import React from "react"
import Model from "Model"
import OpenFilter from "./OpenFilter"
import OpenTable from "./OpenTable"

interface Props {
    model: Model
    isLoading?: boolean
    isLoadingTask?: boolean
    data: Array<object>
    onReGetListTasks: Function
}

export default class OpenTab extends React.Component<Props, {}> {
    state = {
        fields: ["status", "name", "description", "department", "created", "dueDate", "progress"]
    }

    onApplyFilter = (data) => {
        console.log("data", data)
    }

    render() {
        const {fields} = this.state
        const {model, isLoadingTask, data} = this.props

        return (
            <>
                <OpenFilter model={model} onApplyFilter={this.onApplyFilter} />
                <OpenTable model={model} data={data} isLoading={isLoadingTask} fields={fields} />
            </>
        )
    }
}
