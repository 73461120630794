import React from "react"
import {Upload, Button} from "antd"
import {Icon} from "components/Icons"
import styles from "./BaseInput.module.css"

interface Props {
    label: string
    onChangeValue: Function
}

interface State {
    fileName: any
}

export default class BaseFileInput extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = {
            fileName: null
        }
    }

    onChangeFile = (file) => {
        if (file) {
            this.setState({fileName: file.name})
            this.props.onChangeValue(file)
        }
        return false
    }

    onRemoveFile = () => {
        this.setState({fileName: null})
        this.props.onChangeValue(null)
    }

    render() {
        const {fileName} = this.state
        const {label} = this.props

        if (fileName) {
            return (
                <div className={styles.fileNameWrap}>
                    <span className={styles.fileName}>{fileName}</span>
                    <Button className={styles.removeIconBtn} onClick={this.onRemoveFile}>
                        <Icon className={styles.removeIcon} icon="CLOSE" color="#d0d0d0" />
                    </Button>
                </div>
            )
        }
        return (
            <Upload beforeUpload={this.onChangeFile} accept="application/pdf">
                <Button className={styles.baseFileInputBtn}>{label}</Button>
            </Upload>
        )
    }
}
