import React, {useState} from "react"
import classNames from "classnames"
import {Radio} from "antd"
import TableRowItem from "./TableRowItem"
import styles from "./ConfigureTaskOtherTable.module.css"

export default function ConfigureTaskOtherTable({data, onChange, onEnableList, listEnabled, loadStudentFn}: any) {
    const [rowsEnabled, setRowsEnabled] = useState(listEnabled)
    // const onSearchChange = () => {}

    const onRadioChange = (evt) => {
        const {value} = evt.target
        setRowsEnabled(value)
        if (onEnableList) onEnableList(value)
    }
    const onStudentChange = (newItem) => {
        const newData = [...data]
        const idx = newData.findIndex((item) => item.noStudent || item.student.id === newItem.student.id)
        newData[idx] = newItem

        onChange(newData)
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.tableWrapper}>
                <table className={styles.table}>
                    <thead className={styles.tableHead}>
                        <tr className={styles.tableRowHeader}>
                            <th
                                className={classNames(styles.tableCell, styles.tableCellHeader)}
                                style={{width: "130px"}}>
                                {/*
                                    <BaseInput
                                        onChangeValue={onSearchChange}
                                        className={styles.tableCellHeaderInputSearch}
                                        placeholder="Search"
                                    />
                                */}
                            </th>
                            <th className={classNames(styles.tableCell, styles.tableCellHeader)}>Student Name</th>
                            <th className={classNames(styles.tableCell, styles.tableCellHeader)}>Recipient name</th>
                            <th className={classNames(styles.tableCell, styles.tableCellHeader)}>Recipient mail</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data !== undefined &&
                            rowsEnabled &&
                            data.map((item) => (
                                <TableRowItem
                                    key={item.student ? item.student.id : null}
                                    data={item}
                                    onChange={onStudentChange}
                                    loadStudentFn={loadStudentFn}
                                />
                            ))}
                        {(data === undefined || data.length === 0) && (
                            <tr className={styles.tableRow}>
                                <td colSpan={4}>
                                    <p className={styles.emptyRowData}>Empty Data</p>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className={styles.searchFilterWrap}>
                <Radio.Group
                    name="other-radio"
                    className={styles.radioGroup}
                    onChange={onRadioChange}
                    value={listEnabled}>
                    <Radio className={styles.radioItem} value={false}>
                        No
                    </Radio>
                    <Radio className={styles.radioItem} value={true}>
                        Yes
                    </Radio>
                </Radio.Group>
                {/*
                    <Button className={styles.OKBtn}>OK</Button>
                */}
            </div>
        </div>
    )
}
