import React, {useState} from "react"
import {Row, Col, Button, message as MessagePopup} from "antd"
import classNames from "classnames"
import {BaseInput} from "components/Input"
import {BaseButton} from "components/Button"
import {BasePopup} from "components/popup"
import styles from "./ResponsesTab.module.css"
import {taskService} from "services"

export default function ResponsesFooter(props) {
    const [isOpen, setIsOpen] = useState(false)
    const [sendingMessage, setSendingMessage] = useState(false)
    const [type, setType] = useState("")
    const [message, setMessage] = useState("")
    const {resending, done, data, checkboxData, onUpdateLoading} = props
    const subtaskIds = Object.keys(checkboxData)
        .filter((k) => checkboxData[k])
        .map((k) => +k)

    if (resending && !sendingMessage && !isOpen) {
        setType("resend")
        setIsOpen(true)
    }
    const onClickApprove = () => {
        setIsOpen(true)
        setType("approve")
    }

    const onClickRemind = async () => {
        await send("remind")
    }

    const onClickReject = () => {
        setIsOpen(true)
        setType("reject")
    }

    const onClickAction = (type) => {
        switch (type) {
            case "reject":
            case "resend": {
                setSendingMessage(true)
                break
            }
            default:
                send(type)
        }
        setIsOpen(false)
    }

    const onCloseActionPopup = () => {
        reset()
    }
    const send = async (type) => {
        try {
            onUpdateLoading(true)
            switch (type) {
                case "resend": {
                    await taskService.resendTask(resending, {message})
                    MessagePopup.success("Successfully re-sent")
                    break
                }
                case "approve": {
                    await taskService.approve({subtaskIds})
                    MessagePopup.success("Successfully approved")
                    break
                }
                case "reject": {
                    await taskService.rejectTask({subtaskIds, message})
                    MessagePopup.success("Successfully rejected")
                    break
                }
                case "remind": {
                    await taskService.remind({subtaskIds})
                    MessagePopup.success("Successfully reminded")
                    break
                }
            }
            done()
            reset()
        } catch (e) {
            MessagePopup.error("Sorry, an error occurred, please try again.")
            onUpdateLoading(false)
            return false
        } finally {
            onUpdateLoading(false)
        }
    }
    const reset = () => {
        setMessage("")
        setType("")
        setIsOpen(false)
        setSendingMessage(false)
    }
    let approveEnabled = subtaskIds.length > 0
    let remindEnabled = subtaskIds.length > 0
    let rejectEnabled = subtaskIds.length > 0
    for (let i = 0; i < data.subtasks.length; i++) {
        const subtask = data.subtasks[i]
        if (subtaskIds.indexOf(subtask.id) === -1) {
            continue
        }
        switch (subtask.status) {
            case "open":
                approveEnabled = false
                break
            case "submitted":
                remindEnabled = false
                break
            default:
                approveEnabled = false
                remindEnabled = false
                rejectEnabled = false
                break
        }
    }
    return (
        <>
            <Row>
                <Col span={12}></Col>
                <Col span={12}>
                    <Row justify="space-between" className={styles.rowStatusWrap}>
                        {approveEnabled && !data.autoApprove && (
                            <Col span={7}>
                                <Button
                                    className={classNames(styles.statusBtn, styles.approve)}
                                    onClick={onClickApprove}>
                                    APPROVE
                                </Button>
                            </Col>
                        )}
                        {remindEnabled && (
                            <Col span={7}>
                                <Button className={classNames(styles.statusBtn, styles.remind)} onClick={onClickRemind}>
                                    REMIND
                                </Button>
                            </Col>
                        )}
                        {rejectEnabled && (
                            <Col span={7}>
                                <Button className={classNames(styles.statusBtn, styles.reject)} onClick={onClickReject}>
                                    REJECT
                                </Button>
                            </Col>
                        )}
                    </Row>
                </Col>
                {sendingMessage && (
                    <>
                        <Col span={16}>
                            <div className={styles.inputWrap}>
                                <BaseInput
                                    placeholder="Enter a message to send to the student"
                                    onChangeValue={setMessage}
                                />
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className={styles.actionWrap}>
                                <Button className={styles.applyBtn} onClick={() => send(type)}>
                                    SEND
                                </Button>
                            </div>
                        </Col>
                    </>
                )}
            </Row>
            {isOpen && (
                <BasePopup isShow={isOpen} onClose={onCloseActionPopup} width="400px">
                    {type === "remind" ? (
                        <div>
                            <p className={styles.title}>Remind sent</p>
                            <BaseButton title="OK" className={styles.OKBtn} onClick={onCloseActionPopup} />
                        </div>
                    ) : (
                        <div>
                            <p className={styles.title}>Are you sure?</p>
                            <div className={styles.actionWrap1}>
                                <Button className={styles.cancelBtn} onClick={onCloseActionPopup}>
                                    Cancel
                                </Button>
                                <div className={styles.accept}>
                                    <BaseButton
                                        title="OK"
                                        className={styles.acceptBtn}
                                        onClick={() => onClickAction(type)}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </BasePopup>
            )}
        </>
    )
}
