import {get} from "lodash"
import BaseService from "./Base"

class TaskService extends BaseService {
    async getDashboardData() {
        const response = await this.get("/v1/dashboard", {}, true)
        return get(response, "data", {})
    }

    async getAll(params = {}) {
        const response = await this.get("/v1", params)
        return get(response, "data", [])
    }

    async getDigitalDocuments(params = {}) {
        const response = await this.get("/digital-documents/1.1/selector", params)
        return get(response, "data", [])
    }

    async getDocumentCategories(params = {}) {
        const response = await this.get("/customfields/1.0/type/documents_type", params)
        return get(response, "data", [])
    }

    async getStaffUsers(params = {}) {
        const response = await this.get("/users/1.2/roletype/staff", params)
        return get(response, "data", [])
    }

    async getDigitalDocument(id, params = {}) {
        const response = await this.get(`/digital-documents/1.1/${id}`, params)
        return get(response, "data", [])
    }

    async getCampuses(params = {}) {
        const response = await this.get("/locations/1.1", params)
        return get(response, "data", [])
    }

    async getStudentStatuses(params = {}) {
        const response = await this.get("/keyvaluepairs/1.1/type/studentstatus", params)
        return get(response, "data", [])
    }

    async getGroups(params = {}) {
        const response = await this.get("/keyvaluepairs/1.1/type/groups", params)
        return get(response, "data", [])
    }

    async getPrograms(params = {}) {
        const response = await this.get("/majors/1.1", params)
        return get(response, "data", [])
    }

    async getForms(params = {}) {
        const response = await this.get("/forms/v1", params, true)
        return get(response, "data", [])
    }

    async getListAssignedTasks(params = {}) {
        const response = await this.get("/v1", params, true)
        return get(response, "data", [])
    }

    async getTaskDetail(id, params = {}) {
        const response = await this.get(`/v1/${id}`, params, true)
        return get(response, "data", {})
    }

    async createTask(data, params = {}) {
        let formData = new FormData()
        Object.keys(data).forEach((key) => {
            if (Array.isArray(data[key])) {
                formData.append(key, JSON.stringify(data[key]))
            } else {
                formData.append(key, data[key])
            }
        })
        const headers = this.setHeaderFromParams({})
        headers.headers["Content-Type"] = "multipart/form-data"
        const response = await this.axios.post(`${this.baseURLV1}/v1`, formData, headers)

        return get(response, "data", {})
    }

    async resendTask(id, data, params = {}) {
        const response = await this.post(`/v1/resend/${id}`, data, params, true)
        return get(response, "data", {})
    }
    async remind(data, params = {}) {
        const response = await this.post(`/v1/remind/`, data, params, true)
        return get(response, "data", {})
    }
    async approve(data, params = {}) {
        const response = await this.post(`/v1/approve/`, data, params, true)
        return get(response, "data", {})
    }
    async rejectTask(data, params = {}) {
        const response = await this.post(`/v1/reject-subtask/`, data, params, true)
        return get(response, "data", {})
    }

    async assignTask(data, params = {}) {
        const response = await this.post("/v1/assign", data, params, true)
        return get(response, "data", {})
    }

    async updateTask(id, data, params = {}) {
        const response = await this.put(`/v1/${id}`, data, params, true)
        return get(response, "data", {})
    }

    async deleteTask(id, params = {}) {
        const response = await this.delete(`/v1/${id}`, params, true)
        return get(response, "data", {})
    }

    async getListTasksForUser(userID, params = {}) {
        const response = await this.get(`/v1/forUser/`, params, true)
        return get(response, "data", {})
    }

    async upload(data, params = {}) {
        const response = await this.post(`/v1/upload-file`, data, params, true)
        return get(response, "data", {})
    }

    async addToLibrary(id, params = {}) {
        const response = await this.post(`/v1/add-to-library/${id}`, {}, params, true)
        return get(response, "data", {})
    }

    async answerScan(id, data, params = {}) {
        const response = await this.post(`/v1/answer-scan/${id}`, data, params, true)
        return get(response, "data", {})
    }
}

export default TaskService
