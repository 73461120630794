import React from "react"
import Model from "Model"
import {SUBTYPE} from "constant"
import {BaseButton} from "components/Button"
import {Icon} from "components/Icons"
import {taskService} from "services"
import LibraryTable from "./LibraryTable"
import {ConfigureTask} from "../../Configure"
import styles from "./LibraryTab.module.css"

interface Props {
    model: Model
    isLoading?: boolean
    isLoadingTask?: boolean
    data: Array<object>
    onReGetListTasks: Function
}

export default class LibraryTab extends React.Component<Props, {}> {
    state = {
        isShowConfigureTask: false,
        configureTaskData: null
    }

    onClickShowConfigureTaskPopup = () => {
        this.setState({isShowConfigureTask: true, configureTaskData: null})
    }

    onCloseConfigureTaskPopup = () => {
        this.setState({isShowConfigureTask: false, configureTaskData: null})
    }

    onClickShowDetail = async (data) => {
        const task = await taskService.getTaskDetail(data.id)
        this.setState({isShowConfigureTask: true, configureTaskData: task})
    }

    getConfigureTaskPopup() {
        const {isShowConfigureTask, configureTaskData} = this.state
        if (!isShowConfigureTask) return null
        const {model} = this.props

        return (
            <div>
                <ConfigureTask
                    isShow={isShowConfigureTask}
                    onClose={this.onCloseConfigureTaskPopup}
                    data={configureTaskData}
                    model={model}
                    library={true}
                    onSuccess={this.onCreateTaskSuccess}
                    subtype={SUBTYPE.LIBRARY}
                />
            </div>
        )
    }

    onApplyFilter = (data) => {
        console.log("data", data)
    }

    onCreateTaskSuccess = () => {
        this.setState({isShowConfigureTask: false})
        this.props.onReGetListTasks()
    }

    onDeleteTaskSuccess = () => {
        this.props.onReGetListTasks()
    }

    render() {
        const {model, data} = this.props

        return (
            <>
                <div className={styles.createTaskWrap}>
                    <BaseButton
                        title="ADD TASK"
                        className={styles.createBtn}
                        onClick={this.onClickShowConfigureTaskPopup}
                        icon={<Icon className={styles.iconPlus} icon="PLUS" color="#FFF" />}
                    />
                    {/*<BaseButton title="CREATE PACKAGE" icon={<Icon className={styles.iconPlus} icon="PLUS" />} />*/}
                    {this.getConfigureTaskPopup()}
                </div>
                {/*<LibraryFilter onApplyFilter={this.onApplyFilter} model={model} />*/}
                <LibraryTable
                    onClickShowDetail={this.onClickShowDetail}
                    model={model}
                    data={data}
                    onDeleteTaskSuccess={this.onDeleteTaskSuccess}
                />
            </>
        )
    }
}
