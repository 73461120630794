import React from "react"
import PropTypes from "prop-types"
import {Spin} from "antd"
import styles from "./BaseLoading.module.css"

export function BaseLoading(props) {
    const {isShow} = props
    if (!isShow) return null

    return (
        <div className={styles.overlay}>
            <Spin />
        </div>
    )
}

BaseLoading.propTypes = {
    isShow: PropTypes.bool
}
