import React from "react"
import {Row, Col, Button} from "antd"
import {BaseMultiSelect} from "components/Select"
import {BaseDatePicker} from "components/DatePicker"
import styles from "./ConfigureTaskFilter.module.css"
import Model from "Model"

interface Props {
    model: Model
}

interface State {
    filterBodyData: any
    campuses: any
    groups: any
    programs: any
    statuses: any
}

export default class ConfigureTaskFilterBody extends React.Component<Props, State> {
    state = {
        filterBodyData: {
            campuses: "",
            groups: [],
            programs: [],
            statuses: [],
            fromDate: "",
            toDate: ""
        },
        campuses: [
            {id: "campus1", name: "Campus 1"},
            {id: "campus2", name: "Campus 2"},
            {id: "campus3", name: "Campus 3"},
            {id: "campus4", name: "Campus 4"}
        ],
        groups: [
            {id: "group1", name: "Group 1"},
            {id: "group2", name: "Group 2"},
            {id: "group3", name: "Group 3"},
            {id: "group4", name: "Group 4"}
        ],
        programs: [
            {id: "program1", name: "Program  1"},
            {id: "program2", name: "Program 2"},
            {id: "program3", name: "Program 3"},
            {id: "program4", name: "Program 4"}
        ],
        statuses: [
            {id: "status1", name: "Status 1"},
            {id: "status2", name: "Status 2"},
            {id: "status3", name: "Status 3"},
            {id: "status4", name: "Status 4"}
        ]
    }

    onChangeFilter = (key, value) => {
        const {filterBodyData} = this.state
        filterBodyData[key] = value
        this.setState({filterBodyData})
    }

    render() {
        const {filterBodyData, campuses, groups, programs, statuses} = this.state
        const {model} = this.props
        const {dateFormat} = model
        // TODO

        return (
            <Row className={styles.filterOptionsBody}>
                <Col span={8}>
                    <div className={styles.itemFilterBodyWrap}>
                        <p className={styles.itemLabel}>Campuses</p>
                        <BaseMultiSelect
                            options={campuses}
                            onChange={(selectedValue) => this.onChangeFilter("campuses", selectedValue)}
                            placeholder="Campuses"
                        />
                    </div>
                </Col>
                <Col span={8}>
                    <div className={styles.itemFilterBodyWrap}>
                        <p className={styles.itemLabel}>Groups</p>
                        <BaseMultiSelect
                            options={groups}
                            onChange={(selectedValue) => this.onChangeFilter("groups", selectedValue)}
                            placeholder="No groups specified"
                        />
                    </div>
                </Col>
                <Col span={8}>
                    <div className={styles.filterDateBodyWrap}>
                        <p className={styles.itemLabel}>Start date from</p>
                        <BaseDatePicker
                            defaultValue={filterBodyData.fromDate}
                            onChange={(newValue) => this.onChangeFilter("fromDate", newValue)}
                            format={dateFormat}
                        />
                    </div>
                </Col>
                <Col span={8}>
                    <div className={styles.itemFilterBodyWrap}>
                        <p className={styles.itemLabel}>Programs</p>
                        <BaseMultiSelect
                            options={programs}
                            onChange={(selectedValue) => this.onChangeFilter("programs", selectedValue)}
                            placeholder="Programs"
                        />
                    </div>
                </Col>
                <Col span={8}>
                    <div className={styles.itemFilterBodyWrap}>
                        <p className={styles.itemLabel}>Status</p>
                        <BaseMultiSelect
                            options={statuses}
                            onChange={(selectedValue) => this.onChangeFilter("statuses", selectedValue)}
                            placeholder="Status"
                        />
                    </div>
                </Col>
                <Col span={8}>
                    <div className={styles.filterDateBodyWrap}>
                        <p className={styles.itemLabel}>Start date to</p>
                        <BaseDatePicker
                            defaultValue={filterBodyData.toDate}
                            onChange={(newValue) => this.onChangeFilter("toDate", newValue)}
                            format={dateFormat}
                        />
                    </div>
                </Col>
                <Col span={24}>
                    <div className={styles.filterBodyBtnWrap}>
                        <Button className={styles.filterBodyBtn}>OK</Button>
                    </div>
                </Col>
            </Row>
        )
    }
}
