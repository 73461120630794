import React from "react"
import PropTypes from "prop-types"
import {Button} from "antd"
import {Icon} from "components/Icons"
import styles from "../user.module.css"

export default function ExpandRowItem(props) {
    const {data, open, setOpen} = props
    if (!data.childs) return null
    return open ? (
        <Button
            className={styles.expandIconBtn}
            onClick={() => setOpen(!open)}
            icon={<Icon icon="ARROW_DOWN" className={styles.expandIcon} />}
        />
    ) : (
        <Button
            className={styles.expandIconBtn}
            onClick={() => setOpen(!open)}
            icon={<Icon icon="ARROW_RIGHT" className={styles.expandIcon} />}
        />
    )
}

ExpandRowItem.propTypes = {
    data: PropTypes.object,
    open: PropTypes.bool,
    setOpen: PropTypes.func
}
