import React from "react"
import {Pagination} from "antd"

export default function BasePagination(props) {
    const {page, total, onChangePage, ...rest} = props

    return (
        <Pagination
            defaultCurrent={1}
            current={page}
            total={total}
            onChange={onChangePage}
            defaultPageSize={4}
            {...rest}
        />
    )
}
