import React from "react"
import Model from "Model"
import {taskService} from "services"
import {DigitalItem, ScanItem, WebItem} from "."
import {tableData} from "./rawData"

interface Props {
    match: any
    model: Model
}

export default class GoKlassTaskDetail extends React.Component<Props> {
    state = {
        data: {}
    }

    componentDidMount() {
        const {taskId} = this.props.match.params
        this.getTaskDetailData(taskId)
    }

    getTaskDetailData = async (taskId) => {
        try {
            const taskDetail = await taskService.getTaskDetail(taskId, {subTask: 1})
            this.setState({data: taskDetail})
        } catch (e) {}
    }

    render() {
        const data: any = this.state.data
        const {model} = this.props
        const {taskId, taskUserId} = this.props.match.params

        switch (data.type) {
            case "dd":
                return <DigitalItem data={data} taskID={taskId} model={model} />
            case "scan":
                return <ScanItem data={data} taskID={taskId} taskUserId={taskUserId} model={model} />
            case "form":
                return <WebItem data={data} taskID={taskId} model={model} />
            default:
                return ""
        }
    }
}
