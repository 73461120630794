import React from "react"
import PropTypes from "prop-types"
import styles from "./ConfigureTaskStudentTable.module.css"

export default function TableRowItem(props) {
    const {data} = props

    return (
        <tr key={data.id} className={styles.tableRow}>
            <td className={styles.tableCell}>
                {data.first_name} {data.last_name}
            </td>
            <td className={styles.tableCell}>{data.college_id}</td>
            <td className={styles.tableCell}>{data.major ? data.major.name : ""}</td>
            <td className={styles.tableCell}>{data.email}</td>
            {/*
                    <td className={styles.tableCell}>
                        <Button className={styles.cloudIconBtn}>
                            <Icon icon="CLOUD_UPLOAD" className={styles.cloudIcon} />
                        </Button>
                    </td>
                */}
        </tr>
    )
}

TableRowItem.propTypes = {
    data: PropTypes.object
}
