const tableData = [
    {
        id: "1",
        title: "Parental consent form 104",
        trigger: "DOB",
        department: "Admissions",
        responses: 98,
        campus: "Chicago",
        program: "Cosmetology"
    },
    {
        id: "2",
        title: "Identity documents",
        trigger: "Status",
        department: "Admissions",
        responses: 10,
        campus: "Lake Ashley",
        program: "Cosmetology"
    },
    {
        id: "3",
        title: "Photo ID Scan",
        trigger: "Age",
        department: "Financial Aid",
        responses: 250,
        campus: "Chicago",
        program: "Cosmetology"
    },
    {
        id: "4",
        title: "Parental consent form 104",
        trigger: "Graduation Date",
        department: "Financial Aid",
        responses: 98,
        campus: "East Kaitlyn",
        program: "Cosmetology"
    }
]

export {tableData}
