import React from "react"
import PropTypes from "prop-types"
import {Button} from "antd"
import classNames from "classnames"
import {Icon} from "components/Icons"
import StatusIconItem from "./StatusIconItem"
import ActionRowItem from "./ActionRowItem"
import styles from "../user.module.css"

export default function ChildRows(props) {
    const {childRows, open} = props
    if (!childRows) return null

    return childRows.map((childItem) => (
        <tr key={childItem.id} className={classNames(styles.tableRow, !open ? styles.hiddenRow : "")}>
            <td className={`${styles.tableCell} ${styles.expandIconCell}`}></td>
            <td className={classNames(styles.tableCell, styles.tableRowChild)}>
                <span>{childItem.taskName}</span>
            </td>
            <td className={styles.tableCell}>{childItem.reference}</td>
            <td className={styles.tableCell}>{childItem.taskType}</td>
            <td className={classNames(styles.tableCell, styles.tableCellCenter)}>
                <Button className={styles.iconBtn} icon={<Icon icon="FILE" />} />
            </td>
            <td className={styles.tableCell}>{childItem.due}</td>
            <td className={styles.tableCell}>
                <div>
                    <StatusIconItem data={childItem} />
                    <span className={styles.rowItemStatus}>{childItem.status}</span>
                </div>
            </td>
            <td className={styles.tableCell}>
                <ActionRowItem />
            </td>
        </tr>
    ))
}

ChildRows.propTypes = {
    childRows: PropTypes.array,
    open: PropTypes.bool
}
