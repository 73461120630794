import React from "react"
import classNames from "classnames"
import {Button} from "antd"
import TableRowItem from "./TableRowItem"
import styles from "./ConfigureTaskStudentTable.module.css"

export default function ConfigureTaskStudentTable({data, onEdit = null}: any) {
    // const onSearchChange = () => {}

    return (
        <div className={styles.wrapper}>
            <div className={styles.tableWrapper}>
                <table className={styles.table}>
                    <thead className={styles.tableHead}>
                        <tr className={styles.tableRowHeader}>
                            <th className={classNames(styles.tableCell, styles.tableCellHeader)}>Name</th>
                            <th className={classNames(styles.tableCell, styles.tableCellHeader)}>Student ID</th>
                            <th className={classNames(styles.tableCell, styles.tableCellHeader)}>Program</th>
                            <th className={classNames(styles.tableCell, styles.tableCellHeader)}>Email</th>
                            <th className={classNames(styles.tableCell, styles.tableCellHeader)}>
                                {/*
                                    <BaseInput
                                        onChangeValue={onSearchChange}
                                        className={styles.tableCellHeaderInputSearch}
                                        placeholder="Search for a student"
                                    />
                                 */}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data !== undefined && data.map((item) => <TableRowItem key={item.id} data={item} />)}
                        {(data === undefined || data.length === 0) && (
                            <tr className={styles.tableRow}>
                                <td colSpan={5}>
                                    <p className={styles.emptyRowData}>Empty Data</p>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <Button className={styles.OKBtn} onClick={onEdit}>
                Edit
            </Button>

            {/*
                    <Button className={styles.OKBtn}>OK</Button>
                 */}
        </div>
    )
}
