import React from "react"
import classNames from "classnames"
import {Button} from "antd"
import TableRowItem from "./TableRowItem"
import styles from "./ConfigureTaskTable.module.css"

export default function ConfigureTaskTable({data, onEdit = null}: any) {
    return (
        <div className={styles.wrapper}>
            <div className={styles.tableWrapper}>
                <table className={styles.table}>
                    <thead className={styles.tableHead}>
                        <tr className={styles.tableRowHeader}>
                            <th className={classNames(styles.tableCell, styles.tableCellHeader)}>Name</th>
                            <th className={classNames(styles.tableCell, styles.tableCellHeader)}>Student ID</th>
                            <th className={classNames(styles.tableCell, styles.tableCellHeader)}>Program</th>
                            <th className={classNames(styles.tableCell, styles.tableCellHeader)}>Email</th>
                            <th className={classNames(styles.tableCell, styles.tableCellHeader)}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data !== undefined && data.map((item) => <TableRowItem key={item.id} data={item} />)}
                        {(data === undefined || data.length === 0) && (
                            <tr className={styles.tableRow}>
                                <td colSpan={5}>
                                    <p className={styles.emptyRowData}>Empty Data</p>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <Button className={styles.OKBtn} onClick={onEdit}>
                Edit
            </Button>
        </div>
    )
}
