import React from "react"
import {Input} from "antd"
import classNames from "classnames"
import styles from "./BaseInput.module.css"

export function BaseInput(props) {
    const {title, onChangeValue, className, ...rest} = props

    const onChange = (e) => {
        onChangeValue && onChangeValue(e.target.value)
    }

    return <Input className={classNames(styles.baseInput, className)} onChange={onChange} {...rest} />
}
