import React, {useState} from "react"
import {Icon, IconType} from "components/Icons"
import styles from "./SortHeader.module.css"

export const SortHeader = ({className, title, onClick}) => {
    const [sortIcon, setSortIcon] = useState<IconType>("SORT_SOLID")

    const changeSort = () => {
        let icon: IconType = "SORT_SOLID"
        let value: number = 1
        switch (sortIcon) {
            case "SORT_SOLID":
                icon = "SORT_UP_SOLID"
                value = 1
                break
            case "SORT_UP_SOLID":
                icon = "SORT_DOWN_SOLID"
                value = -1
                break
            case "SORT_DOWN_SOLID":
                icon = "SORT_SOLID"
                value = 0
                break
        }
        setSortIcon(icon)
        onClick(value)
    }

    return (
        <th className={className} onClick={changeSort}>
            {title}
            <Icon icon={sortIcon} className={styles.sort_icon} />
        </th>
    )
}
