import React from "react"
import Model from "Model"
import OverdueFilter from "./OverdueFilter"
import OverdueTable from "./OverdueTable"

interface Props {
    model: Model
    isLoading?: boolean
    isLoadingTask?: boolean
    data: Array<object>
    onReGetListTasks: Function
}

export default class OverdueTab extends React.Component<Props, {}> {
    state = {
        fields: ["status", "name", "description", "department", "created", "dueDate", "progress"]
    }

    onApplyFilter = (data) => {
        console.log("data", data)
    }

    render() {
        const {fields} = this.state
        const {model, isLoadingTask, data} = this.props

        return (
            <>
                <OverdueFilter model={model} onApplyFilter={this.onApplyFilter} />
                <OverdueTable model={model} data={data} isLoading={isLoadingTask} fields={fields} />
            </>
        )
    }
}
