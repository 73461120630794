import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import moment from "moment"
// import ActionRowItem from "./ActionRowItem"
import styles from "../AllTable.module.css"
import {STATUS, TASK_TYPE} from "constant"

export default function TableRowItem(props) {
    const {data, fields, onClickRowItem, model} = props
    const {dateFormat} = model

    const containerClass = `${styles.tableRow} ${data.progress === "open" && "pointer"}`
    console.log(fields)
    return (
        <tr key={data.id} className={containerClass} onClick={() => data.progress === "open" && onClickRowItem(data)}>
            {fields.includes("status") && (
                <td className={styles.tableCell} style={{width: "150px"}}>
                    <div
                        className={classNames(
                            styles.statusWrap,
                            data.status === "overdue" ? styles.overdueStatus : "",
                            data.status === "open" ? styles.openStatus : "",
                            data.status === "submitted" ? styles.completedStatus : "",
                            data.status === "completed" ? styles.completedStatus : ""
                        )}>
                        {STATUS[data.overdue ? "overdue" : data.status]}
                    </div>
                </td>
            )}
            {fields.includes("name") && <td className={classNames(styles.tableCell)}>{data.name}</td>}
            {fields.includes("description") && <td className={styles.tableCell}>{data.description}</td>}
            {fields.includes("type") && <td className={styles.tableCell}>{TASK_TYPE[data.type]}</td>}
            {fields.includes("created") && (
                <td className={styles.tableCell}>{data.createdAt ? moment(data.createdAt).format(dateFormat) : ""}</td>
            )}
            {fields.includes("dueDate") && (
                <td className={styles.tableCell}>{data.dueDate ? moment(data.dueDate).format(dateFormat) : ""}</td>
            )}
            {fields.includes("progress") && (
                <td className={styles.tableCell}>
                    <p className={styles.progressText}>{STATUS[data.progress]}</p>
                </td>
            )}
            {/* <td className={styles.tableCell}>
                <ActionRowItem
                    data={data}
                    onClickShowDetail={onClickShowDetail}
                    onDeleteTaskSuccess={onDeleteTaskSuccess}
                />
            </td> */}
        </tr>
    )
}

TableRowItem.propTypes = {
    data: PropTypes.object,
    onClickShowDetail: PropTypes.func,
    onClickRowItem: PropTypes.func
}
