import React from "react"
import {Row, Col, Button} from "antd"
import Model from "Model"
import {BaseButton} from "components/Button"
import {KlassDropdown} from "components/KlassDropdown"
import styles from "./OpenTab.module.css"

interface Props {
    model: Model
    onApplyFilter: Function
}

export default class OpenFilter extends React.Component<Props, {}> {
    state = {
        data: {
            program: null,
            campus: null,
            department: null,
            status: null
        }
    }

    onChange = (key, selectedOption) => {
        const {data} = this.state
        data[key] = selectedOption
        this.setState({data})
    }

    onClickResetFilter = () => {
        const data = {program: null, campus: null, department: null, status: null}
        this.setState({data})
        this.props.onApplyFilter({})
    }

    onClickApplyFilter = () => {
        const {data} = this.state
        const dataFilter = {}
        Object.keys(data).forEach((key) => {
            if (data[key]) dataFilter[key] = data[key].id
        })
        this.props.onApplyFilter(dataFilter)
    }

    render() {
        const {data} = this.state
        const {program, department, status} = data
        const {programs, groups, studentStatuses} = this.props.model

        return (
            <div>
                <Row>
                    <Col span={4}>
                        <KlassDropdown
                            onChange={(selectedOption) => this.onChange("department", selectedOption)}
                            options={groups}
                            value={department}
                            placeholder="Department"
                            className={styles.colFilter}
                        />
                    </Col>
                    <Col span={4}>
                        <KlassDropdown
                            onChange={(selectedOption) => this.onChange("program", selectedOption)}
                            options={programs}
                            value={program}
                            placeholder="Progarm"
                            className={styles.colFilter}
                        />
                    </Col>
                    <Col span={4}>
                        <KlassDropdown
                            onChange={(selectedOption) => this.onChange("status", selectedOption)}
                            options={studentStatuses}
                            value={status}
                            placeholder="Status"
                            className={styles.colFilter}
                        />
                    </Col>
                    <Col span={4}>
                        <div className={styles.filterBtnWrap}>
                            <Button className={styles.filterBtn} onClick={this.onClickResetFilter}>
                                Reset
                            </Button>
                        </div>
                    </Col>
                    <Col span={4}>
                        <div className={styles.applyBtnWrap}>
                            <BaseButton title="APPLY" onClick={this.onClickApplyFilter} />
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
