import React from "react"
import classNames from "classnames"
import {Button} from "antd"
import TableRowItem from "./TableRowItem"
import styles from "./ConfigureTaskStaffTable.module.css"

export default function ConfigureTaskStaffTable({data, onEdit = null}: any) {
    return (
        <div className={styles.wrapper}>
            <div className={styles.tableWrapper}>
                <table className={styles.table}>
                    <thead className={styles.tableHead}>
                        <tr className={styles.tableRowHeader}>
                            <th className={classNames(styles.tableCell, styles.tableCellHeader)}>Name</th>
                            <th className={classNames(styles.tableCell, styles.tableCellHeader)}>Campus</th>
                            <th className={classNames(styles.tableCell, styles.tableCellHeader)}>Email</th>
                        </tr>
                    </thead>
                    <tbody>{data && data.map((item) => <TableRowItem key={item.id} data={item} />)}</tbody>
                </table>
            </div>
            <Button className={styles.OKBtn} onClick={onEdit}>
                Edit
            </Button>
            {/*
                    <Button className={styles.OKBtn}>OK</Button>
                 */}
        </div>
    )
}
