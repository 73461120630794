import React from "react"
import {Button} from "antd"
import classNames from "classnames"
import styles from "./BaseButton.module.css"

export function BaseButton(props) {
    const {title, className, ...rest} = props

    return (
        <Button className={classNames(styles.baseBtn, className || "")} {...rest}>
            {title}
        </Button>
    )
}
