import React from "react"
import {Row, Col, Button} from "antd"
import {debounce} from "lodash"
import Model from "Model"
import {BaseMultiSelect, BaseAsyncSelect} from "components/Select"
import {BaseDatePicker} from "components/DatePicker"
import styles from "./ConfigureTaskTypeFilter.module.css"

interface Props {
    type: string
    onClickAcceptFilter: Function
    model: Model
}

interface State {
    filterBodyData: any
}

export default class ConfigureTaskStudentFilter extends React.Component<Props, State> {
    debouncedLoadStudentUsers: any

    constructor(props) {
        super(props)
        this.state = {
            filterBodyData: {
                students: "",
                campuses: [],
                groups: [],
                programs: [],
                statuses: [],
                fromDate: "",
                toDate: ""
            }
        }
        this.debouncedLoadStudentUsers = debounce(this.loadStudentUsers, 0, {leading: true})
    }

    onChangeFilter = (key, value) => {
        const {filterBodyData} = this.state
        filterBodyData[key] = value
        this.setState({filterBodyData})
    }
    isEmpty = ({students, campuses, groups, programs, statuses, fromDate, toDate}) => {
        return (
            !students &&
            campuses.length === 0 &&
            groups.length === 0 &&
            programs.length === 0 &&
            statuses.length === 0 &&
            !fromDate &&
            !toDate
        )
    }
    onClickOKButton = () => {
        const {onClickAcceptFilter, type} = this.props
        const {filterBodyData} = this.state

        if (!this.isEmpty(filterBodyData)) {
            onClickAcceptFilter(type, filterBodyData)
        }
    }

    loadStudentUsers = async (value) => {
        const {model} = this.props
        return await model.loadStudentUsers({query: value, role: "student", limit: 100000, offset: 0})
    }

    onChangeStudentUsers = (userSelected) => {
        const {filterBodyData} = this.state
        filterBodyData.students = userSelected
        this.setState({filterBodyData})
    }

    render() {
        const {filterBodyData} = this.state
        const {campuses, groups, programs, studentStatuses, dateFormat} = this.props.model
        const hasStudentSelected = !!(filterBodyData.students && filterBodyData.students.length)

        return (
            <Row className={styles.filterOptionsBody}>
                <Col span={16}>
                    <div className={styles.itemFilterBodyWrap}>
                        <p className={styles.itemLabel}>Students</p>
                        <BaseAsyncSelect
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.full_name}
                            loadOptions={(inputValue) => this.debouncedLoadStudentUsers(inputValue)}
                            onChange={this.onChangeStudentUsers}
                            isMulti
                        />
                    </div>
                </Col>
                <hr className={styles.filterSeparator} />
                <Col span={8}>
                    <div className={styles.itemFilterBodyWrap}>
                        <p className={styles.itemLabel}>Campuses</p>
                        <BaseMultiSelect
                            options={campuses}
                            onChange={(selectedValue) => this.onChangeFilter("campuses", selectedValue)}
                            placeholder="Campuses"
                            disabled={hasStudentSelected}
                        />
                    </div>
                </Col>
                <Col span={8}>
                    <div className={styles.itemFilterBodyWrap}>
                        <p className={styles.itemLabel}>Groups</p>
                        <BaseMultiSelect
                            options={groups}
                            onChange={(selectedValue) => this.onChangeFilter("groups", selectedValue)}
                            placeholder="No groups specified"
                            disabled={hasStudentSelected}
                        />
                    </div>
                </Col>
                <Col span={8}>
                    <div className={styles.itemFilterBodyWrap}>
                        <p className={styles.itemLabel}>Programs</p>
                        <BaseMultiSelect
                            options={programs}
                            onChange={(selectedValue) => this.onChangeFilter("programs", selectedValue)}
                            placeholder="Programs"
                            disabled={hasStudentSelected}
                        />
                    </div>
                </Col>
                <Col span={8}>
                    <div className={styles.itemFilterBodyWrap}>
                        <p className={styles.itemLabel}>Status</p>
                        <BaseMultiSelect
                            options={studentStatuses}
                            onChange={(selectedValue) => this.onChangeFilter("statuses", selectedValue)}
                            placeholder="Status"
                            disabled={hasStudentSelected}
                        />
                    </div>
                </Col>
                <Col span={8}>
                    <div className={styles.filterDateBodyWrap}>
                        <p className={styles.itemLabel}>Start date from</p>
                        <BaseDatePicker
                            defaultValue={filterBodyData.fromDate}
                            onChange={(newValue) => this.onChangeFilter("fromDate", newValue)}
                            disabled={hasStudentSelected}
                            format={dateFormat}
                        />
                    </div>
                </Col>
                <Col span={8}>
                    <div className={styles.filterDateBodyWrap}>
                        <p className={styles.itemLabel}>Start date to</p>
                        <BaseDatePicker
                            defaultValue={filterBodyData.toDate}
                            onChange={(newValue) => this.onChangeFilter("toDate", newValue)}
                            disabled={hasStudentSelected}
                            format={dateFormat}
                        />
                    </div>
                </Col>
                <Col span={24}>
                    <div className={styles.filterBodyBtnWrap}>
                        <Button className={styles.filterBodyBtn} onClick={this.onClickOKButton}>
                            OK
                        </Button>
                    </div>
                </Col>
            </Row>
        )
    }
}
