import React from "react"
import {Button} from "antd"
import {keyBy} from "lodash"
import {Icon} from "components/Icons"
import {BaseError} from "components/Common"
import ChooseConfigureTaskTypeFormItem from "./ChooseConfigureTaskTypeFormItem"
import styles from "./ChooseConfigureTaskType.module.css"

interface Props {
    item: any
    index: number
    editable?: boolean
    userTypes: Array<object>
    onCloseItem: Function
    onAddItem: Function
    onClickEditItem: Function
    onClickRemoveItem: Function
    onClick: Function
    isCreateForm?: boolean
}

export default class ChooseConfigureTaskTypeItem extends React.Component<Props, {}> {
    onRemoveItem = (evt) => {
        evt.stopPropagation()
        const {item, onClickRemoveItem} = this.props
        onClickRemoveItem(item)
    }

    onClickEditItem = (evt) => {
        evt.stopPropagation()
        const {item, onClickEditItem} = this.props
        onClickEditItem(item)
    }

    onClick = (evt) => {
        evt.stopPropagation()
        const {item, onClick} = this.props
        onClick(item)
    }

    renderByType = () => {
        const {item, index, userTypes, isCreateForm} = this.props
        const userTypesKeyByID = keyBy(userTypes, "id")
        let icon

        switch (item.userType) {
            case userTypesKeyByID.STUDENT.id:
                icon = "STUDENT_ICON"
                break
            case userTypesKeyByID.STAFF.id:
                icon = "STAFF_ICON"
                break
            case userTypesKeyByID.OTHER.id:
                icon = "MEN_WOMEN"
                break
            default:
                return <div className={styles.item}></div>
        }
        return (
            <div className={`${styles.item} ${item.isActive ? styles.activeItem : ""}`} onClick={this.onClick}>
                <div className={styles.itemBodyWrap}>
                    <Icon className={styles.itemBodyIcon} icon={icon} />
                    <div className={styles.itemBodyContentWrap}>
                        <div className={styles.itemBodyContentTitleWrap}>
                            <p className={styles.itemBodyTitle}>{item.userType}</p>
                            {isCreateForm && <p className={styles.itemBodyNo}>{index + 1}</p>}
                        </div>
                        <div className={styles.itemBodyContentLabel}>{item.name}</div>
                    </div>
                    {!isCreateForm && (
                        <div className={styles.itemBodyInfoNo}>
                            <p className={styles.itemBodyInfoNo__text}>Assigned</p>
                            <p className={styles.itemBodyInfoNo__no}>{item.assigned || 0}</p>
                            <p className={styles.itemBodyInfoNo__text}>Responses</p>
                            <p className={styles.itemBodyInfoNo__no_1}>{item.responses || 0}</p>
                        </div>
                    )}

                    {this.props.editable !== false && (
                        <>
                            <Button className={styles.itemBodyCloseBtn} onClick={this.onRemoveItem}>
                                <Icon className={styles.itemBodyCloseIcon} icon="CLOSE" />
                            </Button>
                            <Button className={styles.itemBodyEditBtn} onClick={this.onClickEditItem}>
                                <Icon className={styles.itemBodyEditIcon} icon="EDIT" />
                            </Button>
                        </>
                    )}
                </div>
                {item.errorMessage && <BaseError className={styles.error} error={item.errorMessage} />}
            </div>
        )
    }

    render() {
        const {item, onCloseItem, onAddItem, userTypes} = this.props

        if (item.isAddNew || item.isEdit) {
            return (
                <ChooseConfigureTaskTypeFormItem
                    item={item}
                    onCloseItem={onCloseItem}
                    onAddItem={onAddItem}
                    userTypes={userTypes}
                />
            )
        }

        return this.renderByType()
    }
}
