import React from "react"
import {Tabs, Button} from "antd"
import * as QueryString from "query-string"
import Model from "Model"
import {observer} from "mobx-react"
import {BaseSearchInput} from "components/Input"
import {Icon} from "components/Icons"
import {BaseLoading} from "components/Loading"
import {TaskDetail, ResponsesTab} from "./items"
import styles from "./Details.module.css"
import {Iframe} from "helpers"

const {TabPane} = Tabs

interface Props {
    isShow: boolean
    data?: any
    model: Model
    onClose: Function
    match: any
    history: any
}

const Types = {
    form: "Form",
    dd: "Digital Docuent",
    scan: "Scan",
    custom: "Custom"
}

@observer
export default class TaskDetails extends React.Component<Props, {}> {
    state = {
        data: null,
        searchData: "",
        tabActiveKey: "1"
    }

    componentDidMount() {
        const {id} = this.props.match.params
        this.getTaskDetailData(id)
        this.getDocumentCategories()
        window.top && window.top.postMessage("scroll-up", "*")
    }
    componentDidUpdate() {
        Iframe.updateSize()
    }
    async getDocumentCategories() {
        const {model} = this.props
        await model.loadDocumentCategories({limit: 20})
    }
    getTaskDetailData = async (id) => {
        try {
            this.props.model.clearTask()
            await this.props.model.loadOneTask(id)
        } catch (e) {}
    }

    onChangeTab = (activeKey) => {
        this.setState({tabActiveKey: activeKey})
    }

    onClickBack = () => {
        const {history} = this.props
        const searchParams: any = QueryString.parse(history.location.search)
        const token = (searchParams && searchParams.token) || ""
        history.push(`/?token=${token}`)
    }

    onSearch = async (value) => {
        const {history} = this.props
        const {location} = history
        const searchParams = QueryString.parse(location.search)
        searchParams.search = value
        location.search = this.getQueryStringFromSearchParams(searchParams)
        history.replace({...location})
        // TODO call search API
    }

    onChangeSearch = (event) => {
        this.setState({searchData: event.target.value})
    }

    getQueryStringFromSearchParams = (searchParams) => {
        let queryString = ""
        const searchParamOfKeys = Object.keys(searchParams)
        searchParamOfKeys.forEach((key, index) => {
            queryString += `${key}=${searchParams[key]}`
            if (index !== searchParamOfKeys.length - 1) {
                queryString += "&"
            }
        })
        return queryString
    }

    render() {
        const {searchData, tabActiveKey} = this.state
        const {model} = this.props
        const {task} = this.props.model
        const data: any = task ? {...task} : task

        if (data && data.subtasks) {
            data.subtasks = data.subtasks.filter((subtask) => {
                if (subtask.assigned) {
                    const {first_name, last_name} = subtask.assigned
                    const fullName = `${first_name} ${last_name}`.toLocaleLowerCase()
                    const fullNameReversed = `${last_name} ${first_name}`.toLocaleLowerCase()
                    const searchDataLowerCase = searchData.toLocaleLowerCase()
                    return (
                        fullName.indexOf(searchDataLowerCase) !== -1 ||
                        fullNameReversed.indexOf(searchDataLowerCase) !== -1
                    )
                }
                return false
            })
        }
        //                    <BaseLoading isShow />

        return (
            <div className={styles.wrapper}>
                <div className={styles.headerWrap}>
                    <div className={styles.titleWrap}>
                        <Button className={styles.backIconBtn} onClick={this.onClickBack}>
                            <Icon className={styles.backIcon} icon="BACK" />
                        </Button>
                        <p className={styles.title}>{data ? `${data.name} (${Types[data.type]})` : ""}</p>
                    </div>
                    {tabActiveKey === "1" && (
                        <div>
                            <BaseSearchInput
                                onSearch={this.onSearch}
                                placeholder="Search for a student"
                                defaultValue={searchData}
                                value={searchData}
                                onChange={this.onChangeSearch}
                            />
                        </div>
                    )}
                </div>
                <Tabs className={`fullwidth ${styles.tabsWrapper}`} defaultActiveKey="1" onChange={this.onChangeTab}>
                    <TabPane
                        className={styles.tabItem}
                        tab={
                            <span>
                                <Icon className={styles.tabIcon} icon="PEOPLE" />
                                <span className={styles.tabTitle}>Responses</span>
                            </span>
                        }
                        key="1">
                        {data ? <ResponsesTab data={data} model={model} /> : <BaseLoading isShow />}
                    </TabPane>
                    <TabPane
                        className={styles.tabItem}
                        tab={
                            <span>
                                <Icon className={styles.tabIcon} icon="CHECK_LIST" />
                                <span className={styles.tabTitle}>Task Detail</span>
                            </span>
                        }
                        key="2">
                        {data ? <TaskDetail data={data} model={model} /> : <BaseLoading isShow />}
                    </TabPane>
                </Tabs>
            </div>
        )
    }
}
