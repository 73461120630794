import React, {useState} from "react"
import {Button, Menu, Dropdown, Checkbox} from "antd"
import {Icon, IconType} from "components/Icons"
import {TableRowItem} from "./Table"
import styles from "./ResponsesTable.module.css"

const SortHeader = ({className, title, onClick}) => {
    const [sortIcon, setSortIcon] = useState<IconType>("SORT_SOLID")

    const changeSort = () => {
        let icon: IconType = "SORT_SOLID"
        let value: number = 1
        switch (sortIcon) {
            case "SORT_SOLID":
                icon = "SORT_UP_SOLID"
                value = 1
                break
            case "SORT_UP_SOLID":
                icon = "SORT_DOWN_SOLID"
                value = -1
                break
            case "SORT_DOWN_SOLID":
                icon = "SORT_SOLID"
                value = 0
                break
        }
        setSortIcon(icon)
        onClick(value)
    }

    return (
        <th className={className} onClick={changeSort}>
            {title}
            <Icon icon={sortIcon} className={styles.sort_icon} />
        </th>
    )
}

export default function ResponsesTable(props) {
    const {data, resend, onChange, model} = props
    const [sortBy, setSortBy] = useState("user")
    const [sortValue, setSortValue] = useState(0)
    const [checkboxData, setCheckboxData] = useState({})
    const [isSelectAll, setIsSelectAll] = React.useState(false)

    const onCheckboxChange = (event) => {
        const {checked} = event.target
        setIsSelectAll(checked)
        const newData = {}
        data.subtasks.forEach((subtask) => {
            newData[subtask.id] = checked
        })
        setCheckboxData(newData)
        onChange(newData)
    }

    const onCheckedItem = (subTaskId, value) => {
        const newData = {
            ...checkboxData,
            [subTaskId]: value
        }
        onChange(newData)
        setCheckboxData(newData)
    }
    const onSortClick = (sortBy) => {
        return (sortValue) => {
            setSortValue(sortValue)
            setSortBy(sortBy)
        }
    }
    const sort = (a, b) => {
        if (sortBy === "user") {
            return a.assigned.full_name.localeCompare(b.assigned.full_name) * sortValue
        }
        return a.status.localeCompare(b.status) * sortValue
    }
    const onClickRowItem = (subTaskId) => {
        const subtasks = data.subtasks.map((item, index) => {
            item.index = index
            item.isFirst = index === 0
            item.isLast = index === data.subtasks.length - 1
            if (item.id === subTaskId) {
                item.isActive = !item.isActive
            } else {
                item.isActive = false
            }
            return item
        })
        const newTask = {...data, subtasks}
        model.updateTask(newTask)
    }

    const menu = (
        <Menu className={styles.menuAcitonWrap}>
            <Menu.Item className={styles.menuAcitonItem} key="0">
                <Checkbox className="border-green" onChange={onCheckboxChange} checked={isSelectAll}>
                    <span className={styles.menuActionTitle}>{isSelectAll ? "Deselect All" : "Select All"}</span>
                </Checkbox>
            </Menu.Item>
        </Menu>
    )
    const subtasks = [...data.subtasks].sort(sort)
    return (
        <div className={styles.wrapper}>
            <table className={styles.table}>
                <thead className={styles.tableHead}>
                    <tr className={styles.tableRowHeader}>
                        <th className={`${styles.tableCell} ${styles.tableCellHeader}`}></th>
                        <SortHeader
                            title={"User"}
                            onClick={onSortClick("user")}
                            className={`${styles.tableCell} ${styles.tableCellHeader} ${styles.noselect}`}
                        />
                        <th className={`${styles.tableCell} ${styles.tableCellHeader}`}>Role</th>
                        <th className={`${styles.tableCell} ${styles.tableCellHeader}`}>Email</th>
                        <th className={`${styles.tableCell} ${styles.tableCellHeader}`}>Submitted on</th>
                        <SortHeader
                            title={"Status"}
                            onClick={onSortClick("status")}
                            className={`${styles.tableCell} ${styles.tableCellHeader}`}
                        />
                        <th className={`${styles.tableCell} ${styles.tableCellHeader}`}>
                            <Dropdown overlay={menu} trigger={["click"]}>
                                <Button className={styles.iconBtn} icon={<Icon icon="MORE_HORZ" />} />
                            </Dropdown>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {subtasks.map((item) => (
                        <TableRowItem
                            key={item.id}
                            model={model}
                            isActive={item.isActive}
                            subtask={item}
                            data={checkboxData}
                            onCheckedItem={onCheckedItem}
                            resend={resend}
                            onClickRowItem={onClickRowItem}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    )
}
