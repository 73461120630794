import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import moment from "moment"
// import ActionRowItem from "./ActionRowItem"
import styles from "../OverdueTable.module.css"

export default function TableRowItem(props) {
    const {data, fields, onClickRowItem, model} = props
    const {dateFormat} = model

    return (
        <tr key={data.id} className={styles.tableRow}>
            {fields.includes("status") && (
                <td className={styles.tableCell} onClick={() => onClickRowItem(data)} style={{width: "150px"}}>
                    <div
                        className={classNames(
                            styles.statusWrap,
                            data.status === "overdue" ? styles.overdueStatus : "",
                            data.status === "open" ? styles.openStatus : "",
                            data.status === "submitted" ? styles.completedStatus : "",
                            data.status === "completed" ? styles.completedStatus : ""
                        )}>
                        {data.status}
                    </div>
                </td>
            )}
            {fields.includes("name") && (
                <td className={classNames(styles.tableCell)} onClick={() => onClickRowItem(data)}>
                    {data.name}
                </td>
            )}
            {fields.includes("description") && (
                <td className={styles.tableCell} onClick={() => onClickRowItem(data)}>
                    {data.description}
                </td>
            )}
            {fields.includes("department") && (
                <td className={styles.tableCell} onClick={() => onClickRowItem(data)}>
                    {data.department}
                </td>
            )}
            {fields.includes("created") && (
                <td className={styles.tableCell} onClick={() => onClickRowItem(data)}>
                    {data.createdAt ? moment(data.createdAt).format(dateFormat) : ""}
                </td>
            )}
            {fields.includes("dueDate") && (
                <td className={styles.tableCell} onClick={() => onClickRowItem(data)}>
                    {data.dueDate ? moment(data.dueDate).format(dateFormat) : ""}
                </td>
            )}
            {fields.includes("progress") && (
                <td className={styles.tableCell} onClick={() => onClickRowItem(data)}>
                    <p className={styles.progressText}>{data.progress}</p>
                </td>
            )}
            {/* <td className={styles.tableCell}>
                <ActionRowItem
                    data={data}
                    onClickShowDetail={onClickShowDetail}
                    onDeleteTaskSuccess={onDeleteTaskSuccess}
                />
            </td> */}
        </tr>
    )
}

TableRowItem.propTypes = {
    data: PropTypes.object,
    onClickShowDetail: PropTypes.func,
    onClickRowItem: PropTypes.func
}
