import React from "react"
import {Row, Col, Button} from "antd"
import Model from "Model"
import {BaseMultiSelect} from "components/Select"
import {BaseDatePicker} from "components/DatePicker"
import styles from "./ConfigureTaskFilter.module.css"

interface Props {
    model: Model
    disabled: boolean
    filterBodyData: any
    onChangeFilter: Function
    onClick: any
}

export default class ConfigureTaskFilterBody extends React.Component<Props, {}> {
    render() {
        const {disabled, filterBodyData, onChangeFilter, onClick} = this.props
        const {campuses, programs, studentStatuses, groups, dateFormat} = this.props.model

        return (
            <Row className={styles.filterOptionsBody}>
                <Col span={8}>
                    <div className={styles.itemFilterBodyWrap}>
                        <p className={styles.itemLabel}>Campuses</p>
                        <BaseMultiSelect
                            defaultValue={filterBodyData.campuses}
                            options={campuses}
                            onChange={(selectedValue) => onChangeFilter("campuses", selectedValue)}
                            placeholder="Campuses"
                            disabled={disabled}
                        />
                    </div>
                </Col>
                <Col span={8}>
                    <div className={styles.itemFilterBodyWrap}>
                        <p className={styles.itemLabel}>Groups</p>
                        <BaseMultiSelect
                            defaultValue={filterBodyData.groups}
                            options={groups}
                            onChange={(selectedValue) => onChangeFilter("groups", selectedValue)}
                            placeholder="No groups specified"
                            disabled={disabled}
                        />
                    </div>
                </Col>
                <Col span={8}>
                    <div className={styles.itemFilterBodyWrap}>
                        <p className={styles.itemLabel}>Programs</p>
                        <BaseMultiSelect
                            defaultValue={filterBodyData.programs}
                            options={programs}
                            onChange={(selectedValue) => onChangeFilter("programs", selectedValue)}
                            placeholder="Programs"
                            disabled={disabled}
                        />
                    </div>
                </Col>
                <Col span={8}>
                    <div className={styles.itemFilterBodyWrap}>
                        <p className={styles.itemLabel}>Status</p>
                        <BaseMultiSelect
                            defaultValue={filterBodyData.statuses}
                            options={studentStatuses}
                            onChange={(selectedValue) => onChangeFilter("statuses", selectedValue)}
                            placeholder="Status"
                            disabled={disabled}
                        />
                    </div>
                </Col>
                <Col span={8}>
                    <div className={styles.itemFilterBodyWrap}>
                        <p className={styles.itemLabel}>Start date from</p>
                        <BaseDatePicker
                            defaultValue={filterBodyData.fromDate}
                            onChange={(newValue) => onChangeFilter("fromDate", newValue)}
                            disabled={disabled}
                            format={dateFormat}
                        />
                    </div>
                </Col>
                <Col span={8}>
                    <div className={styles.itemFilterBodyWrap}>
                        <p className={styles.itemLabel}>Start date to</p>
                        <BaseDatePicker
                            defaultValue={filterBodyData.toDate}
                            onChange={(newValue) => onChangeFilter("toDate", newValue)}
                            disabled={disabled}
                            format={dateFormat}
                        />
                    </div>
                </Col>
                <Col span={24}>
                    <div className={styles.filterBodyBtnWrap}>
                        <Button className={styles.filterBodyBtn} onClick={onClick}>
                            OK
                        </Button>
                    </div>
                </Col>
            </Row>
        )
    }
}
