import React from "react"
import {TableRowItem} from "./Table"
import styles from "./TriggerTable.module.css"
import {tableData} from "./rawData"

export default function TriggerTable(props) {
    const {onClickShowDetail, model} = props

    const onClickRowItem = () => {}

    return (
        <div className={styles.wrapper}>
            <table className={styles.table}>
                <thead className={styles.tableHead}>
                    <tr className={styles.tableRowHeader}>
                        <th className={`${styles.tableCell} ${styles.tableCellHeader}`}>Task name</th>
                        <th className={`${styles.tableCell} ${styles.tableCellHeader}`}>Trigger</th>
                        <th className={`${styles.tableCell} ${styles.tableCellHeader}`}>Department</th>
                        <th className={`${styles.tableCell} ${styles.tableCellHeader}`}>Responses</th>
                        <th className={`${styles.tableCell} ${styles.tableCellHeader}`}></th>
                    </tr>
                </thead>
                <tbody>
                    {tableData.map((item) => (
                        <TableRowItem
                            key={item.id}
                            data={item}
                            onClickShowDetail={onClickShowDetail}
                            onClickRowItem={onClickRowItem}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    )
}
