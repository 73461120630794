import React from "react"
import PropTypes from "prop-types"
import {Button, Menu, Dropdown, message} from "antd"
import {taskService} from "services"
import {Icon} from "components/Icons"
import styles from "../LibraryTable.module.css"

export default function ActionRowItem(props) {
    const {data} = props
    const onClickEditTask = () => {
        const {onClickShowDetail, data} = props
        onClickShowDetail(data)
    }

    const onClickAddToLibrary = () => {}

    // const onClickAddToPackage = () => {}

    const onClickDelete = async () => {
        const {data, onDeleteTaskSuccess} = props
        try {
            await taskService.deleteTask(data.id)
            message.success("Delete task successful")
            onDeleteTaskSuccess()
        } catch (error) {
            message.error("Delete task failure")
        }
    }
    const onConfigure = async () => {
        const {data} = props
        try {
            window.top.location.href = data.url
        } catch (error) {
            message.error("Sorry, couldn't get task info")
        }
    }
    const menu = (
        <Menu className={styles.menuAcitonWrap}>
            {/*
            <Menu.Item className={styles.menuAcitonItem} key="0" onClick={onClickEditTask}>
                <Icon className={styles.menuActionIcon} icon="PAPER_PLANE" />
                <span className={styles.menuActionTitle}>Edit Task</span>
            </Menu.Item>
            <Menu.Item className={styles.menuAcitonItem} key="1" onClick={onClickAddToLibrary}>
                <Icon className={styles.menuActionIcon} icon="LIBRARY" />
                <span className={styles.menuActionTitle}>Add To Libray</span>
            </Menu.Item>
            <Menu.Item className={styles.menuAcitonItem} key="2" onClick={onClickAddToPackage}>
                <Icon className={styles.menuActionPackageIcon} icon="PACKAGE" />
                <span className={styles.menuActionTitle}>Add To Package</span>
            </Menu.Item>
            */}
            {data.type === "dd" && (
                <Menu.Item className={styles.menuAcitonItem} key="2" onClick={onConfigure}>
                    <Icon className={styles.menuActionIcon} icon="EDIT" />
                    <span className={styles.menuActionTitle}>Configure</span>
                </Menu.Item>
            )}
            <Menu.Item className={styles.menuAcitonItem} key="3" onClick={onClickDelete}>
                <Icon className={styles.menuActionIcon} icon="DELETE" />
                <span className={styles.menuActionTitle}>Delete</span>
            </Menu.Item>
        </Menu>
    )

    return (
        <Dropdown overlay={menu} trigger={["click"]}>
            <Button className={styles.iconBtn} icon={<Icon icon="MORE_HORZ" />} />
        </Dropdown>
    )
}

ActionRowItem.propTypes = {
    data: PropTypes.object,
    onClickShowDetail: PropTypes.func,
    onDeleteTaskSuccess: PropTypes.func
}
