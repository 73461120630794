import React from "react"
import {findIndex, keyBy, pick, cloneDeep, isEmpty} from "lodash"
import moment from "moment"
import classNames from "classnames"
import {Divider, message} from "antd"
import Model from "Model"
import {taskService} from "services"
import {getValuesChangeTwoObj} from "helpers"
import {SUBTYPE} from "constant"
import {BaseButton} from "components/Button"
import {BaseError} from "components/Common"
import TaskHeaderSettingBody from "./TaskHeaderSettingBody"
import {
    ChooseConfigureTaskType,
    ConfigureTaskStudentFilter,
    ConfigureTaskStaffFilter,
    ConfigureTaskStaffTable,
    ConfigureTaskStudentTable,
    ConfigureTaskOtherTable
} from "../DefinedUsers"
import {userService} from "services"
import styles from "../ConfigureTask.module.css"

interface Props {
    data?: object
    model: Model
    subtype: string
    onSuccess: Function
    onClose: Function
    library?: boolean
}

interface State {
    taskData: any
    oldTaskData: any
    userTypes: Array<object>
    definedUsers: object
    students: Array<object>
    filterTypeActive: string
    tableTypeActive: string
    activeBox: any
    isSending: boolean
    errors: any
}

export default class ConfigureTaskForm extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        const {data} = props
        const initData = this.initialData(data)
        this.state = {
            taskData: cloneDeep(initData),
            oldTaskData: cloneDeep(initData),
            userTypes: [
                {id: "STUDENT", name: "Student"},
                {id: "STAFF", name: "Staff"},
                {id: "OTHER", name: "Other"}
            ],
            filterTypeActive: "",
            tableTypeActive: "",
            activeBox: undefined,
            isSending: false,
            errors: [],
            students: undefined,
            definedUsers: {}
        }
    }

    initialData(dataProps) {
        const {subtype} = this.props
        const result = {
            taskSettingType: "",
            name: "",
            description: "",
            dueDate: "",
            category: "",
            staffUser: "",
            taskType: "",
            type: "form",
            autoApprove: 0,
            subtype
        }
        if (!dataProps) return result
        return {...result, ...dataProps, dueDate: moment(dataProps.dueDate)}
    }

    onChangeTaskData = (key, value, checkBoxKey = "") => {
        const {taskData} = this.state
        const update: any = {}
        if (checkBoxKey) {
            const checkBoxKeyIndex = findIndex(taskData[checkBoxKey], (option) => option.key === key)
            if (checkBoxKeyIndex !== -1) {
                taskData[checkBoxKey][checkBoxKeyIndex].value = value
            }
        } else {
            taskData[key] = value
        }
        if (key === "form") {
            const formUsers = this.state.taskData.form.users
            const definedUsers: any = {}
            formUsers.forEach((user: any) => {
                definedUsers[user.order] = {
                    definedUserId: user.id,
                    index: user.order,
                    name: user.label,
                    isActive: false,
                    isAddNew: false,
                    isEdit: false
                }
                switch (user.type) {
                    case "staff":
                        definedUsers[user.order].userType = "STAFF"
                        break
                    case "student":
                        definedUsers[user.order].userType = "STUDENT"
                        break
                    case "other":
                        definedUsers[user.order].userType = "OTHER"
                        break
                }
            })
            update.definedUsers = definedUsers
        }
        update.taskData = taskData
        this.setState(update)
    }

    onSelect = (item) => {
        const {subtype} = this.props
        const {students, definedUsers} = this.state
        if (subtype === SUBTYPE.LIBRARY || subtype === SUBTYPE.ON_DEMAND) {
            return
        }
        const {userType} = item
        let filterTypeActive: ""
        let tableTypeActive = ""
        if (
            userType === "OTHER" ||
            (userType === "STUDENT" && students !== undefined) ||
            (userType === "STAFF" &&
                definedUsers[item.index] !== undefined &&
                definedUsers[item.index].users !== undefined &&
                definedUsers[item.index].users.length > 0)
        ) {
            tableTypeActive = userType
        } else {
            filterTypeActive = userType
        }

        this.setState({filterTypeActive, tableTypeActive, activeBox: item}, () => {
            if (
                userType === "OTHER" &&
                (definedUsers[item.index] === undefined ||
                    definedUsers[item.index].users === undefined ||
                    definedUsers[item.index].users.length === 0)
            ) {
                this.onClickAcceptFilter("OTHER", null)
            }
        })
    }

    onRemoveConfigureTaskTypeSuccess = (item) => {
        this.setState({filterTypeActive: "", tableTypeActive: ""})
    }
    cleaAllDefinedUsersOthers = () => {
        const {definedUsers} = this.state
        const newDefinedUsers = {}
        Object.keys(definedUsers).forEach((key) => {
            newDefinedUsers[key] = definedUsers[key]
            if (definedUsers[key].userType === "OTHER") {
                definedUsers[key].users = []
            }
        })
        this.setState({definedUsers: newDefinedUsers})
    }

    onClickAcceptFilter = async (type, filterData) => {
        const {definedUsers, activeBox, students} = this.state
        const update: any = {filterTypeActive: "", tableTypeActive: type}
        if (activeBox === undefined && type !== "STUDENT") {
            return
        }

        switch (type) {
            case "STUDENT":
                if (Array.isArray(filterData.students)) {
                    update.students = filterData.students
                } else {
                    const response = await userService.searchUsers({
                        ...filterData,
                        role: "student"
                    })
                    update.students = response.response
                }
                update.definedUsers = {
                    ...definedUsers,
                    [activeBox.index]: activeBox
                }
                this.cleaAllDefinedUsersOthers()
                break
            case "OTHER":
                const users =
                    students === undefined
                        ? []
                        : students.map((student) => {
                              return {
                                  email: "",
                                  name: "",
                                  student
                              }
                          })
                update.definedUsers = {
                    ...definedUsers,
                    [activeBox.index]: {
                        ...activeBox,
                        listEnabled: false,
                        users
                    }
                }
                break
            case "STAFF":
                update.definedUsers = {
                    ...definedUsers,
                    [activeBox.index]: {
                        ...activeBox,
                        users: filterData.members
                    }
                }
                break
        }
        this.setState(update)
    }

    onClickSendBtn = () => {
        const {taskData} = this.state
        const errors = this.validateDataBeforeSubmit()
        if (errors) {
            this.setState({errors})
            return
        }
        this.setState({errors: {}})
        if (taskData.id) this.onUpdateTask()
        else this.onCreateTask()
    }

    validateDataBeforeSubmit = () => {
        let errors: any = {}
        const {taskData, definedUsers, students} = this.state
        const {library} = this.props

        if (!taskData.name) errors.name = true
        if (!taskData.dueDate) errors.dueDate = true
        if (!taskData.description) errors.description = true
        if (!taskData.form) errors.form = true
        if (!taskData.category) errors.category = true
        if (!library) {
            errors.definedUsers = {}

            Object.keys(definedUsers).forEach((key) => {
                if (definedUsers[key].userType === "STUDENT" && (students === undefined || students.length === 0)) {
                    errors.definedUsers[key] = "Select at least one Student"
                } else if (
                    definedUsers[key].userType === "STAFF" &&
                    (definedUsers[key].users === undefined || definedUsers[key].users.length === 0)
                ) {
                    errors.definedUsers[key] = `Select the ${definedUsers[key].name}`
                } else if (definedUsers[key].userType === null) {
                    errors.definedUsers[key] = "Select an user"
                }
            })
        }

        if (isEmpty(errors.definedUsers)) {
            delete errors.definedUsers
        }
        return isEmpty(errors) ? null : errors
    }

    onCreateTask = async () => {
        const {taskData, students, definedUsers} = this.state
        const {subtype, library} = this.props
        taskData.categoryID = taskData.category.id || ""
        taskData.staffUserID = taskData.staffUser.id || ""
        const submitData = pick(taskData, [
            "name",
            "parentId",
            "description",
            "type",
            "dueDate",
            "categoryID",
            "autoApprove"
        ])
        if (taskData.dueDate) {
            submitData.dueDate = moment(taskData.dueDate)
                .format("YYYY-MM-DD")
                .toString()
        }
        submitData.subtype = subtype
        if (taskData.reminderOption) {
            submitData.reminders = taskData.reminderOption.map((option) => option.id)
        }
        if (taskData.form) {
            submitData.formId = taskData.form.id
        }

        if (taskData.staffUser) {
            submitData.cc = taskData.staffUser.map((staff) => staff.id)
        }

        try {
            this.setState({isSending: true})
            const task = await taskService.createTask(submitData)

            if (!library) {
                const definedUserstoAssign = Object.keys(definedUsers).map((key) => {
                    const item: any = {
                        type: definedUsers[key].userType.toLowerCase(),
                        definedUserId: definedUsers[key].definedUserId
                    }
                    if (definedUsers[key].userType === "STAFF") {
                        item.userIds = definedUsers[key].users.map((user) => user.id)
                    }
                    if (definedUsers[key].userType === "OTHER") {
                        item.assigned = definedUsers[key].users.map((user) => {
                            return {
                                studentId: user.student.id,
                                name: user.name,
                                email: user.email
                            }
                        })
                    }
                    return item
                })

                const payload = {
                    taskId: task.id,
                    studentIds: students.map((s: any) => s.id),
                    definedUsers: definedUserstoAssign
                }

                await taskService.assignTask(payload)
            }
            message.success("Create task successful")
            await this.getDashboardData()
            this.setState({isSending: false}, () => {
                this.props.onSuccess()
            })
        } catch (error) {
            message.error("Create task failure")
        } finally {
            this.setState({isSending: false})
        }
    }

    async getDashboardData() {
        const {model} = this.props
        await model.loadDashboardData()
    }
    onUpdateTask = async () => {
        const {taskData, oldTaskData} = this.state
        const attrs = ["name", "parentId", "description", "type", "dueDate", "autoApprove"]
        const oldData = pick(oldTaskData, attrs)
        const newData = pick(taskData, attrs)
        const dataSubmit = getValuesChangeTwoObj(oldData, newData)
        if (!dataSubmit) {
            this.props.onClose()
            return
        }
        try {
            this.setState({isSending: true})
            await taskService.updateTask(taskData.id, dataSubmit)
            message.success("Update task successful")
            this.setState({isSending: false}, () => {
                this.props.onSuccess()
            })
        } catch (error) {
            message.error("Update task failure")
        }
    }
    onDefinedUserTableEdit = (type) => {
        const {tableTypeActive} = this.state
        const update: any = {}
        if (type === "STUDENT") {
            update.students = []
        } else if (type === "STAFF") {
            const {definedUsers, activeBox} = this.state
            definedUsers[activeBox.index].users = []
        }
        if (tableTypeActive === type) {
            update.tableTypeActive = ""
            update.filterTypeActive = type
        }

        this.setState(update)
    }
    onOthersChange = (data) => {
        const {definedUsers, activeBox} = this.state
        const newDefinedUsers = {
            ...definedUsers
        }
        newDefinedUsers[activeBox.index].users = data
        this.setState({definedUsers: newDefinedUsers})
    }
    onOthersEnableListChange = (enabled) => {
        const {definedUsers, activeBox} = this.state
        const newDefinedUsers = {
            ...definedUsers
        }
        definedUsers[activeBox.index].listEnabled = enabled
        this.setState({definedUsers: newDefinedUsers})
    }

    renderFilterByType = () => {
        const {filterTypeActive, userTypes} = this.state
        const {model} = this.props
        const userTypesKeyByID = keyBy(userTypes, "id")
        switch (filterTypeActive) {
            case userTypesKeyByID.STUDENT.id:
                return (
                    <ConfigureTaskStudentFilter
                        type="STUDENT"
                        model={model}
                        onClickAcceptFilter={this.onClickAcceptFilter}
                    />
                )
            case userTypesKeyByID.STAFF.id:
                return (
                    <ConfigureTaskStaffFilter
                        type="STAFF"
                        model={model}
                        onClickAcceptFilter={this.onClickAcceptFilter}
                    />
                )
            default:
                return null
        }
    }

    renderTableByType = () => {
        const {tableTypeActive, userTypes, activeBox} = this.state
        const userTypesKeyByID = keyBy(userTypes, "id")
        switch (tableTypeActive) {
            case userTypesKeyByID.STUDENT.id:
                return (
                    <ConfigureTaskStudentTable
                        data={this.state.students}
                        onEdit={() => this.onDefinedUserTableEdit("STUDENT")}
                    />
                )
            case userTypesKeyByID.STAFF.id:
                return (
                    <ConfigureTaskStaffTable
                        data={
                            this.state.definedUsers[activeBox.index]
                                ? this.state.definedUsers[activeBox.index].users
                                : []
                        }
                        onEdit={() => this.onDefinedUserTableEdit("STAFF")}
                    />
                )
            case userTypesKeyByID.OTHER.id:
                return (
                    <ConfigureTaskOtherTable
                        data={
                            this.state.definedUsers[activeBox.index]
                                ? this.state.definedUsers[activeBox.index].users
                                : undefined
                        }
                        onChange={this.onOthersChange}
                        onEnableList={this.onOthersEnableListChange}
                        listEnabled={
                            this.state.definedUsers[activeBox.index]
                                ? this.state.definedUsers[activeBox.index].listEnabled
                                : false
                        }
                    />
                )
            default:
                return null
        }
    }

    render() {
        const {taskData, userTypes, isSending, errors} = this.state
        const {model, library} = this.props

        return (
            <>
                <TaskHeaderSettingBody
                    data={taskData}
                    onChangeTaskData={this.onChangeTaskData}
                    model={model}
                    errors={errors}
                    library={library}
                />
                <Divider className={styles.divider} />
                {this.state.taskData.form && (
                    <ChooseConfigureTaskType
                        editable={false}
                        definedUsers={this.state.taskData.form.users}
                        userTypes={userTypes}
                        onSelect={this.onSelect}
                        onRemoveConfigureTaskTypeSuccess={this.onRemoveConfigureTaskTypeSuccess}
                        isCreateForm={true}
                        errors={errors.definedUsers || {}}
                    />
                )}

                {this.renderFilterByType()}
                {this.renderTableByType()}
                <div className={styles.footerWrap}>
                    <div className={classNames(styles.footerBtnWrap, styles.saveBtnWrap)}>
                        <BaseButton
                            title={library ? "CREATE" : "SEND"}
                            loading={isSending}
                            onClick={this.onClickSendBtn}
                        />
                    </div>
                </div>
            </>
        )
    }
}
