import React from "react"
import {Row, Col, Button} from "antd"
import classNames from "classnames"
import Model from "Model"
import {KlassDropdown} from "components/KlassDropdown"
import styles from "./ConfigureTaskTypeFilter.module.css"

interface Props {
    type: string
    onClickAcceptFilter: Function
    model: Model
}

export default class ConfigureTaskStaffFilter extends React.Component<Props, {}> {
    state = {
        filterBodyData: {
            members: []
        }
    }

    onChangeFilter = (key, value) => {
        const {filterBodyData} = this.state
        filterBodyData[key] = value
        this.setState({filterBodyData})
    }

    onClickOKButton = () => {
        const {onClickAcceptFilter, type} = this.props
        const {filterBodyData} = this.state
        onClickAcceptFilter(type, filterBodyData)
    }

    render() {
        const {filterBodyData} = this.state
        const {staffUsers} = this.props.model

        return (
            <Row>
                <Col span={16}>
                    <div className={styles.itemStaff}>
                        <p className={styles.itemLabel}>Staff user</p>
                        <div className={styles.itemStaffFilterBodyWrap}>
                            <div className={styles.itemStaffDropDownWrap}>
                                <KlassDropdown
                                    onChange={(selectedOption) => this.onChangeFilter("members", selectedOption)}
                                    options={staffUsers}
                                    value={filterBodyData.members}
                                    getOptionLabel={(option: any) => `${option.first_name} ${option.last_name}`}
                                    placeholder="Select a staff member"
                                    isMulti
                                />
                            </div>
                            <Button
                                className={classNames(styles.filterBodyBtn, styles.staffFilterBodyBtn)}
                                onClick={this.onClickOKButton}>
                                OK
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>
        )
    }
}
