import React from "react"
import {Tabs} from "antd"
import * as QueryString from "query-string"
import {observer} from "mobx-react"
import {Icon} from "components/Icons"
import {BaseLoading} from "components/Loading"
import {BaseSearchInput} from "components/Input"
import {BasePagination} from "components/Pagination"
import {taskService} from "services"
import {AllTab} from "./Tab"
import Model from "Model"
import styles from "./task.module.css"

const {TabPane} = Tabs

interface Props {
    model: Model
    history: any
}

@observer
export default class GoKlassTasks extends React.Component<Props, {}> {
    state = {
        isLoading: false,
        isLoadingTask: false,
        numberTaskPerPage: 20,
        page: 1,
        data: [],
        total: 0,
        activeTabKey: "1",
        searchData: "",
        filteredStatus: "",
        subType: "",
        tabKeyByIndex: {
            1: "all",
            2: "open",
            3: "overdue"
        }
    }

    componentDidMount() {
        const {numberTaskPerPage, page} = this.state
        const {location} = this.props.history
        const searchParams: any = QueryString.parse(location.search)
        let params = {limit: numberTaskPerPage, offset: (page - 1) * numberTaskPerPage}
        const dataState: any = {}
        if (searchParams) {
            const {token, ...rest} = searchParams
            params = {...params, ...rest}
            if (searchParams.page) {
                const searchParamsPage = parseInt(searchParams.page, 10)
                params.offset = (searchParamsPage - 1) * numberTaskPerPage
            }
            if (searchParams.search) {
                dataState.searchData = searchParams.search || ""
            }
            if (searchParams.filter) {
                dataState.filteredStatus = searchParams.filter
            }
        }
        this.setState({...dataState}, () => {
            this.getListTasks(params)
        })
    }

    getListTasks = async (params) => {
        this.setState({isLoadingTask: true})
        const {subType, searchData, filteredStatus} = this.state
        if (subType) {
            params.subtype = subType
        }
        params.search = searchData
        params.filter = filteredStatus
        const {data, total} = await taskService.getListTasksForUser(11, params)
        this.setState({data, total, isLoadingTask: false})
    }

    onChangePage = async (page) => {
        const {numberTaskPerPage} = this.state
        const params = {limit: numberTaskPerPage, offset: (page - 1) * numberTaskPerPage}
        this.setState({page}, () => {
            this.getListTasks(params)
        })
    }

    onReGetListTasks = async () => {
        const {numberTaskPerPage, page} = this.state
        const params = {limit: numberTaskPerPage, offset: (page - 1) * numberTaskPerPage}
        await this.getListTasks(params)
    }

    onSearch = async (value) => {
        const {numberTaskPerPage, page} = this.state
        const {history} = this.props
        const {location} = history
        const searchParams = QueryString.parse(location.search)
        searchParams.search = value
        location.search = this.getQueryStringFromSearchParams(searchParams)
        history.replace({...location})
        const params = {limit: numberTaskPerPage, offset: (page - 1) * numberTaskPerPage}
        await this.getListTasks(params)
    }

    onChangeSearch = (event) => {
        this.setState({searchData: event.target.value})
    }

    onChangeTab = (activeKey) => {
        const {tabKeyByIndex, numberTaskPerPage} = this.state
        this.setState({page: 1, subType: tabKeyByIndex[activeKey], activeTabKey: activeKey}, () => {
            const params = {limit: numberTaskPerPage, offset: 0}
            this.getListTasks(params)
        })
    }

    onFilterTaskByStatus = async (status) => {
        const {numberTaskPerPage, page, activeTabKey} = this.state
        const dataState: any = {filteredStatus: status}
        if (activeTabKey !== "1") {
            dataState.activeTabKey = "1"
            dataState.subType = ""
        }
        const {history} = this.props
        const {location} = history
        const searchParams = QueryString.parse(location.search)
        searchParams.filter = status
        location.search = this.getQueryStringFromSearchParams(searchParams)
        history.replace({...location})
        const params = {limit: numberTaskPerPage, offset: (page - 1) * numberTaskPerPage}
        console.log("params", params)
        this.setState({...dataState}, () => {})
    }

    getQueryStringFromSearchParams = (searchParams) => {
        let queryString = ""
        const searchParamOfKeys = Object.keys(searchParams)
        searchParamOfKeys.forEach((key, index) => {
            queryString += `${key}=${searchParams[key]}`
            if (index !== searchParamOfKeys.length - 1) {
                queryString += "&"
            }
        })
        return queryString
    }

    render() {
        const {isLoading, isLoadingTask, data, total, page, numberTaskPerPage, activeTabKey, searchData} = this.state
        const {model} = this.props

        return (
            <div className={styles.wrapper}>
                <BaseLoading isShow={isLoading} />
                <div className={styles.tasksHeaderWrap}>
                    <p className={styles.taskHeaderTitle}>Task</p>
                    <div>
                        <BaseSearchInput
                            onSearch={this.onSearch}
                            placeholder="Enter task name"
                            defaultValue={searchData}
                            value={searchData}
                            onChange={this.onChangeSearch}
                        />
                    </div>
                </div>
                <Tabs className="fullwidth" activeKey={activeTabKey} onChange={this.onChangeTab}>
                    <TabPane
                        className={styles.tabItem}
                        tab={
                            <span>
                                <Icon className={styles.tabIcon} icon="PEOPLE" />
                                <span className={styles.tabTitle}>All</span>
                            </span>
                        }
                        key="1">
                        <AllTab
                            model={model}
                            isLoading={isLoading}
                            isLoadingTask={isLoadingTask}
                            data={data}
                            onReGetListTasks={this.onReGetListTasks}
                        />
                        <div className={styles.paginationWrap}>
                            <BasePagination
                                page={page}
                                total={total}
                                onChangePage={this.onChangePage}
                                defaultPageSize={numberTaskPerPage}
                            />
                        </div>
                    </TabPane>
                    {/* <TabPane
                        className={styles.tabItem}
                        tab={
                            <span>
                                <Icon className={styles.tabIcon} icon="LIBRARY" />
                                <span className={styles.tabTitle}>Open</span>
                            </span>
                        }
                        key="2">
                        <OpenTab
                            model={model}
                            isLoading={isLoading}
                            isLoadingTask={isLoadingTask}
                            data={data}
                            onReGetListTasks={this.onReGetListTasks}
                        />
                        <div className={styles.paginationWrap}>
                            <BasePagination
                                page={page}
                                total={total}
                                onChangePage={this.onChangePage}
                                defaultPageSize={numberTaskPerPage}
                            />
                        </div>
                    </TabPane>
                    <TabPane
                        className={styles.tabItem}
                        tab={
                            <span>
                                <Icon className={styles.tabIcon} icon="PEOPLE" />
                                <span className={styles.tabTitle}>Overdue</span>
                            </span>
                        }
                        key="3">
                        <OverdueTab
                            model={model}
                            isLoading={isLoading}
                            isLoadingTask={isLoadingTask}
                            data={data}
                            onReGetListTasks={this.onReGetListTasks}
                        />
                        <div className={styles.paginationWrap}>
                            <BasePagination
                                page={page}
                                total={total}
                                onChangePage={this.onChangePage}
                                defaultPageSize={numberTaskPerPage}
                            />
                        </div>
                    </TabPane> */}
                </Tabs>
            </div>
        )
    }
}
