import React, {Component} from "react"
import {Document, Page, pdfjs} from "react-pdf"

import "react-pdf/dist/Page/AnnotationLayer.css"

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

interface Props {
    fileName: string
}

interface State {
    numPages: any
}
function Iframe(props) {
    return <div dangerouslySetInnerHTML={{__html: props.iframe ? props.iframe : ""}} />
}

export default class PDFViewer extends Component<Props, State> {
    state = {numPages: null}

    onDocumentLoadSuccess = ({numPages}) => {
        this.setState({numPages})
    }

    render() {
        const {numPages} = this.state
        const {fileName} = this.props
        return <Iframe iframe={`<iframe src="${fileName}" style="width:100%;height:700px;">`} />
        return (
            <Document
                file={fileName}
                onLoadSuccess={this.onDocumentLoadSuccess}
                className="pdf-document-wrap"
                externalLinkTarget="_blank">
                {Array.from(new Array(numPages), (el, index) => (
                    <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
            </Document>
        )
    }
}
