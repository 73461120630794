import React from "react"
import {Button} from "antd"
import {Icon} from "components/Icons"
import {PDFViewer} from "components/FileViewer"
import styles from "./ResponsesTab.module.css"

const Files = ({type, files}) => {
    return files.map((file, idx) => {
        if (file.endsWith(".pdf")) {
            return <PDFViewer fileName={file} />
        } else {
            return <img src={file} />
        }
    })
}

export function TaskPDFViewer(props) {
    const {data, model} = props
    if (!data.subtasks || !data.subtasks.length) {
        return null
    }
    if (data.subtasks.length === 1) {
        const {files} = data.subtasks[0]
        if (!files) {
            return null
        }
        return (
            <div className={styles.pdfViewWrap}>
                <Files type={data.type} files={files} />
            </div>
        )
    }

    const activeSubTask = data.subtasks.find((subtask) => subtask.isActive)

    const onClickPrevBtn = (subtask, isFirst) => {
        if (isFirst) {
            return
        }
        const subtasks = data.subtasks.map((item) => {
            item.isActive = item.index === subtask.index - 1
            return item
        })
        const newTask = {...data, subtasks}
        model.updateTask(newTask)
    }

    const onClickNextBtn = (subtask, isLast) => {
        if (isLast) {
            return
        }
        const subtasks = data.subtasks.map((item) => {
            item.isActive = item.index === subtask.index + 1
            return item
        })
        const newTask = {...data, subtasks}
        model.updateTask(newTask)
    }

    if (!activeSubTask) {
        return (
            <div className={styles.pdfViewWrap}>
                <div className={styles.buttonWrap}>
                    <Button className={styles.preIcon} icon={<Icon icon="ARROW_LEFT_CIRCLE" color="#ccc" />} />
                    <Button className={styles.nextIcon} icon={<Icon icon="ARROW_RIGHT_CIRCLE" color="#ccc" />} />
                </div>
            </div>
        )
    }
    if (!activeSubTask.files) {
        return null
    }
    return (
        <div className={styles.pdfViewWrap}>
            <div className={styles.buttonWrap}>
                <Button
                    className={styles.preIcon}
                    icon={<Icon icon="ARROW_LEFT_CIRCLE" color={activeSubTask.isFirst ? "#ccc" : "#1E90FF"} />}
                    onClick={() => onClickPrevBtn(activeSubTask, activeSubTask.isFirst)}
                />
                <Button
                    className={styles.nextIcon}
                    icon={<Icon icon="ARROW_RIGHT_CIRCLE" color={activeSubTask.isLast ? "#ccc" : "#1E90FF"} />}
                    onClick={() => onClickNextBtn(activeSubTask, activeSubTask.isLast)}
                />
            </div>
            <div>
                <Files type={data.type} files={activeSubTask.files} />
            </div>
        </div>
    )
}
