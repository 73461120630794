import React from "react"
import {Select} from "antd"
import classNames from "classnames"
import styles from "./BaseMultiSelect.module.css"

const {Option} = Select

export function BaseMultiSelect(props) {
    const {options, className, onChange, valueKey, labelKey, ...rest} = props
    const valueKeySelect = valueKey || "id"
    const labelKeySelect = labelKey || "name"

    return (
        <Select
            mode="multiple"
            onChange={onChange}
            className={classNames(styles.baseMultiSelect, className)}
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            {...rest}>
            {options.map((option, index) => (
                <Option key={index} value={option[valueKeySelect]}>
                    {Array.isArray(labelKeySelect)
                        ? labelKeySelect.map((key) => `${option[key]} `)
                        : option[labelKeySelect]}
                </Option>
            ))}
        </Select>
    )
}
