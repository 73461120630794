import React, {useState} from "react"
import {TableRowItem} from "./Table"
import styles from "./LibraryTable.module.css"
import {SortHeader} from "components/SortHeader"

export default function LibraryTable(props) {
    const [sortBy, setSortBy] = useState("user")
    const [sortValue, setSortValue] = useState(0)
    const {onClickShowDetail, data, model, onDeleteTaskSuccess} = props

    const onSortClick = (sortBy) => {
        return (sortValue) => {
            setSortValue(sortValue)
            setSortBy(sortBy)
        }
    }
    const sort = (a, b) => {
        if (sortBy === "name") {
            return a.name.localeCompare(b.name) * sortValue
        }
    }

    return (
        <div className={styles.wrapper}>
            <table className={styles.table}>
                <thead className={styles.tableHead}>
                    <tr className={styles.tableRowHeader}>
                        <SortHeader
                            title={"Task name"}
                            onClick={onSortClick("name")}
                            className={`${styles.tableCell} ${styles.tableCellHeader}`}
                        />
                        <th className={`${styles.tableCell} ${styles.tableCellHeader}`}>Type</th>
                        <th className={`${styles.tableCell} ${styles.tableCellHeader}`}>Tasks Assigned</th>
                        <th className={`${styles.tableCell} ${styles.tableCellHeader}`}>Added</th>
                        <th className={`${styles.tableCell} ${styles.tableCellHeader}`}></th>
                    </tr>
                </thead>
                <tbody>
                    {data.sort(sort).map((item) => (
                        <TableRowItem
                            key={item.id}
                            data={item}
                            onClickShowDetail={onClickShowDetail}
                            onDeleteTaskSuccess={onDeleteTaskSuccess}
                            onClickRowItem={onClickShowDetail}
                            model={model}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    )
}
