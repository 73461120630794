import {observable} from "mobx"
import {cloneDeep} from "lodash"
import {taskService, userService} from "services"
import {api_response} from "./interfaces"

export default class Model {
    @observable
    public digitalDocuments: api_response.DigitalDocuments[] = []

    @observable
    public documentCategories: api_response.DigitalDocuments[] = []

    @observable
    public programs: api_response.Programs[] = []

    @observable
    public campuses: api_response.Campuses[] = []

    @observable
    public studentStatuses: api_response.StudentStatuses[] = []

    @observable
    public staffUsers: api_response.StaffUsers[] = []

    @observable
    public groups: api_response.Groups[] = []

    @observable
    public forms: api_response.Forms[] = []

    @observable
    public taskId: number

    @observable
    public task: any

    @observable
    public user: any

    @observable
    public dateFormat: any

    @observable
    public libraryTask: any

    @observable
    public activeTabKey: string = "1"

    @observable
    public dashboardData = {
        all: 0,
        completed: 0,
        overdue: 0,
        submitted: 0,
        submittedWaitingForMe: 0
    }

    public studentUsers: api_response.StudentUsers[] = []

    public async loadOneTask(taskId) {
        this.taskId = taskId
        this.reloadOneTask()
    }

    public async loadMe() {
        const {user} = await userService.getMe()
        this.user = user
        return user
    }

    public async loadDateFormat() {
        const format = "MM/DD/YYYY"
        this.dateFormat = format
        return format
    }

    public async clearTask() {
        this.task = undefined
    }

    public async reloadOneTask() {
        this.task = await taskService.getTaskDetail(this.taskId)
    }

    public async updateTask(task) {
        this.task = cloneDeep(task)
    }

    async loadDashboardData() {
        this.dashboardData = await taskService.getDashboardData()
    }

    public async loadDigitalDocuments(params = {}) {
        const {response} = await taskService.getDigitalDocuments(params)
        this.digitalDocuments = response
    }

    public async loadDocumentCategories(params = {}) {
        const {response} = await taskService.getDocumentCategories(params)
        this.documentCategories = response
        return response
    }

    public async loadPrograms(params = {}) {
        const {response} = await taskService.getPrograms(params)
        this.programs = response
        return response
    }

    public async loadForms(params = {}) {
        const response = await taskService.getForms(params)
        this.forms = response
        return response
    }

    public async loadCampuses(params = {}) {
        const {response} = await taskService.getCampuses(params)
        this.campuses = response
        return response
    }

    public async loadStudentStatus(params = {}) {
        const {response} = await taskService.getStudentStatuses(params)
        this.studentStatuses = response
        return response
    }

    public async loadGroups(params = {}) {
        const {response} = await taskService.getGroups(params)
        this.groups = response
        return response
    }

    public async loadStaffUsers(params = {}) {
        const {response} = await taskService.getStaffUsers(params)
        this.staffUsers = response
        return response
    }

    public async loadStudentUsers(params = {}) {
        const {response} = await userService.searchUsersByName(params)
        this.studentUsers = response
        return response
    }
}
