import React from "react"
import {BaseButton} from "components/Button"
import styles from "../task.module.css"

interface Props {
    number: number
    status: string
    desc: string
    onClick: Function
}

export default function TaskCard(props: Props) {
    const {number, status, desc, onClick} = props

    return (
        <div className={styles.taskCardWrap}>
            <div className={styles.taskCardNo}>{number}</div>
            <div className={styles.taskCardStatus}>{status}</div>
            <div className={styles.taskCardDesc}>{desc}</div>
            <BaseButton title="Show" className={styles.taskCardBtn} onClick={onClick} />
        </div>
    )
}
