import {get} from "lodash"
import BaseService from "./Base"
import moment from "moment"

class UserService extends BaseService {
    async generateToken(data, params = {}) {
        const response = await this.post("/users/1.2/login", data, params)
        return get(response, "data", {})
    }
    async getMe(params = {}) {
        const response = await this.get("/users/1.2/getme", params)
        return get(response, "data", {})
    }

    async searchUsersByName(params = {}) {
        const response = await this.get("/users/1.2/name_or_collegeid", params)
        return get(response, "data", [])
    }

    async searchUsers(params: any = {}) {
        const payload: any = {
            role: params.role
        }
        const filters = params
        if (filters.campuses.length > 0) {
            payload.locations = {in: filters.campuses.join(",")}
        }
        if (filters.groups.length > 0) {
            payload.groups = {in: filters.groups.join(",")}
        }
        if (filters.programs.length > 0) {
            payload.major = {in: filters.programs.join(",")}
        }
        if (filters.statuses.length > 0) {
            payload.status = {in: filters.statuses.join(",")}
        }
        if (filters.fromDate) {
            payload.start_date = {gt: moment(filters.fromDate).format("YYYY-MM-DD")}
        }
        if (filters.toDate) {
            if (!payload.start_date) {
                payload.start_date = {}
            }
            payload.start_date.lt = moment(filters.toDate).format("YYYY-MM-DD")
        }
        const response = await this.get(`/users/1.2/search?search=${JSON.stringify(payload)}&limit=1000`, {}, false)
        return get(response, "data", [])
    }
}

export default UserService
