import React from "react"
import {AddCircleButton} from "components/Button"
import ChooseConfigureTaskTypeItem from "./ChooseConfigureTaskTypeItem"
import styles from "./ChooseConfigureTaskType.module.css"

interface Props {
    definedUsers?: Array<Object>
    editable?: boolean
    userTypes: Array<object>
    onChange?: Function
    onSelect: Function
    onLoad?: Function
    onAddNewUser?: Function
    onRemoveConfigureTaskTypeSuccess: Function
    isCreateForm?: boolean
    errors?: Array<any>
}

export default class ChooseConfigureTaskType extends React.Component<Props, {}> {
    state = {
        items: []
    }

    componentDidMount() {
        this.populateItemsFromProps()
    }

    componentDidUpdate(prevProps) {
        const {definedUsers, errors} = this.props
        if (definedUsers !== prevProps.definedUsers || errors !== prevProps.errors) {
            this.populateItemsFromProps()
        }
    }
    private populateItemsFromProps() {
        const {definedUsers, errors} = this.props
        if (definedUsers === undefined) {
            let items = this.state.items.map((item: any) => {
                if (errors) {
                    item.errorMessage = errors[item.index] || ""
                }
                return item
            })
            this.setState({items})
            return
        }
        const items = definedUsers.map((user: any, i) => {
            let errorMessage
            const index = i + 1
            if (errors && index) {
                errorMessage = errors[index] || ""
            }

            const item: any = {
                definedUserId: user.id,
                index: index,
                name: user.label,
                assigned: user.assigned,
                responses: user.responses,
                isActive: false,
                isAddNew: false,
                isEdit: false,
                errorMessage
            }
            switch (user.type) {
                case "staff":
                    item.userType = "STAFF"
                    break
                case "student":
                    item.userType = "STUDENT"
                    break
                case "other":
                    item.userType = "OTHER"
                    break
            }
            return item
        })
        if (this.props.onLoad) {
            this.props.onLoad(items)
        }
        this.setState({items})
    }

    onClickAddItem = () => {
        let {items} = this.state
        const {onAddNewUser} = this.props
        const index = items.length
        items = items.map((item) => {
            item.isActive = false
            return item
        })
        const item = {index, userType: null, name: "", isAddNew: true, isEdit: false, isActive: false}
        items.push(item)
        this.setState({items})
        onAddNewUser && onAddNewUser(item)
    }

    onCloseItem = (item) => {
        let {items} = this.state
        if (item.isAddNew) {
            items = items.filter((item) => !item.isAddNew && !item.isEdit)
            this.setState({items})
        }
    }

    onAddItem = (newItem) => {
        let {items} = this.state
        const {onSelect, onChange} = this.props
        items = items.filter((item) => !item.isAddNew && !item.isEdit)
        newItem = {...newItem, isAddNew: false, isEdit: false, isActive: true}
        items.push(newItem)
        this.setState({items})
        if (onChange) onChange(items)

        onSelect(newItem)
    }

    onClickEditItem = (editItem) => {
        let {items} = this.state
        items = items.map((item) => {
            item.isEdit = item.index === editItem.index
            return item
        })
        this.setState({items})
    }

    onClick = (editItem) => {
        if (!this.props.isCreateForm) {
            return
        }
        let newItems = [...this.state.items]
        newItems = newItems.map((item) => {
            item.isActive = item.index === editItem.index
            if (item.index === editItem.index) {
                this.props.onSelect(item)
            }
            return item
        })

        this.setState({items: newItems})
    }

    onClickRemoveItem = (removeItem) => {
        let {items} = this.state
        const {onRemoveConfigureTaskTypeSuccess, onChange} = this.props
        items = items.filter((item) => item.index !== removeItem.index)
        this.setState({items})
        if (onChange) onChange(items)
        onRemoveConfigureTaskTypeSuccess(removeItem)
    }

    render() {
        const {items} = this.state
        const {userTypes, editable, isCreateForm} = this.props
        const isHaveAddNewItem = items.some((item) => item.isAddNew)

        return (
            <div className={styles.wrapper}>
                <div className={styles.container}>
                    <ul className={styles.listItem}>
                        {items.map((item, index) => (
                            <ChooseConfigureTaskTypeItem
                                key={index}
                                item={item}
                                index={index}
                                editable={editable}
                                onAddItem={this.onAddItem}
                                onCloseItem={this.onCloseItem}
                                onClickEditItem={this.onClickEditItem}
                                onClickRemoveItem={this.onClickRemoveItem}
                                onClick={this.onClick}
                                userTypes={userTypes}
                                isCreateForm={isCreateForm}
                            />
                        ))}
                        {!isHaveAddNewItem && editable !== false && (
                            <div className={styles.item}>
                                <p className={styles.definedUser__title}>Add a defined user</p>
                                <AddCircleButton onClick={this.onClickAddItem} />
                            </div>
                        )}
                    </ul>
                </div>
            </div>
        )
    }
}
