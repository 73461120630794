import React from "react"

export type IconType =
    | "SORT_SOLID"
    | "SORT_UP_SOLID"
    | "SORT_DOWN_SOLID"
    | "SENT"
    | "VIEWED"
    | "DONE"
    | "OPTIONS"
    | "FILE"
    | "DOLLAR_SIGN"
    | "CALENDAR"
    | "PROFILE"
    | "SEND"
    | "COPY"
    | "ADD"
    | "EDIT"
    | "CERTIFICATE"
    | "WARNING"
    | "SMALL_PROFILE"
    | "MORE_HORZ"
    | "ARROW_RIGHT"
    | "ARROW_DOWN"
    | "CHECKED"
    | "LIBRARY"
    | "PACKAGE"
    | "PAPER_PLANE"
    | "WARNING_TRIANGLE"
    | "PEOPLE"
    | "BAR_CHART"
    | "PERSON"
    | "SETTINGS"
    | "CLOSE"
    | "PLUS"
    | "DELETE"
    | "UNORDER_LIST"
    | "SCAN_TASK"
    | "DIGITAL_DOCUMENT_TASK"
    | "EYE"
    | "BACK"
    | "CHECK_LIST"
    | "STUDENT_ICON"
    | "STAFF_ICON"
    | "NOTIFY"
    | "DUPLICATE"
    | "HAND"
    | "QUESTION"
    | "SEARCH"
    | "MEN_WOMEN"
    | "CLOUD_UPLOAD"
    | "ARROW_LEFT_CIRCLE"
    | "ARROW_RIGHT_CIRCLE"
    | "INFO"
    | "FILE_EMPTY"

interface Props {
    icon: IconType
    className?: string
    classNamePath?: string
    color?: string
}
export function Icon({className, icon, classNamePath, color}: Props) {
    const fillColor = color || "#1E90FF"

    switch (icon) {
        case "SORT_SOLID":
            return (
                <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="sort"
                    className={className}
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512">
                    <path
                        fill="currentColor"
                        d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41zm255-105L177 64c-9.4-9.4-24.6-9.4-33.9 0L24 183c-15.1 15.1-4.4 41 17 41h238c21.4 0 32.1-25.9 17-41z"></path>
                </svg>
            )
        case "SORT_UP_SOLID":
            return (
                <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="sort-up"
                    className={className}
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512">
                    <path
                        fill="currentColor"
                        d="M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z"></path>
                </svg>
            )
        case "SORT_DOWN_SOLID":
            return (
                <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="sort-down"
                    className={className}
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512">
                    <path
                        fill="currentColor"
                        d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z"></path>
                </svg>
            )
        case "PROFILE":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25">
                    <path
                        fill="#939393"
                        fillRule="evenodd"
                        d="M12 13.596a7 7 0 0 1 7 7l-.007.116a1 1 0 0 1-.993.884H6a1 1 0 0 1-1-1l.004-.241A7 7 0 0 1 12 13.595zm0-10a4 4 0 1 1 0 8l-.2-.005A4 4 0 0 1 8 7.596l.005-.2A4 4 0 0 1 12 3.596z"
                    />
                </svg>
            )
        case "DOLLAR_SIGN":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56">
                    <path
                        fill={fillColor}
                        fillRule="evenodd"
                        d="M28 2.333c1.289 0 2.333.958 2.333 2.14v6.415h9.334c1.232 0 2.242.877 2.327 1.987l.006.153c0 1.181-1.045 2.139-2.333 2.139h-9.334V25.86l3.5.001c5.703 0 10.343 4.167 10.496 9.36l.004.265c0 5.316-4.7 9.625-10.5 9.625h-3.5v6.417c0 1.13-.955 2.055-2.166 2.133l-.167.006c-1.289 0-2.333-.958-2.333-2.14V45.11L14 45.111c-1.233 0-2.242-.876-2.327-1.986l-.006-.153c0-1.181 1.044-2.139 2.333-2.139h11.667V30.138h-3.5c-5.703 0-10.343-4.166-10.496-9.36l-.004-.264c0-5.316 4.7-9.625 10.5-9.625h3.5V4.471c0-1.13.955-2.055 2.166-2.133zm2.333 38.5h3.5c3.222 0 5.834-2.394 5.834-5.347 0-2.953-2.612-5.347-5.834-5.347h-3.5v10.694zM25.667 25.86V15.166h-3.5c-3.145 0-5.709 2.282-5.83 5.138l-.004.21c0 2.953 2.612 5.347 5.834 5.347h3.5z"
                    />
                </svg>
            )
        case "SENT":
            return (
                <svg
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24">
                    <path
                        fill={fillColor}
                        fillRule="evenodd"
                        d="M20.94 3.73v-.09a1 1 0 0 0-.2-.3 1 1 0 0 0-.29-.19h-.09a.86.86 0 0 0-.31-.15H20a1 1 0 0 0-.3 0l-18 6a1 1 0 0 0 0 1.9l8.53 2.84 2.84 8.53a1 1 0 0 0 1.9 0l6-18A1 1 0 0 0 21 4a1.31 1.31 0 0 0-.06-.27zM16.3 6.29l-5.57 5.57L5.16 10 16.3 6.29zM14 18.84l-1.86-5.57 5.57-5.57L14 18.84z"
                    />
                </svg>
            )
        case "VIEWED":
            return (
                <svg
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24">
                    <path
                        fill="#FF8A38"
                        fillRule="evenodd"
                        d="M11.73 5c5.98-.18 9.5 5.39 10.14 6.5a1 1 0 0 1 0 1l-.06.103c-.94 1.606-4.13 6.26-9.54 6.397h-.25c-5.89 0-9.26-5.41-9.89-6.5a1 1 0 0 1 0-1l.06-.103C3.14 9.791 6.32 5.137 11.73 5zM12 8.5a3.5 3.5 0 1 0 0 7l.192-.005A3.5 3.5 0 0 0 15.5 12l-.005-.192A3.5 3.5 0 0 0 12 8.5zm1.06 2.44a1.5 1.5 0 1 1-2.12 2.12 1.5 1.5 0 0 1 2.12-2.12z"
                    />
                </svg>
            )
        case "DONE":
            return (
                <svg
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24">
                    <path
                        fill="#18A957"
                        fillRule="evenodd"
                        d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm4.3 7.61l-4.57 6a1 1 0 0 1-1.58.01l-2.44-3.11a1.001 1.001 0 0 1 1.58-1.23l1.63 2.08 3.78-5a1.006 1.006 0 1 1 1.6 1.22v.03z"
                    />
                </svg>
            )
        case "OPTIONS":
            return (
                <svg
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24">
                    <path
                        fillRule="evenodd"
                        d="M13.414 10.586a2 2 0 1 1-2.828 2.828 2 2 0 0 1 2.828-2.828zm7 0a2 2 0 1 1-2.828 2.828 2 2 0 0 1 2.828-2.828zm-14 0a2 2 0 1 1-2.828 2.828 2 2 0 0 1 2.828-2.828z"
                    />
                </svg>
            )
        case "FILE":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33">
                    <path
                        fill={fillColor}
                        fillRule="evenodd"
                        d="M26.651 9.808l-5.944-6.69a1.34 1.34 0 0 0-.991-.442H9.005A3.387 3.387 0 0 0 5.578 6.02v20.07a3.387 3.387 0 0 0 3.427 3.346h14.567a3.387 3.387 0 0 0 3.427-3.345V10.704c0-.331-.125-.65-.348-.896zm-7.685-4.456l5.007 5.352h-4.016a1.057 1.057 0 0 1-.99-1.137V5.352z"
                    />
                </svg>
            )
        case "CALENDAR":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33">
                    <g fill="none" fillRule="evenodd">
                        <path
                            fill={fillColor}
                            d="M4.628 28.14c0 1.455 1.156 2.635 2.582 2.635h18.935c1.426 0 2.582-1.18 2.582-2.635V13.213h-24.1V28.14zm17.214-10.756c0-.362.29-.659.645-.659h2.152c.355 0 .645.297.645.659v2.195c0 .362-.29.659-.645.659h-2.152a.654.654 0 0 1-.645-.659v-2.195zm0 7.025c0-.363.29-.659.645-.659h2.152c.355 0 .645.296.645.659v2.195c0 .362-.29.658-.645.658h-2.152a.654.654 0 0 1-.645-.658v-2.195zm-6.886-7.025c0-.362.29-.659.645-.659h2.152c.355 0 .646.297.646.659v2.195c0 .362-.29.659-.646.659h-2.152a.654.654 0 0 1-.645-.659v-2.195zm0 7.025c0-.363.29-.659.645-.659h2.152c.355 0 .646.296.646.659v2.195c0 .362-.29.658-.646.658h-2.152a.654.654 0 0 1-.645-.658v-2.195zM8.07 17.384c0-.362.29-.659.646-.659h2.152c.355 0 .645.297.645.659v2.195c0 .362-.29.659-.645.659H8.716a.654.654 0 0 1-.646-.659v-2.195zm0 7.025c0-.363.29-.659.646-.659h2.152c.355 0 .645.296.645.659v2.195c0 .362-.29.658-.645.658H8.716a.654.654 0 0 1-.646-.658v-2.195zm18.075-18.22h-2.582V3.553a.872.872 0 0 0-.86-.878H20.98a.872.872 0 0 0-.86.878v2.634h-6.886V3.554a.872.872 0 0 0-.861-.878h-1.722a.872.872 0 0 0-.86.878v2.634H7.21c-1.426 0-2.582 1.18-2.582 2.635v2.634h24.1V8.823c0-1.455-1.157-2.635-2.583-2.635z"
                        />
                    </g>
                </svg>
            )
        case "SEND":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32">
                    <path
                        fill="#FF349B"
                        fillRule="evenodd"
                        d="M26.299 4.214L4.13 17c-1.138.658-1.024 2.335.188 2.835l6.937 2.87v5.046c0 1.494 1.87 2.143 2.806 1.005l3.003-3.652 6.255 2.583a1.582 1.582 0 0 0 2.167-1.222l3.167-20.637c.203-1.321-1.217-2.271-2.355-1.613zM12.839 27.75v-4.393l2.698 1.113-2.697 3.28zm11.085-1.529L13.72 22.006l9.871-11.667c.238-.277-.143-.653-.42-.415L10.538 20.69l-5.616-2.316L27.09 5.584l-3.166 20.637z"
                    />
                </svg>
            )
        case "COPY":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                    <path
                        fill="#FF349B"
                        fillRule="evenodd"
                        d="M27.247 6.273l-2.78-2.837a2.545 2.545 0 0 0-1.818-.77h-9.22c-1.42 0-2.572 1.176-2.572 2.626v2.625H6.571C5.151 7.917 4 9.092 4 10.542v17.5c0 1.45 1.151 2.625 2.571 2.625h12c1.42 0 2.572-1.176 2.572-2.625v-2.625h4.286c1.42 0 2.571-1.176 2.571-2.625V8.129c0-.696-.27-1.364-.753-1.856zm-4.39-1.83c.15.038.289.118.398.23l2.78 2.837c.11.112.187.253.224.407h-3.402V4.444zM19.43 28.043a.867.867 0 0 1-.858.875h-12a.867.867 0 0 1-.857-.875v-17.5c0-.483.385-.875.857-.875h4.286v13.125c0 1.45 1.151 2.625 2.572 2.625h6v2.625zm6.857-5.25a.867.867 0 0 1-.857.875h-12a.867.867 0 0 1-.858-.875v-17.5c0-.483.385-.875.858-.875h7.714v3.937c0 .722.578 1.313 1.286 1.313h3.857v13.125z"
                    />
                </svg>
            )

        case "ADD":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32">
                    <path
                        fill="#FF349B"
                        fillRule="evenodd"
                        d="M26.28 7.77L21.91 3.4a2.5 2.5 0 0 0-1.766-.733H9.514a2.508 2.508 0 0 0-2.5 2.504v21.663a2.5 2.5 0 0 0 2.5 2.5h15a2.5 2.5 0 0 0 2.5-2.5V9.54c0-.661-.266-1.302-.735-1.77zm-1.178 1.182c.11.11.182.24.219.385h-4.974V4.364a.82.82 0 0 1 .385.219l4.37 4.369zm-.588 18.715h-15a.836.836 0 0 1-.834-.833V5.17c0-.458.375-.833.834-.833h9.166v5.416c0 .692.558 1.25 1.25 1.25h5.417v15.83a.836.836 0 0 1-.833.833zm-2.5-9.373v.416a.627.627 0 0 1-.625.625h-3.542v3.541a.627.627 0 0 1-.625.625h-.417a.627.627 0 0 1-.625-.625v-3.54H12.64a.627.627 0 0 1-.625-.626v-.416c0-.344.28-.625.625-.625h3.541v-3.541c0-.344.282-.625.625-.625h.417c.344 0 .625.281.625.625v3.54h3.542c.343 0 .625.282.625.626z"
                    />
                </svg>
            )

        case "EDIT":
            return (
                <svg className={className} viewBox="0 0 17 17">
                    <path
                        fill="#D0D0D0"
                        d="M16.323 2.445L14.535.657c-.876-.876-2.294-.876-3.17 0L.827 11.126l-.816 4.947c-.089.539.377 1.005.916.916l4.947-.816 10.47-10.538c.874-.874.882-2.287-.021-3.19zm-9.668 7.9c.143.142.33.214.517.214s.374-.072.516-.214l3.953-3.953.948.95-5.616 5.652v-1.373H5.379v-1.594H4.006l5.653-5.616.948.948-3.952 3.953c-.285.285-.285.747 0 1.033zM2.727 15.21l-.938-.938.403-2.443.61-.607h1.382v1.593h1.593v1.381l-.607.611-2.443.403zm12.584-10.61l-.002.003-.002.001-1.595 1.605-2.922-2.922 1.605-1.595.001-.002.002-.001c.304-.304.796-.308 1.104 0l1.788 1.788c.328.328.33.815.02 1.124z"
                    />
                </svg>
            )

        case "CERTIFICATE":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56">
                    <path
                        fill={fillColor}
                        fillRule="evenodd"
                        d="M48.36 14.238l-8.922-8.932c-.703-.702-1.458-.638-2.106-.638v11.667H49c0-.687.058-1.4-.638-2.097zm-13.944 2.826V4.668H16.188a2.182 2.182 0 0 0-2.187 2.188v9.934c1.668-.456 1.784-.456 2.41-.456a5.6 5.6 0 0 1 3.968 1.668c.795.874.402.636 1.666.98a5.624 5.624 0 0 1 3.94 3.993c.284 1.203.055.789.98 1.73a5.684 5.684 0 0 1 1.43 5.41c-.341 1.207-.337.73 0 2.017a5.685 5.685 0 0 1-1.43 5.411c-.878.853-.64.445-.979 1.731a5.637 5.637 0 0 1-3.236 3.748v8.311h24.061A2.182 2.182 0 0 0 49 49.146V19.25H36.603a2.194 2.194 0 0 1-2.187-2.187zM24.885 35.5a2.749 2.749 0 0 0 .69-2.624c-.494-1.882-.495-1.617 0-3.502a2.75 2.75 0 0 0-.69-2.624c-1.354-1.378-1.224-1.15-1.72-3.033a2.693 2.693 0 0 0-1.888-1.92c-1.848-.504-1.626-.374-2.979-1.75a2.636 2.636 0 0 0-2.578-.704c-1.852.505-1.59.504-3.44 0a2.638 2.638 0 0 0-2.578.703c-1.359 1.384-1.139 1.249-2.98 1.751a2.694 2.694 0 0 0-1.887 1.92c-.497 1.89-.364 1.653-1.72 3.033a2.749 2.749 0 0 0-.69 2.624c.496 1.887.493 1.621 0 3.5a2.75 2.75 0 0 0 .69 2.625c1.354 1.377 1.224 1.15 1.72 3.032.247.937.966 1.67 1.888 1.92 1.304.356 1.05.271 1.443.456v10.426L14 48.417l5.833 2.916V40.908c.393-.184.138-.1 1.444-.456a2.694 2.694 0 0 0 1.887-1.92c.499-1.89.366-1.652 1.72-3.032zM14 36.75a5.833 5.833 0 1 1 0-11.667 5.833 5.833 0 0 1 0 11.667z"
                    />
                </svg>
            )
        case "WARNING":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25">
                    <path
                        fill="#939393"
                        fillRule="evenodd"
                        d="M10.153 3.037A3.43 3.43 0 0 1 14.89 4.08l7.67 12.72a3 3 0 0 1 .05 3.04l-.113.18a3.37 3.37 0 0 1-2.827 1.48H4.33a3.37 3.37 0 0 1-2.94-1.7l-.097-.19a3 3 0 0 1 .147-2.81L9.11 4.08a3.43 3.43 0 0 1 1.043-1.043zm2.74 1.796a1.48 1.48 0 0 0-2.073.287L3.14 17.85a1 1 0 0 0 0 1l.08.121a1.31 1.31 0 0 0 1.11.529h15.34a1.31 1.31 0 0 0 1.19-.65l.059-.12a1 1 0 0 0-.069-.89L13.18 5.12a1.48 1.48 0 0 0-.287-.287zm-.186 10.96a1 1 0 1 1-1.414 1.414 1 1 0 0 1 1.414-1.414zM12 8.5l.117.007A1 1 0 0 1 13 9.5v4a1 1 0 1 1-2 0v-4l.007-.117A1 1 0 0 1 12 8.5z"
                    />
                </svg>
            )
        case "SMALL_PROFILE":
            const style = {
                width: "22px",
                height: "22px",
                backgroundColor: "var(--black-200)",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }
            return (
                <div style={style}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13">
                        <path
                            fill="#FFF"
                            fillRule="evenodd"
                            d="M6 7a3.5 3.5 0 0 1 3.5 3.5l-.008.09A.5.5 0 0 1 9 11H3a.5.5 0 0 1-.5-.5l.005-.192A3.5 3.5 0 0 1 6 7zm0-5a2 2 0 1 1 0 4l-.15-.005A2 2 0 0 1 4 4l.005-.15A2 2 0 0 1 6 2z"
                        />
                    </svg>
                </div>
            )
        case "MORE_HORZ":
            return (
                <svg
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24">
                    <g fill="none" fillRule="evenodd">
                        <path
                            fill="#666"
                            d="M13.414 10.586c.781.78.781 2.047 0 2.828-.78.781-2.047.781-2.828 0-.781-.78-.781-2.047 0-2.828.78-.781 2.047-.781 2.828 0zm7 0c.781.78.781 2.047 0 2.828-.78.781-2.047.781-2.828 0-.781-.78-.781-2.047 0-2.828.78-.781 2.047-.781 2.828 0zm-14 0c.781.78.781 2.047 0 2.828-.78.781-2.047.781-2.828 0-.781-.78-.781-2.047 0-2.828.78-.781 2.047-.781 2.828 0z"
                        />
                    </g>
                </svg>
            )
        case "ARROW_RIGHT":
            return (
                <svg className={className} version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512.005 512.005">
                    <g>
                        <g>
                            <path
                                fill={fillColor}
                                d="M388.418,240.923L153.751,6.256c-8.341-8.341-21.824-8.341-30.165,0s-8.341,21.824,0,30.165L343.17,256.005
                       L123.586,475.589c-8.341,8.341-8.341,21.824,0,30.165c4.16,4.16,9.621,6.251,15.083,6.251c5.461,0,10.923-2.091,15.083-6.251
                       l234.667-234.667C396.759,262.747,396.759,249.264,388.418,240.923z"
                            />
                        </g>
                    </g>
                </svg>
            )
        case "ARROW_DOWN":
            return (
                <svg className={className} version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512.005 512.005">
                    <g>
                        <g>
                            <path
                                fill={fillColor}
                                d="M505.755,123.592c-8.341-8.341-21.824-8.341-30.165,0L256.005,343.176L36.421,123.592c-8.341-8.341-21.824-8.341-30.165,0
			s-8.341,21.824,0,30.165l234.667,234.667c4.16,4.16,9.621,6.251,15.083,6.251c5.462,0,10.923-2.091,15.083-6.251l234.667-234.667
			C514.096,145.416,514.096,131.933,505.755,123.592z"
                            />
                        </g>
                    </g>
                </svg>
            )
        case "CHECKED":
            return (
                <svg
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24">
                    <g fill="none" fillRule="evenodd">
                        <path
                            fill="#18A957"
                            d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm4.3 7.61l-4.57 6c-.189.245-.48.39-.79.39-.308.002-.6-.139-.79-.38l-2.44-3.11c-.34-.436-.261-1.065.175-1.405.436-.34 1.065-.261 1.405.175l1.63 2.08 3.78-5c.337-.442.968-.527 1.41-.19.442.337.527.968.19 1.41v.03z"
                        />
                    </g>
                </svg>
            )
        case "LIBRARY":
            return (
                <svg className={className} viewBox="0 0 24 24" aria-hidden="true">
                    <g fill="none">
                        <path
                            fill={fillColor}
                            d="M19.92 4.11c.376-.097.776-.012 1.08.23l.11.093c.243.23.385.549.39.887V17c.004.574-.384 1.078-.94 1.22L13 20.16V5.89zm-16.803.15c.281-.17.62-.225.943-.15L11 5.89v14.27l-7.54-1.95c-.553-.141-.94-.64-.94-1.21V5.34c-.005-.39.173-.76.48-1z"
                            transform="translate(.333)"></path>
                    </g>
                </svg>
            )
        case "PACKAGE":
            return (
                <svg
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24">
                    <path
                        fill={fillColor}
                        fillRule="evenodd"
                        d="M23.93 6.857c.022-.027.027-.037.049-.064l-3.017-5.62C20.727.471 20.073 0 19.334 0h-5.62l1.715 6.857h8.501zm-15.359 0L10.286 0h-5.62c-.74 0-1.393.471-1.629 1.173L.022 6.793c.022.027.027.037.049.064H8.57zm6.858 1.714v4.286c0 .472-.386.857-.858.857H9.43c-.472 0-.858-.385-.858-.857V8.571H0v13.715C0 23.234.766 24 1.714 24h20.572c.948 0 1.714-.766 1.714-1.714V8.57h-8.571z"
                    />
                </svg>
            )
        case "PAPER_PLANE":
            return (
                <svg
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24">
                    <g fill="none" fillRule="evenodd">
                        <path
                            fill={fillColor}
                            d="M20.94 3.73v-.09c-.048-.111-.116-.213-.2-.3-.085-.08-.183-.144-.29-.19h-.09c-.092-.07-.198-.121-.31-.15H20c-.1-.015-.2-.015-.3 0l-18 6c-.525.172-.81.738-.638 1.262.1.302.336.539.638.638l8.53 2.84 2.84 8.53c.172.525.738.81 1.262.638.302-.1.539-.336.638-.638l6-18c.022-.088.032-.18.03-.27-.01-.092-.03-.182-.06-.27zM16.3 6.29l-5.57 5.57L5.16 10 16.3 6.29zM14 18.84l-1.86-5.57 5.57-5.57L14 18.84z"
                        />
                    </g>
                </svg>
            )
        case "WARNING_TRIANGLE":
            return (
                <svg
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24">
                    <path
                        fill="#DF1642"
                        fillRule="evenodd"
                        d="M12 16.727c.602 0 1.09.489 1.09 1.091 0 .603-.488 1.091-1.09 1.091-.602 0-1.09-.488-1.09-1.09 0-.603.488-1.092 1.09-1.092zM12 8c.602 0 1.09.488 1.09 1.09v4.365c0 .602-.488 1.09-1.09 1.09-.602 0-1.09-.488-1.09-1.09V9.09C10.91 8.488 11.397 8 12 8zm11.52 9.329L15.149 3.21c-.688-1.094-1.877-1.756-3.154-1.756-1.278 0-2.467.662-3.155 1.756L.467 17.33c-.602 1.02-.623 2.29-.055 3.33.657 1.17 1.883 1.891 3.21 1.886h16.744c1.317.015 2.541-.688 3.21-1.842.585-1.052.564-2.343-.055-3.374zm-1.855 2.275c-.267.467-.768.745-1.299.721H3.621c-.531.024-1.031-.254-1.299-.721-.195-.343-.195-.766 0-1.11l8.384-14.129c.305-.41.782-.65 1.288-.65s.982.24 1.288.65l8.372 14.118c.203.344.207.773.011 1.121z"
                    />
                </svg>
            )
        case "PEOPLE":
            return (
                <svg className={className} viewBox="0 0 24 24" aria-hidden="true">
                    <path
                        fill={fillColor}
                        d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z"></path>
                </svg>
            )
        case "BAR_CHART":
            return (
                <svg className={className} viewBox="0 0 24 24" aria-hidden="true">
                    <path fill={fillColor} d="M5 9.2h3V19H5zM10.6 5h2.8v14h-2.8zm5.6 8H19v6h-2.8z"></path>
                </svg>
            )
        case "PERSON":
            return (
                <svg className={className} viewBox="0 0 24 24" aria-hidden="true">
                    <path
                        fill={fillColor}
                        d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path>
                </svg>
            )
        case "SETTINGS":
            return (
                <svg className={className} viewBox="0 0 24 24" aria-hidden="true">
                    <path
                        fill={fillColor}
                        d="M15.95 10.78c.03-.25.05-.51.05-.78s-.02-.53-.06-.78l1.69-1.32c.15-.12.19-.34.1-.51l-1.6-2.77c-.1-.18-.31-.24-.49-.18l-1.99.8c-.42-.32-.86-.58-1.35-.78L12 2.34c-.03-.2-.2-.34-.4-.34H8.4c-.2 0-.36.14-.39.34l-.3 2.12c-.49.2-.94.47-1.35.78l-1.99-.8c-.18-.07-.39 0-.49.18l-1.6 2.77c-.1.18-.06.39.1.51l1.69 1.32c-.04.25-.07.52-.07.78s.02.53.06.78L2.37 12.1c-.15.12-.19.34-.1.51l1.6 2.77c.1.18.31.24.49.18l1.99-.8c.42.32.86.58 1.35.78l.3 2.12c.04.2.2.34.4.34h3.2c.2 0 .37-.14.39-.34l.3-2.12c.49-.2.94-.47 1.35-.78l1.99.8c.18.07.39 0 .49-.18l1.6-2.77c.1-.18.06-.39-.1-.51l-1.67-1.32zM10 13c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3z"></path>
                </svg>
            )
        case "CLOSE":
            return (
                <svg className={className} viewBox="0 0 24 24" aria-hidden="true">
                    <path
                        fill={fillColor}
                        d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                </svg>
            )
        case "PLUS":
            return (
                <svg className={className} viewBox="0 0 24 24">
                    <g fill="none">
                        <path
                            fill={fillColor}
                            d="M19 11h-6V5c0-.552-.448-1-1-1s-1 .448-1 1v6H5c-.552 0-1 .448-1 1s.448 1 1 1h6v6c0 .552.448 1 1 1s1-.448 1-1v-6h6c.552 0 1-.448 1-1s-.448-1-1-1z"
                        />
                    </g>
                </svg>
            )
        case "DELETE":
            return (
                <svg className={className} viewBox="0 0 24 24">
                    <g fill="none">
                        <path
                            fill={fillColor}
                            d="M21 6h-5V4.33c-.049-1.333-1.167-2.375-2.5-2.33h-3c-1.333-.045-2.451.997-2.5 2.33V6H3c-.552 0-1 .448-1 1s.448 1 1 1h1v11c0 1.657 1.343 3 3 3h10c1.657 0 3-1.343 3-3V8h1c.552 0 1-.448 1-1s-.448-1-1-1zM10 16c0 .552-.448 1-1 1s-1-.448-1-1v-4c0-.552.448-1 1-1s1 .448 1 1v4zm0-11.67c0-.16.21-.33.5-.33h3c.29 0 .5.17.5.33V6h-4V4.33zM16 16c0 .552-.448 1-1 1s-1-.448-1-1v-4c0-.552.448-1 1-1s1 .448 1 1v4z"
                        />
                    </g>
                </svg>
            )
        case "UNORDER_LIST":
            return (
                <svg className={className} viewBox="0 0 33 33">
                    <path
                        fill="#FFF"
                        d="M3 22.5c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5zm29.06 0c.52 0 .94.42.94.94v1.12c0 .52-.42.94-.94.94H9.94c-.52 0-.94-.42-.94-.94v-1.12c0-.52.42-.94.94-.94h22.12zM3 13.5c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5zm29.06 0c.52 0 .94.42.94.94v1.12c0 .52-.42.94-.94.94H9.94c-.52 0-.94-.42-.94-.94v-1.12c0-.52.42-.94.94-.94h22.12zM3 4.5c.828 0 1.5.672 1.5 1.5S3.828 7.5 3 7.5 1.5 6.828 1.5 6 2.172 4.5 3 4.5zm29.06 0c.52 0 .94.42.94.94v1.12c0 .52-.42.94-.94.94H9.94c-.52 0-.94-.42-.94-.94V5.44c0-.52.42-.94.94-.94h22.12z"
                    />
                </svg>
            )
        case "SCAN_TASK":
            return (
                <svg className={className} viewBox="0 0 24 24">
                    <g fill="none">
                        <path
                            fill={fillColor}
                            d="M13.5 3C14.88 3 16 4.12 16 5.5V7h3c1.657 0 3 1.343 3 3v8c0 1.657-1.343 3-3 3H5c-1.657 0-3-1.343-3-3v-8l.005-.176C2.096 8.249 3.402 7 5 7h3V5.5l.005-.164C8.09 4.032 9.175 3 10.5 3zM12 10.5c-1.933 0-3.5 1.567-3.5 3.5s1.567 3.5 3.5 3.5l.192-.005c1.844-.1 3.308-1.626 3.308-3.495l-.005-.192c-.1-1.844-1.626-3.308-3.495-3.308zm1.06 2.44c.586.585.586 1.535 0 2.12-.585.586-1.535.586-2.12 0-.586-.585-.586-1.535 0-2.12.585-.586 1.535-.586 2.12 0zM13.5 5h-3c-.276 0-.5.224-.5.5V7h4V5.5c0-.276-.224-.5-.5-.5z"
                        />
                    </g>
                </svg>
            )
        case "DIGITAL_DOCUMENT_TASK":
            return (
                <svg className={className} viewBox="0 0 25 24">
                    <path
                        fill={fillColor}
                        d="M14.115 6.375V0H4.74c-.624 0-1.125.502-1.125 1.125v21.75c0 .623.501 1.125 1.125 1.125h15.75c.623 0 1.125-.502 1.125-1.125V7.5H15.24c-.62 0-1.125-.506-1.125-1.125zm-7.5-3c0-.207.167-.375.375-.375h3.75c.207 0 .375.168.375.375v.75c0 .207-.168.375-.375.375H6.99c-.208 0-.375-.168-.375-.375v-.75zm0 3c0-.207.167-.375.375-.375h3.75c.207 0 .375.168.375.375v.75c0 .207-.168.375-.375.375H6.99c-.208 0-.375-.168-.375-.375v-.75zm0 5c0-.207.298-.375.666-.375h6.667c.368 0 .667.168.667.375v.75c0 .207-.299.375-.667.375H7.281c-.368 0-.666-.168-.666-.375v-.75zm5 3c0-.207.298-.375.666-.375h6.667c.368 0 .667.168.667.375v.75c0 .207-.299.375-.667.375h-6.667c-.368 0-.666-.168-.666-.375v-.75zm-5 0c0-.207.149-.375.333-.375h3.333c.185 0 .334.168.334.375v.75c0 .207-.15.375-.334.375H6.948c-.184 0-.333-.168-.333-.375v-.75zm9-3c0-.207.149-.375.333-.375h3.333c.185 0 .334.168.334.375v.75c0 .207-.15.375-.334.375h-3.333c-.184 0-.333-.168-.333-.375v-.75zm1.778 9.469h1.66c.31 0 .562.259.562.578 0 .32-.252.578-.563.578h-1.659c-.578 0-1.1-.33-1.358-.862-.104-.214-.285-.236-.358-.236-.073 0-.254.022-.352.224l-.27.554c-.095.196-.29.32-.503.32l-.04-.002c-.227-.016-.422-.172-.493-.394l-.592-1.823-.373 1.152c-.207.638-.787 1.067-1.441 1.067h-.436c-.31 0-.562-.259-.562-.578 0-.32.251-.578.562-.578h.436c.17 0 .32-.111.374-.277l.64-1.974c.115-.355.437-.593.8-.593.364 0 .685.238.8.593l.489 1.504c.695-.585 1.9-.35 2.32.512.071.147.21.235.357.235zm3.894-15.922L16.697.328c-.21-.21-.496-.328-.796-.328h-.286v6h6v-.286c0-.295-.118-.581-.328-.792z"
                    />
                </svg>
            )
        case "EYE":
            return (
                <svg className={className} viewBox="0 0 26 25">
                    <g fill="none">
                        <path
                            fill={fillColor}
                            d="M11.879 5c6.056-.18 9.62 5.39 10.269 6.5.18.31.18.69 0 1l-.059.1c-.951 1.605-4.182 6.263-9.663 6.4h-.253c-5.965 0-9.378-5.41-10.016-6.5-.18-.31-.18-.69 0-1l.06-.1c.96-1.605 4.18-6.263 9.662-6.4zm.424 1.999L11.98 7c-4.05.1-6.694 3.39-7.706 5 .89 1.41 3.737 5.1 8.101 5 4.051-.1 6.664-3.39 7.707-5-.901-1.41-3.757-5.11-8.102-5zm-.15 1.501l.194.005c1.867.1 3.35 1.626 3.35 3.495l-.005.192c-.101 1.844-1.647 3.308-3.54 3.308-1.957 0-3.544-1.567-3.544-3.5s1.587-3.5 3.544-3.5zm0 2c-.84 0-1.52.672-1.52 1.5s.68 1.5 1.52 1.5l.138-.006c.774-.07 1.38-.712 1.38-1.494l-.006-.137c-.07-.764-.72-1.363-1.513-1.363z"
                            transform="translate(.91 .5)"
                        />
                    </g>
                </svg>
            )
        case "BACK":
            return (
                <svg className={className} viewBox="0 0 40 40">
                    <g fill="none">
                        <path
                            fill="#FF349B"
                            d="M20 0C8.954 0 0 8.954 0 20s8.954 20 20 20 20-8.954 20-20S31.046 0 20 0zm-3.72 27.686l-5.72-6.246c-.084-.09-.152-.196-.2-.312-.084-.098-.152-.211-.2-.333-.213-.504-.213-1.079 0-1.583.096-.255.238-.489.42-.687l6-6.246c.785-.816 2.056-.816 2.84 0 .784.816.784 2.14 0 2.956l-2.6 2.686H28c1.105 0 2 .932 2 2.082s-.895 2.082-2 2.082H16.68l2.5 2.728c.762.834.731 2.153-.07 2.946-.8.793-2.068.76-2.83-.073z"
                        />
                    </g>
                </svg>
            )
        case "CHECK_LIST":
            return (
                <svg className={className} viewBox="0 0 25 24">
                    <g fill="none">
                        <path
                            fill={fillColor}
                            d="M9.759 5.716h13.447c.411 0 .747-.336.747-.747V3.1c0-.41-.336-.747-.747-.747H9.76c-.411 0-.747.336-.747.747V4.97c0 .41.336.747.747.747zm0 7.47h13.447c.411 0 .747-.335.747-.746v-1.868c0-.41-.336-.747-.747-.747H9.76c-.411 0-.747.336-.747.747v1.868c0 .41.336.747.747.747zm0 7.472h13.447c.411 0 .747-.337.747-.747v-1.868c0-.411-.336-.747-.747-.747H9.76c-.411 0-.747.336-.747.747v1.868c0 .41.336.747.747.747zm-6.724-3.923c-1.237 0-2.27 1.004-2.27 2.242 0 1.237 1.033 2.24 2.27 2.24s2.241-1.003 2.241-2.24c0-1.238-1.004-2.242-2.241-2.242zm4.32-13.96l-3.372 3.37-.729.729c-.219.22-.602.22-.821 0L.21 4.66c-.22-.22-.22-.575 0-.794l.733-.733c.22-.22.575-.22.794 0l1.06 1.032L5.77 1.21c.22-.22.575-.22.794 0l.794.77c.215.22.215.575-.005.794zm0 7.451l-3.372 3.372-.733.733c-.22.22-.603.22-.822 0L.21 12.113c-.22-.22-.22-.574 0-.794l.733-.733c.22-.22.575-.22.794 0l1.06 1.032L5.77 8.644c.22-.22.575-.22.794 0l.794.793c.215.215.215.57-.005.79z"
                            transform="translate(.222)"
                        />
                    </g>
                </svg>
            )
        case "STUDENT_ICON":
            return (
                <svg className={className} viewBox="0 0 74 74">
                    <path
                        fill={fillColor}
                        d="M49.157 46.39L35.395 60.153 21.634 46.391c-10.314.447-18.55 8.885-18.55 19.3v1.385c0 3.823 3.1 6.924 6.923 6.924h50.776c3.823 0 6.924-3.101 6.924-6.924v-1.385c0-10.415-8.237-18.853-18.55-19.3zM5.045 11.656l.923.217v8.424c-1.01.606-1.73 1.659-1.73 2.928 0 1.212.663 2.222 1.6 2.842l-2.25 8.987c-.245.995.303 2.02 1.097 2.02h6.03c.793 0 1.34-1.025 1.095-2.02l-2.25-8.987c.938-.62 1.601-1.63 1.601-2.842 0-1.27-.72-2.322-1.73-2.928v-7.588l9.52 2.294c-1.24 2.481-2.02 5.25-2.02 8.222 0 10.199 8.266 18.464 18.464 18.464 10.199 0 18.464-8.265 18.464-18.464 0-2.971-.764-5.741-2.02-8.222l13.892-3.347c2.625-.634 2.625-3.909 0-4.543L38.266.476c-1.875-.447-3.852-.447-5.727 0L5.045 7.097c-2.61.635-2.61 3.924 0 4.558z"
                    />
                </svg>
            )
        case "STAFF_ICON":
            return (
                <svg className={className} viewBox="0 0 74 74">
                    <path
                        fill={fillColor}
                        d="M0 32.067v31.45c0 1.022.828 1.85 1.85 1.85h9.25v-37H3.7c-2.043 0-3.7 1.657-3.7 3.7zm41.625-5.55H38.85v-4.625c0-.511-.414-.925-.925-.925h-1.85c-.511 0-.925.414-.925.925v7.4c0 .51.414.925.925.925h5.55c.511 0 .925-.414.925-.925v-1.85c0-.511-.414-.925-.925-.925zm15.927-7.396l-18.5-12.333c-1.243-.828-2.861-.828-4.104 0l-18.5 12.333c-1.03.686-1.648 1.841-1.648 3.078v43.168h14.8v-16.65c0-1.022.828-1.85 1.85-1.85h11.1c1.022 0 1.85.828 1.85 1.85v16.65h14.8V22.2c0-1.237-.619-2.393-1.648-3.079zM37 35.767c-5.108 0-9.25-4.142-9.25-9.25 0-5.109 4.142-9.25 9.25-9.25s9.25 4.141 9.25 9.25c0 5.108-4.142 9.25-9.25 9.25zm33.3-7.4h-7.4v37h9.25c1.022 0 1.85-.828 1.85-1.85v-31.45c0-2.043-1.657-3.7-3.7-3.7z"
                    />
                </svg>
            )
        case "NOTIFY":
            return (
                <svg className={className} viewBox="0 0 24 24">
                    <g fill="none">
                        <path
                            fill={fillColor}
                            d="M20.52 15.21l-1.8-1.81V8.94c.05-3.425-2.435-6.361-5.82-6.88-3.69-.486-7.076 2.112-7.562 5.802-.038.288-.057.578-.058.868v4.67l-1.8 1.81c-.635.646-.627 1.684.02 2.32.304.299.713.468 1.14.47H8v.34c.097 2.114 1.886 3.75 4 3.66 2.114.09 3.903-1.546 4-3.66V18h3.36c.906-.005 1.636-.743 1.63-1.65-.002-.426-.17-.836-.47-1.14zM14 18.34c-.115 1-.997 1.73-2 1.66-1.003.07-1.885-.66-2-1.66V18h4v.34z"
                        />
                    </g>
                </svg>
            )
        case "DUPLICATE":
            return (
                <svg className={className} viewBox="0 0 24 24">
                    <g fill="none">
                        <path
                            fill={fillColor}
                            d="M18 9h-3V5.67c-.005-1.472-1.198-2.665-2.67-2.67H5.67C4.198 3.005 3.005 4.198 3 5.67v6.66c.005 1.472 1.198 2.664 2.67 2.67H9v3c0 1.657 1.343 3 3 3h6c1.657 0 3-1.343 3-3v-6c0-1.657-1.343-3-3-3zm-9 3v1H5.67c-.37 0-.67-.3-.67-.67V5.67c0-.37.3-.67.67-.67h6.66c.37 0 .67.3.67.67V9h-1c-1.657 0-3 1.343-3 3z"
                        />
                    </g>
                </svg>
            )
        case "HAND":
            return (
                <svg className={className} viewBox="0 0 75 74">
                    <path
                        fill="#FF71B9"
                        d="M62.665 18.501c-3.241-.062-5.894 2.653-5.894 5.894V37h-1.156V11.532c0-3.242-2.653-5.956-5.895-5.894-3.14.06-5.668 2.625-5.668 5.78V37h-1.156V5.895c0-3.241-2.653-5.956-5.894-5.894-3.141.06-5.669 2.625-5.669 5.78V37h-1.156V11.677c0-3.242-2.653-5.957-5.894-5.895-3.14.06-5.668 2.625-5.668 5.78v34.109l-4.575-6.29c-1.877-2.582-5.493-3.153-8.076-1.275-2.582 1.878-3.152 5.493-1.275 8.076l18.154 24.96c1.305 1.796 3.39 2.858 5.61 2.858h28.558c3.22 0 6.016-2.215 6.753-5.349l3.832-16.282c.49-2.083.737-4.216.737-6.356V24.28c0-3.155-2.527-5.72-5.668-5.78z"
                    />
                </svg>
            )
        case "QUESTION":
            return (
                <svg className={className} viewBox="0 0 39 39">
                    <g fill="none">
                        <path
                            fill={fillColor}
                            d="M19.5 3.25c-8.975 0-16.25 7.275-16.25 16.25s7.275 16.25 16.25 16.25 16.25-7.275 16.25-16.25S28.475 3.25 19.5 3.25zm0 26c-.897 0-1.625-.728-1.625-1.625S18.603 26 19.5 26s1.625.728 1.625 1.625-.728 1.625-1.625 1.625zm1.625-8.385v1.885c0 .897-.728 1.625-1.625 1.625s-1.625-.728-1.625-1.625V19.5c0-.897.728-1.625 1.625-1.625 1.346 0 2.437-1.091 2.437-2.437 0-1.347-1.09-2.438-2.437-2.438-1.346 0-2.438 1.091-2.438 2.438 0 .897-.727 1.625-1.625 1.625-.897 0-1.625-.728-1.625-1.625-.012-3.142 2.524-5.698 5.665-5.71 3.141-.013 5.698 2.523 5.71 5.664.01 2.523-1.644 4.752-4.062 5.473z"
                        />
                    </g>
                </svg>
            )
        case "SEARCH":
            return (
                <svg className={className} viewBox="0 0 24 24">
                    <g fill="none">
                        <path
                            fill={fillColor}
                            d="M20.71 19.29l-3.4-3.39c1.097-1.398 1.692-3.123 1.69-4.9 0-4.418-3.582-8-8-8s-8 3.582-8 8 3.582 8 8 8c1.777.002 3.502-.593 4.9-1.69l3.39 3.4c.389.392 1.022.395 1.414.006l.006-.006c.392-.389.395-1.022.006-1.414l-.006-.006zM5 11c0-3.314 2.686-6 6-6s6 2.686 6 6-2.686 6-6 6-6-2.686-6-6z"
                        />
                    </g>
                </svg>
            )
        case "MEN_WOMEN":
            return (
                <svg className={className} viewBox="0 0 76 74">
                    <g fill="none">
                        <path
                            fill={fillColor}
                            d="M17.173 3.083c5.173 0 9.367 3.797 9.367 8.48 0 4.682-4.194 8.479-9.367 8.479-5.173 0-9.367-3.797-9.367-8.48 0-4.682 4.194-8.479 9.367-8.479m7.025 19.078h-1.662c-3.324 1.384-7.258 1.444-10.726 0h-1.662c-3.88 0-7.026 2.848-7.026 6.36v18.018c0 1.756 1.573 3.18 3.513 3.18h2.342v18.018c0 1.756 1.572 3.18 3.512 3.18h9.368c1.94 0 3.512-1.424 3.512-3.18V49.719h2.342c1.94 0 3.513-1.424 3.513-3.18V28.521c0-3.512-3.146-6.36-7.026-6.36zM56.202 3.083c4.599 0 8.326 3.797 8.326 8.48 0 4.682-3.727 8.479-8.326 8.479-4.598 0-8.325-3.797-8.325-8.48 0-4.682 3.727-8.479 8.325-8.479M71.72 50.007L65.475 24.57c-.347-1.416-1.596-2.409-3.028-2.409h-1.478c-2.955 1.384-6.451 1.444-9.533 0h-1.478c-1.432 0-2.681.993-3.029 2.409l-6.244 25.437c-.492 2.004.998 3.951 3.03 3.951h7.284v13.779c0 1.756 1.398 3.18 3.122 3.18h4.163c1.724 0 3.122-1.424 3.122-3.18V53.958h7.285c2.028 0 3.521-1.943 3.029-3.95z"
                            transform="translate(.278)"
                        />
                    </g>
                </svg>
            )
        case "CLOUD_UPLOAD":
            return (
                <svg className={className} viewBox="0 0 24 24">
                    <path
                        fill={fillColor}
                        d="M20.16 11.297c.154-.4.24-.84.24-1.297 0-1.988-1.612-3.6-3.6-3.6-.739 0-1.429.225-1.999.607C13.762 5.208 11.824 4 9.6 4c-3.315 0-6 2.685-6 6 0 .101.004.203.007.304C1.507 11.043 0 13.045 0 15.4c0 2.981 2.419 5.4 5.4 5.4h13.8c2.651 0 4.8-2.149 4.8-4.8 0-2.321-1.65-4.26-3.84-4.703zM14.752 13.6H12.3v4.2c0 .33-.27.6-.6.6H9.9c-.33 0-.6-.27-.6-.6v-4.2H6.847c-.536 0-.802-.645-.423-1.024l3.952-3.952c.233-.233.615-.233.848 0l3.952 3.952c.379.379.109 1.024-.424 1.024z"
                    />
                </svg>
            )
        case "ARROW_LEFT_CIRCLE":
            return (
                <svg
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24">
                    <g fill="none" fillRule="evenodd">
                        <path
                            fill={fillColor}
                            d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zM9.768 16.612l-3.432-3.748c-.05-.054-.091-.118-.12-.187-.05-.06-.091-.127-.12-.2-.128-.303-.128-.647 0-.95.057-.153.143-.293.252-.412l3.6-3.748c.47-.49 1.234-.49 1.704 0s.47 1.284 0 1.774l-1.56 1.612H16.8c.663 0 1.2.56 1.2 1.249 0 .69-.537 1.25-1.2 1.25h-6.792l1.5 1.636c.457.5.439 1.291-.042 1.767-.48.476-1.24.457-1.698-.043z"
                        />
                    </g>
                </svg>
            )

        case "ARROW_RIGHT_CIRCLE":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <g fill="none" fillRule="evenodd">
                        <path
                            fill={fillColor}
                            d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zM9.768 16.612l-3.432-3.748c-.05-.054-.091-.118-.12-.187-.05-.06-.091-.127-.12-.2-.128-.303-.128-.647 0-.95.057-.153.143-.293.252-.412l3.6-3.748c.47-.49 1.234-.49 1.704 0s.47 1.284 0 1.774l-1.56 1.612H16.8c.663 0 1.2.56 1.2 1.249 0 .69-.537 1.25-1.2 1.25h-6.792l1.5 1.636c.457.5.439 1.291-.042 1.767-.48.476-1.24.457-1.698-.043z"
                            transform="rotate(180 12 12)"
                        />
                    </g>
                </svg>
            )
        case "INFO":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path
                        fill={fillColor}
                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"
                    />
                </svg>
            )
        case "FILE_EMPTY":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="127" height="127" viewBox="0 0 127 127">
                    <g fill="none">
                        <path
                            fill={fillColor}
                            d="M104.457 38.788L80.963 12.33c-1-1.109-2.423-1.743-3.916-1.748H34.713c-7.393-.087-13.458 5.835-13.546 13.229v79.376c.087 7.394 6.151 13.317 13.544 13.23h57.576c7.393.088 13.458-5.834 13.546-13.228V42.333c-.003-1.311-.493-2.575-1.376-3.545zM74.084 79.375H52.917c-2.923 0-5.292-2.37-5.292-5.292 0-2.922 2.37-5.291 5.292-5.291h21.166c2.923 0 5.292 2.369 5.292 5.291 0 2.923-2.37 5.292-5.292 5.292zm3.757-37.042c-2.27-.223-3.94-2.224-3.757-4.498V21.167l19.791 21.166H77.84z"
                        />
                    </g>
                </svg>
            )
        default:
            return null
    }
}
