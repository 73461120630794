import React from "react"
import {Row, Col, Checkbox} from "antd"
import moment from "moment"
import Model from "Model"
import {observer} from "mobx-react"
import {BaseInput} from "components/Input"
import {BaseDatePicker} from "components/DatePicker"
import {KlassDropdown} from "components/KlassDropdown"
import {BaseAsyncSelect} from "components/Select"
import {BaseError} from "components/Common"
import {REMINDERS} from "constant"

import styles from "../ConfigureTask.module.css"

interface Props {
    data: any
    model: Model
    errors: any
    onChangeTaskData: Function
    library: boolean
}

interface State {}

@observer
class TaskHeaderSettingBody extends React.Component<Props, State> {
    render() {
        const {onChangeTaskData, data, errors, model, library} = this.props
        const {category, dueDate, name, description, reminderOption, cc, autoApprove} = data
        const {documentCategories, staffUsers, dateFormat} = model

        return (
            <div>
                <Row className={styles.rowItem}>
                    <Col span={8}>
                        <div className={styles.itemBodyWrap}>
                            <p className={styles.bodyTitle}>
                                Title <span className="required-star">*</span>
                            </p>
                            <BaseInput
                                value={name}
                                onChangeValue={(newValue) => onChangeTaskData("name", newValue)}
                                placeholder="Title"
                                className={errors && errors.name ? "error" : ""}
                            />
                            {errors && errors.name && (
                                <BaseError className={styles.error} error="This field is required" />
                            )}
                        </div>
                    </Col>
                    <Col span={8}>
                        {!library && (
                            <div className={styles.itemBodyWrap}>
                                <p className={styles.bodyTitle}>
                                    Due Date<span className="required-star">*</span>
                                </p>
                                <BaseDatePicker
                                    value={dueDate}
                                    onChange={(newValue) => onChangeTaskData("dueDate", newValue)}
                                    format={dateFormat}
                                    disabledDate={(current) => moment(current).isBefore(moment().subtract(1, "d"))}
                                    className={errors && errors.dueDate ? "error" : ""}
                                />
                                {errors && errors.dueDate && (
                                    <BaseError className={styles.error} error="This field is required" />
                                )}
                            </div>
                        )}
                    </Col>
                    <Col span={8}>
                        <div className={styles.itemBodyWrap}>
                            <p className={styles.bodyTitle}>Reminder options</p>
                            <KlassDropdown
                                onChange={(selectedOption) => onChangeTaskData("reminderOption", selectedOption)}
                                options={REMINDERS}
                                value={reminderOption}
                                placeholder="Select..."
                                isMulti
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <div className={styles.itemBodyWrap}>
                            <p className={styles.bodyTitle}>
                                Description <span className="required-star">*</span>
                            </p>
                            <BaseInput
                                value={description}
                                onChangeValue={(newValue) => onChangeTaskData("description", newValue)}
                                placeholder="Basic usage"
                                className={errors && errors.description ? "error" : ""}
                            />
                            {errors && errors.description && (
                                <BaseError className={styles.error} error="This field is required" />
                            )}
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className={styles.itemBodyWrap}>
                            <p className={styles.bodyTitle}>
                                Document category
                                <span className="required-star">*</span>
                            </p>
                            <KlassDropdown
                                onChange={(selectedOption) => onChangeTaskData("category", selectedOption)}
                                options={documentCategories}
                                value={category}
                                valueKey="item_value"
                                labelKey="item_label"
                                placeholder="Select..."
                            />
                            {errors && errors.category && (
                                <BaseError className={styles.error} error="This field is required" />
                            )}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <div className={styles.itemBodyWrap}>
                            <p className={styles.bodyTitle}>Additional Approval Access</p>
                            <KlassDropdown
                                onChange={(selectedOption) => onChangeTaskData("cc", selectedOption)}
                                options={staffUsers}
                                value={cc}
                                getOptionLabel={(option: any) => `${option.first_name} ${option.last_name}`}
                                placeholder="Select..."
                                isMulti
                            />
                        </div>
                    </Col>
                    <Col span={16}>
                        <div className={styles.checkBoxAutoApprove}>
                            <Checkbox
                                onChange={({target: {checked}}) => onChangeTaskData("autoApprove", checked ? 1 : 0)}
                                checked={autoApprove}>
                                Auto Approve (Task will be automatically mark as "Approved" once all signers are done)
                            </Checkbox>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default TaskHeaderSettingBody
