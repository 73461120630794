import React from "react"
import {Modal} from "antd"
import classNames from "classnames"
import {Icon} from "components/Icons"
import styles from "./popup.module.css"

interface Props {
    isShow: boolean
    onClose: () => void
}

export function BasePopup(props) {
    const {isShow, onClose, children, className, leftIcon, leftIconColor, ...rest} = props

    return (
        <Modal
            visible={isShow}
            onCancel={onClose}
            width="90vw"
            closeIcon={<Icon icon="CLOSE" className={styles.closeIcon} />}
            maskClosable={false}
            footer={null}
            className={classNames("base-popup", className || "")}
            {...rest}>
            <div className={styles.dialogWrap}>
                <div className={styles.leftSide}>
                    <Icon
                        icon={leftIcon ? leftIcon : "UNORDER_LIST"}
                        className={styles.leftSideIcon}
                        color={leftIconColor}
                    />
                </div>
                <div className={styles.dialogContent}>{children}</div>
            </div>
        </Modal>
    )
}
