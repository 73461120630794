import React from "react"
import {createBrowserHistory} from "history"
import {Router, Route, Switch} from "react-router-dom"
import Users from "Modules/Users/Users"
import {Tasks, TaskDetails} from "Modules/Tasks"
import {GoKlassTasks, GoKlassTaskDetail} from "Modules/TasksV2"
import {BaseLoading} from "components/Loading"
import Model from "./Model"
import * as queryString from "query-string"

const model = new Model()
const history = createBrowserHistory()

export default class App extends React.Component<any, any> {
    constructor(props) {
        super(props)
        this.state = {
            loaded: false
        }
    }
    async componentDidMount() {
        model.loadDateFormat()
        const queryParams: any = queryString.parse(window.location.search)
        await localStorage.setItem("token", queryParams.token)
        model.loadMe()
        this.setState({loaded: true})
    }

    render() {
        if (!this.state.loaded) {
            return <BaseLoading isShow={true} />
        }
        return (
            <Router history={history}>
                <Switch>
                    <Route exact path="/" render={(props) => <Tasks {...props} model={model} />} />
                    <Route exact path="/go-klass" render={(props) => <GoKlassTasks {...props} model={model} />} />
                    <Route
                        path="/go-klass/:taskId/:taskUserId"
                        render={(props) => <GoKlassTaskDetail {...props} model={model} />}
                    />
                    <Route path="/users" render={(props) => <Users {...props} model={model} />} />
                    <Route path="/:id" render={(props) => <TaskDetails {...props} model={model} />} />
                </Switch>
            </Router>
        )
    }
}
