import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import ActionRowItem from "./ActionRowItem"
import styles from "../TriggerTable.module.css"

export default function TableRowItem(props) {
    const {data, onClickShowDetail, onClickRowItem} = props

    return (
        <tr key={data.id} className={styles.tableRow}>
            <td className={classNames(styles.tableCell, styles.cursor)} onClick={() => onClickRowItem(data)}>
                {data.title}
            </td>
            <td className={styles.tableCell}>{data.trigger}</td>
            <td className={styles.tableCell}>{data.department}</td>
            <td className={styles.tableCell}>{data.responses}</td>

            <td className={styles.tableCell}>
                <ActionRowItem data={data} onClickShowDetail={onClickShowDetail} />
            </td>
        </tr>
    )
}

TableRowItem.propTypes = {
    data: PropTypes.object,
    onClickShowDetail: PropTypes.func,
    onClickRowItem: PropTypes.func
}
