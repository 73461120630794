const tableData = [
    {
        id: "1",
        taskName: "Admissions Package",
        reference: "TK45G9B1",
        taskType: "Package",
        document: "",
        due: "",
        status: "Sent 3rd April 2020",
        statusType: "SENT",
        childs: [
            {
                id: "2",
                taskName: "APPLICATION FORM",
                reference: "",
                taskType: "Digital Document",
                document: "",
                due: "3rd Jue 2019",
                status: "Sent 3rd April 2020",
                statusType: "SENT",
                isHaveExpandIcon: false,
                isChildTask: true
            },
            {
                id: "3",
                taskName: "TERMS AND CONDITIONS",
                reference: "",
                taskType: "Digital Document",
                document: "",
                due: "3rd Jue 2019",
                status: "Sent 3rd April 2020",
                statusType: "SENT",
                isHaveExpandIcon: false,
                isChildTask: true
            },
            {
                id: "4",
                taskName: "PHOTO ID DOCUMENTS",
                reference: "",
                taskType: "Scan",
                document: "",
                due: "3rd Jue 2019",
                status: "Sent 3rd April 2020",
                statusType: "SENT",
                isHaveExpandIcon: false,
                isChildTask: true
            },
            {
                id: "5",
                taskName: "PARENTAL CONSENT",
                reference: "",
                taskType: "Digital Document",
                document: "",
                due: "3rd Jue 2019",
                status: "Sent 3rd April 2020",
                statusType: "SENT",
                isHaveExpandIcon: false,
                isChildTask: true
            },
            {
                id: "6",
                taskName: "FACIAL RECOGNITION PHOTO",
                reference: "",
                taskType: "Scan",
                document: "",
                due: "3rd Jue 2019",
                status: "Sent 3rd April 2020",
                statusType: "SENT",
                isHaveExpandIcon: false,
                isChildTask: true
            }
        ]
    },
    {
        id: "7",
        taskName: "Daymar Application Form",
        reference: "TD100G9B1",
        taskType: "Digital Document",
        document: "",
        due: "28th Nov 2019",
        status: "OVERDUE",
        statusType: "OVERDUE"
    },
    {
        id: "8",
        taskName: "Photo ID",
        reference: "LG45J12F",
        taskType: "Scan",
        document: "",
        due: "28th Nov 2019",
        status: "COMPLETED 30th Dec 2018",
        statusType: "COMPLETED"
    },
    {
        id: "9",
        taskName: "Healthcare",
        reference: "LG45J12F",
        taskType: "Package",
        document: "",
        due: "28th Nov 2019",
        status: "COMPLETED 30th Dec 2018"
    }
]

export {tableData}
