import React from "react"
import {findIndex, keyBy, pick, cloneDeep, isEmpty, debounce} from "lodash"
import moment from "moment"
import classNames from "classnames"
import {Divider, message} from "antd"
import Model from "Model"
import {taskService} from "services"
import {getValuesChangeTwoObj} from "helpers"
import {SUBTYPE} from "constant"
import {BaseError} from "components/Common"
import {BaseButton} from "components/Button"
import TaskHeaderSettingBody from "./TaskHeaderSettingBody"
import {
    ChooseConfigureTaskType,
    ConfigureTaskStudentFilter,
    ConfigureTaskStaffFilter,
    ConfigureTaskStaffTable,
    ConfigureTaskStudentTable,
    ConfigureTaskOtherTable
} from "../DefinedUsers"
import {userService} from "services"
import styles from "../ConfigureTask.module.css"

interface Props {
    data?: any
    model: Model
    subtype: string
    onSuccess: Function
    onClose: Function
    fromLibrary?: boolean
    library?: boolean
}

interface State {
    taskData: any
    oldTaskData: any
    userTypes: Array<object>
    definedUsers: object
    students: Array<object>
    filterTypeActive: string
    tableTypeActive: string
    activeBox: any
    isSending: boolean
    isEditing: boolean
    errors: any
}

export default class ConfigureTaskDigitalDocument extends React.Component<Props, State> {
    debouncedLoadStudentUsers: any

    constructor(props) {
        super(props)
        const {data} = props

        const initData = this.initialData(data)
        this.state = {
            taskData: cloneDeep(initData),
            oldTaskData: cloneDeep(initData),
            userTypes: [
                {id: "STUDENT", name: "Student"},
                {id: "STAFF", name: "Staff"},
                {id: "OTHER", name: "Other"}
            ],
            filterTypeActive: "",
            tableTypeActive: "",
            isSending: false,
            isEditing: !!data,
            errors: {},
            students: undefined,
            activeBox: undefined,
            definedUsers: {}
        }

        this.debouncedLoadStudentUsers = debounce(this.loadStudentUsers, 0, {leading: true})
    }

    loadStudentUsers = async (value) => {
        const {model} = this.props
        return await model.loadStudentUsers({query: value, role: "student", limit: 100000, offset: 0})
    }

    initialData(dataProps) {
        const {subtype} = this.props
        const result: any = {
            taskSettingType: "",
            name: "",
            description: "",
            dueDate: "",
            file: "",
            category: "",
            staffUser: "",
            taskType: "",
            type: "dd",
            autoApprove: 0,
            subtype
        }
        if (!dataProps) return result

        return {...result, ...dataProps, dueDate: ""}
    }

    onChangeTaskData = (key, value, checkBoxKey = "") => {
        const {taskData} = this.state
        if (checkBoxKey) {
            const checkBoxKeyIndex = findIndex(taskData[checkBoxKey], (option) => option.key === key)
            if (checkBoxKeyIndex !== -1) {
                taskData[checkBoxKey][checkBoxKeyIndex].value = value
            }
        } else {
            taskData[key] = value
        }
        this.setState({taskData})
    }
    onLoadDefinedUsers = (definedUsers) => {
        if (Object.keys(this.state.definedUsers).length > 0) return
        const newDefinedUsers = {}
        definedUsers.forEach((user) => {
            newDefinedUsers[user.index] = user
        })
        this.setState({definedUsers: newDefinedUsers})
    }
    onSelect = (item) => {
        const {subtype} = this.props
        const {students, definedUsers} = this.state
        if (subtype === SUBTYPE.ON_DEMAND) {
            return
        }
        const {userType} = item
        let filterTypeActive: ""
        let tableTypeActive = ""

        if (
            userType === "OTHER" ||
            (userType === "STUDENT" && students !== undefined) ||
            (userType === "STAFF" &&
                definedUsers[item.index] !== undefined &&
                definedUsers[item.index].users !== undefined &&
                definedUsers[item.index].users.length > 0)
        ) {
            tableTypeActive = userType
        } else {
            filterTypeActive = userType
        }

        let newItem = item
        if (definedUsers[item.index] !== undefined) {
            newItem = {
                ...definedUsers[item.index],
                ...item
            }
        }

        const newDefinedUsers = {
            ...definedUsers,
            [item.index]: newItem
        }

        this.setState({filterTypeActive, tableTypeActive, activeBox: item, definedUsers: newDefinedUsers}, () => {
            if (
                userType === "OTHER" &&
                (definedUsers[item.index] === undefined ||
                    definedUsers[item.index].users === undefined ||
                    definedUsers[item.index].users.length === 0)
            ) {
                this.onClickAcceptFilter("OTHER", null)
            }
        })
    }
    onAddNewDefinedUser = (user) => {
        const {definedUsers} = this.state
        const newDefinedUsers = {
            ...definedUsers,
            [user.index]: user
        }
        this.setState({definedUsers: newDefinedUsers})
    }

    onRemoveConfigureTaskTypeSuccess = (item) => {
        this.setState({filterTypeActive: "", tableTypeActive: ""})
    }

    onClickAcceptFilter = async (type, filterData) => {
        const {definedUsers, activeBox, students} = this.state
        const update: any = {filterTypeActive: "", tableTypeActive: type}
        if (activeBox === undefined && type !== "STUDENT") {
            return
        }

        switch (type) {
            case "STUDENT":
                if (Array.isArray(filterData.students)) {
                    update.students = filterData.students
                } else {
                    const response = await userService.searchUsers({
                        ...filterData,
                        role: "student"
                    })
                    update.students = response.response
                }
                update.definedUsers = {
                    ...definedUsers,
                    [activeBox.index]: activeBox
                }
                this.cleaAllDefinedUsersOthers()
                break
            case "OTHER":
                const users =
                    students === undefined
                        ? [
                              {
                                  email: "",
                                  name: "",
                                  student: null,
                                  noStudent: true
                              }
                          ]
                        : students.map((student) => {
                              return {
                                  email: "",
                                  name: "",
                                  student
                              }
                          })
                update.definedUsers = {
                    ...definedUsers,
                    [activeBox.index]: {
                        ...activeBox,
                        listEnabled: false,
                        users
                    }
                }
                break
            case "STAFF":
                update.definedUsers = {
                    ...definedUsers,
                    [activeBox.index]: {
                        ...activeBox,
                        users: filterData.members
                    }
                }
                break
        }

        this.setState(update)
    }
    cleaAllDefinedUsersOthers = () => {
        const {definedUsers} = this.state
        const newDefinedUsers = {}
        Object.keys(definedUsers).forEach((key) => {
            newDefinedUsers[key] = definedUsers[key]
            if (definedUsers[key].userType === "OTHER") {
                definedUsers[key].users = []
            }
        })
        this.setState({definedUsers: newDefinedUsers})
    }
    onClickSendBtn = () => {
        const {taskData} = this.state
        const errors = this.validateDataBeforeSubmit()
        if (errors) {
            this.setState({errors})
            return
        }
        this.setState({errors: {}})

        if (taskData.id) this.onUpdateTask()
        else this.onCreateTask()
    }

    onDefinedUserTableEdit = (type) => {
        const {tableTypeActive} = this.state
        const update: any = {}
        if (type === "STUDENT") {
            update.students = []
        } else if (type === "STAFF") {
            const {definedUsers, activeBox} = this.state
            definedUsers[activeBox.index].users = []
        }
        if (tableTypeActive === type) {
            update.tableTypeActive = ""
            update.filterTypeActive = type
        }

        this.setState(update)
    }
    onOthersChange = (data) => {
        const {definedUsers, activeBox} = this.state
        const newDefinedUsers = {
            ...definedUsers
        }
        newDefinedUsers[activeBox.index].users = data
        this.setState({definedUsers: newDefinedUsers})
    }
    onOthersEnableListChange = (enabled) => {
        const {definedUsers, activeBox} = this.state
        const newDefinedUsers = {
            ...definedUsers
        }
        definedUsers[activeBox.index].listEnabled = enabled
        this.setState({definedUsers: newDefinedUsers})
    }

    validateDataBeforeSubmit = () => {
        let errors: any = {}
        const {taskData, definedUsers, students, isEditing} = this.state
        const {library, fromLibrary} = this.props

        if (!taskData.name) errors.name = true
        if (!library && !taskData.dueDate) errors.dueDate = true
        if (!taskData.description) errors.description = true
        if (!taskData.category) errors.category = true
        if (!isEditing && !fromLibrary && !taskData.file) errors.file = true

        errors.definedUsers = {}
        if (!isEditing && Object.keys(definedUsers).length === 0) {
            errors.definedUsers["empty"] = "Define at least one user"
        }

        if (!(!isEditing && library)) {
            Object.keys(definedUsers).forEach((key) => {
                if (definedUsers[key].userType === "STUDENT" && (students === undefined || students.length === 0)) {
                    errors.definedUsers[definedUsers[key].index] = "Select at least one Student"
                } else if (
                    definedUsers[key].userType === "STAFF" &&
                    (definedUsers[key].users === undefined || definedUsers[key].users.length === 0)
                ) {
                    errors.definedUsers[definedUsers[key].index] = `Select the ${definedUsers[key].name}`
                } else if (definedUsers[key].userType === null) {
                    errors.definedUsers[definedUsers[key].index] = "Select an user"
                }
            })
        }

        if (isEmpty(errors.definedUsers)) {
            delete errors.definedUsers
        }
        return isEmpty(errors) ? null : errors
    }

    onCreateTask = async () => {
        const {taskData, students, definedUsers} = this.state

        const {subtype, library, fromLibrary} = this.props
        taskData.categoryID = taskData.category ? taskData.category.id : undefined
        taskData.staffUserID = taskData.staffUser ? taskData.staffUser.id : undefined
        const submitData = pick(taskData, [
            "name",
            "parentId",
            "dDocId",
            "description",
            "type",
            "dueDate",
            "categoryID",
            "file",
            "autoApprove"
        ])

        if (taskData.dueDate) {
            submitData.dueDate = moment(taskData.dueDate)
                .format("YYYY-MM-DD")
                .toString()
        }
        submitData.subtype = subtype
        if (taskData.reminders) {
            submitData.reminders = taskData.reminders.map((option) => option.id)
        }
        if (taskData.form) {
            submitData.formId = taskData.form.id
        }

        if (taskData.staffUser) {
            submitData.cc = taskData.staffUser.map((staff) => staff.id)
        }

        submitData.definedUsers = Object.keys(definedUsers).map((k) => {
            const definedUser = definedUsers[k]
            return {
                type: definedUser.userType ? definedUser.userType.toLowerCase() : "",
                label: definedUser.name,
                order: definedUser.index + 1
            }
        })

        try {
            this.setState({isSending: true})
            let task = await taskService.createTask(submitData)
            const url = task.url

            if (!library) {
                let hasOthers = false
                const assignedUserIds = []
                task = await taskService.getTaskDetail(task.id)

                const ddDefinedUserIds = {}
                task.digitalDocument.users.forEach((user) => {
                    ddDefinedUserIds[user.order - (fromLibrary ? 0 : 1)] = user.id
                })

                const definedUserstoAssign = Object.keys(definedUsers).map((key) => {
                    const item: any = {
                        type: definedUsers[key].userType.toLowerCase(),
                        definedUserId: ddDefinedUserIds[key]
                    }
                    if (definedUsers[key].userType === "STAFF") {
                        item.userIds = definedUsers[key].users.map((user) => user.id)
                    }
                    if (definedUsers[key].userType === "OTHER") {
                        hasOthers = true
                        item.assigned = definedUsers[key].users.map((user) => {
                            assignedUserIds.push(user.student.id)
                            return {
                                studentId: user.student.id,
                                name: user.name,
                                email: user.email
                            }
                        })
                    }
                    return item
                })

                const payload = {
                    taskId: task.id,
                    studentIds: !!students ? students.map((s: any) => s.id) : [],
                    assignedIds: hasOthers && !students ? assignedUserIds : undefined,
                    definedUsers: definedUserstoAssign
                }

                await taskService.assignTask(payload)
            }

            message.success("Create task successful")
            if (!!task.parentId) {
                this.setState({isSending: false}, () => {
                    this.props.onSuccess()
                })
            } else {
                window.top.location.href = url
            }
        } catch (error) {
            console.log(error)
            message.error("Create task failure")
        } finally {
            this.setState({isSending: false})
        }
    }

    onUpdateTask = async () => {
        const {taskData, oldTaskData} = this.state
        taskData.categoryID = taskData.category ? taskData.category.id : undefined
        taskData.staffUserID = taskData.staffUser ? taskData.staffUser.id : undefined

        const attrs = ["name", "description", "type", "dueDate", "categoryID", "autoApprove"]
        const oldData = pick(oldTaskData, attrs)
        const newData = pick(taskData, attrs)
        const dataSubmit = getValuesChangeTwoObj(oldData, newData)
        /*
        if (!dataSubmit) {
            this.props.onClose()
            return
        }*/
        try {
            this.setState({isSending: true})
            const task = await taskService.updateTask(taskData.id, dataSubmit)
            message.success("Update task successful")
            this.setState({isSending: false}, () => {
                this.props.onSuccess()
            })
            //  window.top.location.href = task.url
        } catch (error) {
            message.error("Update task failure")
        }
    }

    renderFilterByType = () => {
        const {filterTypeActive, userTypes} = this.state
        const {model, library} = this.props
        const userTypesKeyByID = keyBy(userTypes, "id")
        if (library) {
            return null
        }
        switch (filterTypeActive) {
            case userTypesKeyByID.STUDENT.id:
                return (
                    <ConfigureTaskStudentFilter
                        type="STUDENT"
                        model={model}
                        onClickAcceptFilter={this.onClickAcceptFilter}
                    />
                )
            case userTypesKeyByID.STAFF.id:
                return (
                    <ConfigureTaskStaffFilter
                        type="STAFF"
                        model={model}
                        onClickAcceptFilter={this.onClickAcceptFilter}
                    />
                )
            default:
                return null
        }
    }

    renderTableByType = () => {
        if (this.props.library) {
            return null
        }
        const {tableTypeActive, userTypes, activeBox, definedUsers} = this.state
        const userTypesKeyByID = keyBy(userTypes, "id")
        switch (tableTypeActive) {
            case userTypesKeyByID.STUDENT.id:
                return (
                    <ConfigureTaskStudentTable
                        data={this.state.students}
                        onEdit={() => this.onDefinedUserTableEdit("STUDENT")}
                    />
                )
            case userTypesKeyByID.STAFF.id:
                return (
                    <ConfigureTaskStaffTable
                        data={definedUsers[activeBox.index] ? definedUsers[activeBox.index].users : []}
                        onEdit={() => this.onDefinedUserTableEdit("STAFF")}
                    />
                )
            case userTypesKeyByID.OTHER.id:
                return (
                    <ConfigureTaskOtherTable
                        data={definedUsers[activeBox.index] ? definedUsers[activeBox.index].users : undefined}
                        loadStudentFn={this.debouncedLoadStudentUsers}
                        onChange={this.onOthersChange}
                        onEnableList={this.onOthersEnableListChange}
                        listEnabled={definedUsers[activeBox.index] ? definedUsers[activeBox.index].listEnabled : false}
                    />
                )
            default:
                return null
        }
    }

    render() {
        const {definedUsers, taskData, userTypes, isSending, errors, isEditing} = this.state
        const {model, data, library} = this.props

        let defaultDefinedUsers = undefined
        if (data && data.digitalDocument) {
            defaultDefinedUsers = data.digitalDocument.users
        }
        return (
            <>
                <TaskHeaderSettingBody
                    data={taskData}
                    onChangeTaskData={this.onChangeTaskData}
                    model={model}
                    errors={errors}
                    library={library}
                />
                <Divider className={styles.divider} />
                {errors.definedUsers && errors.definedUsers["empty"] && (
                    <BaseError error={errors.definedUsers["empty"]} />
                )}

                <ChooseConfigureTaskType
                    userTypes={userTypes}
                    onSelect={this.onSelect}
                    onLoad={this.onLoadDefinedUsers}
                    definedUsers={defaultDefinedUsers}
                    onAddNewUser={this.onAddNewDefinedUser}
                    onRemoveConfigureTaskTypeSuccess={this.onRemoveConfigureTaskTypeSuccess}
                    isCreateForm={true}
                    editable={defaultDefinedUsers === undefined}
                    errors={errors.definedUsers || {}}
                />
                {this.renderFilterByType()}
                {this.renderTableByType()}
                {/* <ConfigureTaskFilter /> */}
                {/* <div className={styles.chooseDocsWrap}>
                    <Icon className={styles.handIcon} icon="HAND" />
                    <p className={styles.chooseDocsDescription}>
                        Choose an existing Digital Document or upload your own PDF to create one
                    </p>
                </div> */}
                <div className={styles.footerWrap}>
                    <div className={classNames(styles.footerBtnWrap, styles.saveBtnWrap)}>
                        <BaseButton
                            title={isEditing ? "SAVE" : library ? "CREATE" : "SEND"}
                            loading={isSending}
                            onClick={this.onClickSendBtn}
                        />
                    </div>
                </div>
            </>
        )
    }
}
