import React from "react"
import {Tabs, Button} from "antd"
import moment from "moment"
import {useHistory} from "react-router-dom"
import * as QueryString from "query-string"
import {Icon} from "components/Icons"
import styles from "../detail.module.css"
import {BaseButton} from "components/Button"

const {TabPane} = Tabs

export function WebItem(props) {
    const history = useHistory()

    const onClickBack = () => {
        const searchParams: any = QueryString.parse(history.location.search)
        const token = (searchParams && searchParams.token) || ""
        history.push(`/go-klass?token=${token}`)
    }

    const {data, model} = props
    const {dateFormat} = model

    return (
        <div className={styles.itemWrap}>
            <div className={styles.titleWrap}>
                <Button className={styles.backIconBtn} onClick={onClickBack}>
                    <Icon className={styles.backIcon} icon="BACK" />
                </Button>
                <p className={`${styles.title} ${styles.headerTitle}`}>Complete a task</p>
            </div>
            <Tabs className={`fullwidth ${styles.tabsWrapper}`} defaultActiveKey="1">
                <TabPane
                    className={styles.tabItem}
                    tab={
                        <span>
                            <Icon className={styles.tabIcon} icon="FILE" />
                            <span className={styles.tabTitle}>Web Form</span>
                        </span>
                    }
                    key="1">
                    <div className={styles.bodyWrap}>
                        <p className={styles.title}>{data.name}</p>
                        <p className={styles.description}>Complete the highlighted fields to submit this task</p>
                        <div className={styles.bodyInfoWrap}>
                            <div className={styles.bodyInfoItem} style={{minWidth: 110}}>
                                <p className={styles.bodyInfoItem__title}>Requested:</p>
                                <p className={styles.bodyInfoItem__value}>
                                    {data.createdAt ? moment(data.createdAt).format(dateFormat) : ""}
                                </p>
                            </div>
                            <div className={styles.bodyInfoItem} style={{minWidth: 110}}>
                                <p className={styles.bodyInfoItem__title}>Due date:</p>
                                <p className={styles.bodyInfoItem__value}>
                                    {data.dueDate ? moment(data.dueDate).format(dateFormat) : ""}
                                </p>
                            </div>
                            <div className={styles.bodyInfoItem} style={{minWidth: 50}}>
                                <p className={styles.bodyInfoItem__title}>Status:</p>
                                <p className={styles.bodyInfoItem__value}>{data.status}</p>
                            </div>
                            <div className={styles.bodyInfoItem} style={{minWidth: 140}}>
                                <p className={styles.bodyInfoItem__title}>Type:</p>
                                <p className={styles.bodyInfoItem__value}>Web Form</p>
                            </div>
                            <div className={styles.bodyInfoItem}>
                                <p className={styles.bodyInfoItem__title}>Description:</p>
                                <p className={styles.bodyInfoItem__value}>{data.description}</p>
                            </div>
                        </div>
                        <div className={styles.actionWrap}>
                            {/* <div className={styles.leftActionWrap}>
                                <Button className={styles.leftActionBtn}>Cancel</Button>
                                <Button className={styles.leftActionBtn}>Save</Button>
                            </div> */}
                            <div className={styles.rightActionWrap}>
                                <BaseButton title="Submit" />
                            </div>
                        </div>
                    </div>
                </TabPane>
            </Tabs>
        </div>
    )
}
