import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import classNames from "classnames"
import ActionRowItem from "./ActionRowItem"
import styles from "../AssignedTable.module.css"
import {STATUS, TASK_TYPE} from "constant"

export default function TableRowItem(props) {
    const {data, onClickShowDetail, onClickRowItem, onDeleteTaskSuccess, fields, model} = props
    const {dateFormat} = model

    return (
        <tr key={data.id} className={styles.tableRow}>
            {fields.includes("Status") && (
                <td className={styles.tableCell} onClick={() => onClickRowItem(data)} style={{width: "150px"}}>
                    <div
                        className={classNames(
                            styles.statusWrap,
                            data.overdue ? styles.overdueStatus : "",
                            !data.overdue && data.status === "open" ? styles.openStatus : "",
                            data.status === "submitted" ? styles.completedStatus : "",
                            data.status === "completed" ? styles.completedStatus : ""
                        )}>
                        {STATUS[data.status === "open" && data.overdue ? "overdue" : data.status]}
                    </div>
                </td>
            )}
            {fields.includes("Task name") && (
                <td className={classNames(styles.tableCell, styles.cursor)} onClick={() => onClickRowItem(data)}>
                    {data.name}
                </td>
            )}
            {fields.includes("Task type") && (
                <td className={classNames(styles.tableCell, styles.cursor)} onClick={() => onClickRowItem(data)}>
                    {TASK_TYPE[data.type]}
                </td>
            )}
            {fields.includes("Description") && (
                <td className={classNames(styles.tableCell, styles.cursor)} onClick={() => onClickRowItem(data)}>
                    {data.description}
                </td>
            )}
            {fields.includes("Student") && (
                <td className={classNames(styles.tableCell, styles.cursor)} onClick={() => onClickRowItem(data)}>
                    {data.subtasks > 1 ? "Multiple" : data.student}
                </td>
            )}
            {fields.includes("Responses") && (
                <td className={classNames(styles.tableCell, styles.cursor)} onClick={() => onClickRowItem(data)}>
                    {data.subtasks > 1 ? "Multiple" : `${data.pending}/${data.total}`}
                </td>
            )}
            {fields.includes("Campus") && (
                <td className={classNames(styles.tableCell, styles.cursor)} onClick={() => onClickRowItem(data)}>
                    {data.campuses.length > 1 ? "Multiple" : data.campuses.join(",")}
                </td>
            )}
            {fields.includes("Program") && (
                <td className={classNames(styles.tableCell, styles.cursor)} onClick={() => onClickRowItem(data)}>
                    {data.programs.length > 1 ? "Multiple" : data.programs.join(",")}
                </td>
            )}
            {fields.includes("Created") && (
                <td className={classNames(styles.tableCell, styles.cursor)} onClick={() => onClickRowItem(data)}>
                    {data.createdAt ? moment(data.createdAt).format(dateFormat) : ""}
                </td>
            )}
            {fields.includes("Due") && (
                <td className={classNames(styles.tableCell, styles.cursor)} onClick={() => onClickRowItem(data)}>
                    {data.dueDate ? moment(data.dueDate).format(dateFormat) : ""}
                </td>
            )}
            {fields.includes("Created By") && <td className={styles.tableCell}>{data.createdBy}</td>}
            <td className={styles.tableCell}>
                <div className={styles.flexEnd}>
                    <ActionRowItem
                        model={model}
                        data={data}
                        onClickShowDetail={onClickShowDetail}
                        onDeleteTaskSuccess={onDeleteTaskSuccess}
                    />
                </div>
            </td>
        </tr>
    )
}

TableRowItem.propTypes = {
    data: PropTypes.object,
    onClickShowDetail: PropTypes.func,
    onClickRowItem: PropTypes.func
}
