import React, {useState} from "react"
import {Divider, Button} from "antd"
import ResponsesTable from "./ResponsesTable"
import ResponsesFooter from "./ResponsesFooter"
import styles from "./ResponsesTab.module.css"
import {TaskPDFViewer} from "./TaskPDFViewer"
import {BaseLoading} from "components/Loading"

export default function ResponsesTab({data, model}) {
    const [resending, setResending] = useState(null)
    const [checkboxData, setCheckboxData] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const onReload = () => {
        model.reloadOneTask()
    }
    const onUpdateLoading = (newLoading) => {
        setIsLoading(newLoading)
    }

    return (
        <>
            <BaseLoading isShow={isLoading} />
            <ResponsesTable data={data} resend={setResending} onChange={setCheckboxData} model={model} />
            <Divider className={styles.divider} />
            <ResponsesFooter
                data={data}
                checkboxData={checkboxData}
                resending={resending}
                done={() => {
                    onReload()
                    setResending(null)
                }}
                onUpdateLoading={onUpdateLoading}
            />
            <TaskPDFViewer data={data} model={model} />
        </>
    )
}
