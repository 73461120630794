import React, {Component} from "react"
import AsyncSelect from "react-select/async"

interface Props {
    onChange?: any
    options?: any
    value?: any
    loadOptions?: any
    valueKey?: string
    labelKey?: string
    getOptionValue: any
    getOptionLabel: any
    isMulti?: boolean
    isKlassDropdown?: boolean
    stylesCustom?: any
}

const customStyles = {
    control: (provided) => ({
        ...provided,
        borderRadius: "6px",
        borderColor: "transparent",
        boxShadow: "1px 2px 3px 1px var(--black-100)",
        border: "none",
        minHeight: "44px",
        fontSize: 16
    }),

    container: (provided) => ({
        ...provided
    }),

    indicatorSeparator: () => ({
        display: "none"
    }),

    dropdownIndicator: (provided, state) => ({
        ...provided,
        display: "flex",
        alignItems: "center",
        width: "40px",
        height: "100%",
        borderTopRightRadius: "6px",
        borderBottomRightRadius: "6px",
        backgroundColor: "var(--primary-400-base)",
        borderColor: "var(--primary-400-base)",
        justifyContent: "center",
        color: "var(--whitebase)",
        "&:hover": {color: "var(--whitebase)"},
        cursor: "pointer"
    }),

    placeholder: (provided) => ({
        ...provided,
        fontSize: "16px",
        color: "var(--black-200)"
    }),

    multiValue: (styles) => {
        return {
            ...styles,
            backgroundColor: "var(--primary-400-base)"
        }
    },

    multiValueLabel: (styles) => ({
        ...styles,
        color: "var(--whitebase)"
    }),

    multiValueRemove: (styles) => ({
        ...styles,
        color: "var(--whitebase)"
    }),

    menuList: () => ({
        maxHeight: "200px",
        overflow: "auto"
    })
}

const defaultStyles = {
    control: (provided) => ({
        ...provided,
        minHeight: "44px"
    })
}

export class BaseAsyncSelect extends Component<Props, {}> {
    render() {
        const {stylesCustom, loadOptions, isKlassDropdown, ...rest} = this.props
        let styles = isKlassDropdown ? customStyles : defaultStyles
        if (stylesCustom) styles = {...styles, ...stylesCustom}
        const props = {...rest, styles}

        return <AsyncSelect cacheOptions defaultOptions loadOptions={loadOptions} {...props} />
    }
}
