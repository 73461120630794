import React from "react"
import {Button} from "antd"
import {Icon} from "components/Icons"
import styles from "../detail.module.css"

export function FileItem(props) {
    const {fileUrl, index, onClickRemoveFileItem} = props

    const getFileName = (fileUrl) => {
        const splitedFileUrl = fileUrl.split("/")
        const fileNameWithExtension = splitedFileUrl[splitedFileUrl.length - 1]
        const temp = fileNameWithExtension.split(".")
        const extension = temp.pop()
        const fileName = temp.join(".")
        return {fileName, extension}
    }

    const {fileName, extension} = getFileName(fileUrl)

    return (
        <div className={styles.fileItem}>
            <img className={styles.fileItem__img} src={fileUrl} alt="" />
            <div className={styles.fileItem__content}>
                <p className={`${styles.fileItem__contentText} ${styles.fileItem__contentTextBold}`}>{fileName}</p>
                <p className={styles.fileItem__contentText}>{extension}</p>
            </div>
            <div className={styles.fileItem__removeIconWrap}>
                <Button className={styles.fileItem_removeIconBtn} onClick={() => onClickRemoveFileItem(index)}>
                    <Icon className={styles.fileItem_removeIcon} icon="CLOSE" />
                </Button>
            </div>
        </div>
    )
}
