import React from "react"
import PropTypes from "prop-types"
import styles from "./ConfigureTaskStaffTable.module.css"

export default function TableRowItem(props) {
    const {data} = props
    let campus = "None"
    if (Array.isArray(data.locations)) {
        if (data.locations.length > 1) {
            campus = "Multiple"
        } else if (data.locations.length === 1) {
            campus = data.locations.map((loc) => loc.name).join(",")
        }
    }
    return (
        <tr key={data.id} className={styles.tableRow}>
            <td className={styles.tableCell}>
                {data.first_name} {data.last_name}
            </td>
            <td className={styles.tableCell}>{campus}</td>
            <td className={styles.tableCell}>{data.email}</td>
        </tr>
    )
}

TableRowItem.propTypes = {
    data: PropTypes.object
}
