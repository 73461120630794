import React from "react"
import PropTypes from "prop-types"
import styles from "./ConfigureTaskTable.module.css"

export default function TableRowItem(props) {
    const {data} = props

    return (
        <tr key={data.id} className={styles.tableRow}>
            <td className={styles.tableCell}>
                {data.first_name} {data.last_name}
            </td>
            <td className={styles.tableCell}>{data.college_id}</td>
            <td className={styles.tableCell}>{data.major ? data.major.name : ""}</td>
            <td className={styles.tableCell}>{data.email}</td>
        </tr>
    )
}

TableRowItem.propTypes = {
    data: PropTypes.object
}
