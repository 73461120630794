import React from "react"
import Model from "Model"
import AllFilter from "./AllFilter"
import AllTable from "./AllTable"

interface Props {
    model: Model
    isLoading?: boolean
    isLoadingTask?: boolean
    data: Array<object>
    onReGetListTasks: Function
}

export default class AllTab extends React.Component<Props, {}> {
    state = {
        fields: ["status", "name", "description", "type", "created", "dueDate", "progress"]
    }

    onApplyFilter = (data) => {
        console.log("data", data)
    }

    render() {
        const {fields} = this.state
        const {model, isLoadingTask, data} = this.props

        return (
            <>
                {/*
                <AllFilter model={model} onApplyFilter={this.onApplyFilter} />
             */}
                <AllTable model={model} data={data} isLoading={isLoadingTask} fields={fields} />
            </>
        )
    }
}
