import React, {useState} from "react"
import {Button, Checkbox} from "antd"
import {difference} from "lodash"
import {useHistory} from "react-router-dom"
import * as QueryString from "query-string"
import {Icon} from "components/Icons"
import {TableRowItem, EmptyRow, LoadingRow} from "./Table"
import styles from "./AssignedTable.module.css"

const CheckboxGroup = Checkbox.Group

function BodyTable(props) {
    const {tableData, isLoading, ...rest} = props
    if (isLoading) return <LoadingRow colSpan={8} />
    if (!tableData.length) return <EmptyRow colSpan={8} />
    return tableData.map((item) => <TableRowItem key={item.id} data={item} {...rest} />)
}

export default function AssignedTable(props) {
    const history = useHistory()
    const {onClickShowDetail, data, isLoading, onDeleteTaskSuccess, model, fields, allFields, onChangeFields} = props
    const restFields = difference(allFields, fields)

    const [isShowChooseCols, setIsShowChooseCols] = useState(false)
    const [activedfields, setActiveFields] = useState(fields)
    const [availableFields, setAvailableFields] = useState([])

    const onClickRowItem = (data) => {
        const searchParams: any = QueryString.parse(history.location.search)
        const token = (searchParams && searchParams.token) || ""
        history.push(`/${data.id}?token=${token}`, {data})
    }

    const onChange = (checkedList) => {
        setActiveFields(checkedList)
        onUpdateActivedFields(checkedList, availableFields)
    }

    const onChangeAvailableFields = (checkedList) => {
        setAvailableFields(checkedList)
        onUpdateActivedFields(activedfields, checkedList)
    }

    const onUpdateActivedFields = (checkedFields, checkedAvailableFields) => {
        const fields = [...checkedFields, ...checkedAvailableFields]
        const restFields = difference(allFields, fields)
        setAvailableFields([])
        setActiveFields(fields)
        onChangeFields(fields)
    }

    return (
        <div className={styles.wrapper}>
            <table className={fields.length === allFields.length ? styles.tableHorizontalScroll : styles.table}>
                <thead className={styles.tableHead}>
                    <tr className={styles.tableRowHeader}>
                        {fields.includes("Status") && (
                            <th
                                className={`${styles.tableCell} ${styles.tableCellHeader} ${styles.tableCellCenter}`}
                                style={{width: "150px"}}>
                                Status
                            </th>
                        )}
                        {fields.includes("Task name") && (
                            <th className={`${styles.tableCell} ${styles.tableCellHeader}`}>Task name</th>
                        )}
                        {fields.includes("Task type") && (
                            <th className={`${styles.tableCell} ${styles.tableCellHeader}`}>Task type</th>
                        )}
                        {fields.includes("Description") && (
                            <th className={`${styles.tableCell} ${styles.tableCellHeader}`}>Description</th>
                        )}
                        {fields.includes("Student") && (
                            <th className={`${styles.tableCell} ${styles.tableCellHeader}`}>Student</th>
                        )}
                        {fields.includes("Responses") && (
                            <th className={`${styles.tableCell} ${styles.tableCellHeader}`}>Responses</th>
                        )}
                        {fields.includes("Campus") && (
                            <th className={`${styles.tableCell} ${styles.tableCellHeader}`}>Campus</th>
                        )}
                        {fields.includes("Program") && (
                            <th className={`${styles.tableCell} ${styles.tableCellHeader}`}>Program</th>
                        )}
                        {fields.includes("Created") && (
                            <th className={`${styles.tableCell} ${styles.tableCellHeader}`}>Created</th>
                        )}
                        {fields.includes("Due") && (
                            <th className={`${styles.tableCell} ${styles.tableCellHeader}`}>Due date</th>
                        )}
                        {fields.includes("Created By") && (
                            <th className={`${styles.tableCell} ${styles.tableCellHeader}`}>Created By</th>
                        )}
                        <th className={`${styles.tableCell} ${styles.tableCellHeader}`}>
                            <div className={styles.flexEnd}>
                                <Button
                                    className={styles.plusIconBtn}
                                    icon={<Icon icon="PLUS" className={styles.iconPlus} />}
                                    onClick={() => setIsShowChooseCols(!isShowChooseCols)}
                                />
                            </div>
                            {isShowChooseCols && (
                                <div className={`col-picker ${styles.colsPicker}`}>
                                    <Button
                                        onClick={() => setIsShowChooseCols(false)}
                                        className={styles.closeColsPickerIcon}
                                        icon={
                                            <Icon
                                                className={styles.closeIcon}
                                                icon="CLOSE"
                                                color="rgba(0, 0, 0, 0.5)"
                                            />
                                        }
                                    />
                                    <p className={styles.colsPickerTitle}>Column</p>
                                    <div className={styles.colsPickerBody}>
                                        <p className={styles.colsPickerDesc}>Active</p>
                                        <CheckboxGroup
                                            options={fields}
                                            value={activedfields}
                                            onChange={onChange}
                                            className={styles.availableCheckboxes}
                                        />
                                        <p className={styles.colsPickerDescAvailable}>Available</p>
                                        <CheckboxGroup
                                            options={restFields}
                                            value={availableFields}
                                            onChange={onChangeAvailableFields}
                                            className={styles.availableCheckboxes}
                                        />
                                    </div>
                                </div>
                            )}
                        </th>
                    </tr>
                </thead>
                <tbody className={styles.bodyTable}>
                    <BodyTable
                        tableData={data}
                        isLoading={isLoading}
                        onClickShowDetail={onClickShowDetail}
                        onClickRowItem={onClickRowItem}
                        onDeleteTaskSuccess={onDeleteTaskSuccess}
                        fields={fields}
                        model={model}
                    />
                </tbody>
            </table>
        </div>
    )
}
