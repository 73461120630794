import React from "react"
import {findIndex, get} from "lodash"
import moment from "moment"
import {Divider} from "antd"
import Model from "Model"
import {PDFViewer} from "components/FileViewer"
import {ChooseConfigureTaskType} from "../../../items/Configure/DefinedUsers"
import TaskDetailBody from "./TaskDetailBody"
import styles from "./TaskDetail.module.css"

interface Props {
    data?: object
    model: Model
}

interface State {
    taskData: any
    oldTaskData: any
    userTypes: any
}

export default class TaskDetail extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        const {data} = props
        const initData = this.initialData(data)
        this.state = {
            taskData: initData,
            oldTaskData: initData,
            userTypes: [
                {id: "STUDENT", name: "Student"},
                {id: "STAFF", name: "Staff"},
                {id: "OTHER", name: "Other"}
            ]
        }
    }

    initialData(dataProps) {
        const result = {
            taskSettingType: "",
            name: "",
            description: "",
            dueDate: "",
            file: "",
            category: "",
            member: "",
            taskType: ""
        }
        if (!dataProps) return result
        return {...result, ...dataProps}
    }

    onChangeTaskData = (key, value, checkBoxKey = "") => {
        const {taskData} = this.state
        if (checkBoxKey) {
            const checkBoxKeyIndex = findIndex(taskData[checkBoxKey], (option) => option.key === key)
            if (checkBoxKeyIndex !== -1) {
                taskData[checkBoxKey][checkBoxKeyIndex].value = value
            }
        } else {
            taskData[key] = value
        }
        this.setState({taskData})
    }

    renderFile = () => {
        const {taskData} = this.state
        if (!taskData.subtasks || taskData.subtasks.length !== 1) {
            return null
        }
        const {files} = taskData.subtasks[0]
        if (!files) {
            return null
        }
        const fileName = files[0] //.replace("https://test-tasks.s3.amazonaws.com", "")
        return (
            <div>
                <Divider className={styles.divider} />
                <PDFViewer fileName={fileName} />
            </div>
        )
    }

    render() {
        const {taskData, userTypes} = this.state
        const {model} = this.props
        const users = get(taskData, "responseDetails", [])

        return (
            <>
                <TaskDetailBody data={taskData} onChangeTaskData={this.onChangeTaskData} model={model} />
                <ChooseConfigureTaskType
                    editable={false}
                    definedUsers={users}
                    userTypes={userTypes}
                    onSelect={() => {}}
                    onRemoveConfigureTaskTypeSuccess={() => {}}
                    isCreateForm={false}
                />
            </>
        )
    }
}
