import React from "react"
import {BaseButton} from "components/Button"
import {Icon} from "components/Icons"
import Model from "Model"
import {SUBTYPE} from "constant"
// import AssignedFilter from "./AssignedFilter"
import AssignedTable from "./AssignedTable"
import {ConfigureTask} from "../../Configure"
import {Iframe} from "helpers"
import styles from "./AssignedTab.module.css"

interface Props {
    model: Model
    isLoading?: boolean
    isLoadingTask?: boolean
    data: Array<object>
    onReGetListTasks: Function
}

export default class AssignedTab extends React.Component<Props, {}> {
    state = {
        isShowConfigureTask: false,
        configureTaskData: null,
        allFields: [
            "Status",
            "Task name",
            "Description",
            "Student",
            "Responses",
            "Campus",
            "Program",
            "Created",
            "Due",
            "Created By",
            "Task type"
        ],
        fields: ["Status", "Task name", "Task type", "Student", "Responses", "Due"]
    }

    onClickShowConfigureTaskPopup = () => {
        this.setState({isShowConfigureTask: true, configureTaskData: null})
    }

    onCloseConfigureTaskPopup = () => {
        this.setState({isShowConfigureTask: false, configureTaskData: null})
    }
    componentDidUpdate() {
        Iframe.updateSize()
    }
    onClickShowDetail = (data) => {
        this.setState({isShowConfigureTask: true, configureTaskData: data})
    }

    getConfigureTaskPopup() {
        const {isShowConfigureTask, configureTaskData} = this.state
        if (!isShowConfigureTask) return null
        const {model} = this.props

        return (
            <div>
                <ConfigureTask
                    isShow={isShowConfigureTask}
                    onClose={this.onCloseConfigureTaskPopup}
                    data={configureTaskData}
                    model={model}
                    onSuccess={this.onCreateTaskSuccess}
                    subtype={SUBTYPE.ASSIGNED}
                />
            </div>
        )
    }

    onApplyFilter = (data) => {
        console.log("data", data)
    }

    onCreateTaskSuccess = () => {
        this.setState({isShowConfigureTask: false})
        this.props.onReGetListTasks()
    }

    onDeleteTaskSuccess = () => {
        this.props.onReGetListTasks()
    }

    onChangeFields = (fields) => {
        this.setState({fields})
    }

    render() {
        const {fields, allFields} = this.state
        const {model, isLoading, isLoadingTask, data} = this.props
        return (
            <>
                {/*<AssignedFilter model={model} onApplyFilter={this.onApplyFilter}/>*/}
                <div className={styles.createTaskWrap}>
                    <BaseButton
                        title="CREATE TASK"
                        onClick={this.onClickShowConfigureTaskPopup}
                        icon={<Icon className={styles.iconPlus} icon="PLUS" color="#FFF" />}
                        disabled={isLoading}
                    />
                    {this.getConfigureTaskPopup()}
                </div>
                <AssignedTable
                    model={model}
                    onClickShowDetail={this.onClickShowDetail}
                    data={data}
                    isLoading={isLoadingTask}
                    onDeleteTaskSuccess={this.onDeleteTaskSuccess}
                    fields={fields}
                    allFields={allFields}
                    onChangeFields={this.onChangeFields}
                />
            </>
        )
    }
}
