import React from "react"
import {findIndex, isEmpty, pick, cloneDeep} from "lodash"
import moment from "moment"
import classNames from "classnames"
import {Divider, message} from "antd"
import Model from "Model"
import {taskService} from "services"
import {getValuesChangeTwoObj} from "helpers"
import {BaseButton} from "components/Button"
import TaskHeaderSettingBody from "./TaskHeaderSettingBody"
import {ConfigureTaskFilter} from "./items"
import styles from "../ConfigureTask.module.css"

interface Props {
    data?: object
    model: Model
    subtype: string
    onSuccess: Function
    onClose: Function
    library?: boolean
}

interface State {
    taskData: any
    oldTaskData: any
    isSubmitting: boolean
    errors: object
    filteredUsers: Array<any>
}

export default class ConfigureTaskScan extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        const {data} = props

        const initData = this.initialData(data)
        this.state = {
            taskData: cloneDeep(initData),
            oldTaskData: cloneDeep(initData),
            isSubmitting: false,
            errors: [],
            filteredUsers: []
        }
    }

    initialData(dataProps) {
        const {subtype} = this.props
        const result = {
            taskSettingType: "",
            name: "",
            description: "",
            dueDate: "",
            file: "",
            category: "",
            member: "",
            taskType: "",
            type: "scan",
            subtype,
            fileName: "",
            cc: "",
            assigners: [],
            autoApprove: false
        }
        if (!dataProps) return result
        return {...result, ...dataProps, dueDate: moment(dataProps.dueDate)}
    }
    componentDidUpdate(prevProps) {
        if (prevProps.data === null && !!this.props.data) {
            const initData = this.initialData(this.props.data)
            this.setState({
                taskData: cloneDeep(initData),
                oldTaskData: cloneDeep(initData),
                isSubmitting: false,
                errors: []
            })
        }
    }

    onChangeTaskData = (key, value, checkBoxKey = "") => {
        const {taskData} = this.state
        const newTaskData = {
            ...taskData
        }
        if (checkBoxKey) {
            const checkBoxKeyIndex = findIndex(newTaskData[checkBoxKey], (option) => option.key === key)
            if (checkBoxKeyIndex !== -1) {
                newTaskData[checkBoxKey][checkBoxKeyIndex].value = value
            }
        } else {
            newTaskData[key] = value
        }
        this.setState({taskData: newTaskData})
    }

    onClickSubmitBtn = () => {
        const {taskData} = this.state
        const errors = this.validateDataBeforeSubmit()
        if (errors) {
            this.setState({errors})
            return
        }
        this.setState({errors: {}})
        if (taskData.id) this.onUpdateTask()
        else this.onCreateTask()
    }

    validateDataBeforeSubmit = () => {
        let errors: any = {}
        const {taskData, filteredUsers} = this.state
        const {library} = this.props
        if (!taskData.name) errors.name = true
        if (!taskData.dueDate) errors.dueDate = true
        if (!taskData.description) errors.description = true
        if (!taskData.category) errors.category = true
        if (!library) {
            if (!filteredUsers.length) {
                if (!taskData.assigners.length) errors.assigners = true
            }
            if (!taskData.fileName) errors.fileName = true
        }
        return isEmpty(errors) ? null : errors
    }

    onCreateTask = async () => {
        const {taskData, filteredUsers} = this.state
        const {subtype, library} = this.props
        taskData.categoryID = taskData.category ? taskData.category.id : undefined
        const submitData = pick(taskData, [
            "name",
            "parentId",
            "description",
            "type",
            "dueDate",
            "categoryID",
            "autoApprove"
        ])
        if (taskData.dueDate) {
            submitData.dueDate = moment(taskData.dueDate)
                .format("YYYY-MM-DD")
                .toString()
        }
        submitData.subtype = subtype
        submitData.filename = taskData.fileName
        let studentIds = []
        if (taskData.assigners.length) {
            studentIds = taskData.assigners.map((assigner) => assigner.id)
        } else {
            studentIds = filteredUsers.map((user) => user.id)
        }
        try {
            this.setState({isSubmitting: true})
            const newTask = await taskService.createTask(submitData)
            message.success("Create task successful")
            if (!library) {
                await taskService.assignTask({taskId: newTask.id, studentIds})
            }
            this.setState({isSubmitting: false}, () => {
                this.props.onSuccess()
            })
        } catch (error) {
            message.error("Create task failure")
        } finally {
            this.setState({isSubmitting: false})
        }
    }

    onUpdateTask = async () => {
        const {taskData, oldTaskData} = this.state
        const attrs = ["name", "description", "type", "dueDate", "autoApprove"]
        const oldData = pick(oldTaskData, attrs)
        const newData = pick(taskData, attrs)
        const dataSubmit = getValuesChangeTwoObj(oldData, newData)
        if (!dataSubmit) {
            this.props.onClose()
            return
        }
        try {
            this.setState({isSubmitting: true})
            await taskService.updateTask(taskData.id, dataSubmit)
            message.success("Update task successful")
            this.setState({isSubmitting: false}, () => {
                this.props.onSuccess()
            })
        } catch (error) {
            message.error("Update task failure")
        }
    }

    onChangeFilteredUsers = async (users) => {
        this.setState({filteredUsers: users})
    }

    render() {
        const {taskData, isSubmitting, errors} = this.state
        const {model, library} = this.props

        return (
            <>
                <TaskHeaderSettingBody
                    data={taskData}
                    onChangeTaskData={this.onChangeTaskData}
                    model={model}
                    errors={errors}
                    library={library}
                />
                <Divider className={styles.divider} />
                {!library && (
                    <ConfigureTaskFilter
                        model={model}
                        data={taskData}
                        errors={errors}
                        onChangeTaskData={this.onChangeTaskData}
                        onChangeFilteredUsers={this.onChangeFilteredUsers}
                    />
                )}
                <div className={styles.footerWrap}>
                    <div className={classNames(styles.footerBtnWrap, styles.saveBtnWrap)}>
                        <BaseButton
                            title={library ? "CREATE" : "SEND"}
                            loading={isSubmitting}
                            onClick={this.onClickSubmitBtn}
                        />
                    </div>
                </div>
            </>
        )
    }
}
