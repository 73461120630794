import React from "react"
import PropTypes from "prop-types"
import {Icon} from "components/Icons"

export default function StatusIconItem(props) {
    const {data} = props
    if (data.statusType === "SENT") {
        return <Icon icon="PAPER_PLANE" />
    }

    if (data.statusType === "OVERDUE") {
        return <Icon icon="WARNING_TRIANGLE" />
    }

    return <Icon icon="CHECKED" />
}

StatusIconItem.propTypes = {
    data: PropTypes.object
}
