import React from "react"
import {observer} from "mobx-react"
import {Checkbox, Tooltip} from "antd"
import classNames from "classnames"
import moment from "moment"
import {Icon} from "components/Icons"
import ChildRows from "./ChildRows"
import ExpandRowItem from "./ExpandRowItem"
import styles from "../ResponsesTable.module.css"
import {STATUS} from "constant"

interface Props {
    subtask: any
    data: any
    isActive: any
    onCheckedItem: Function
    resend: Function
    model: any
    onClickRowItem: Function
}

const TableRowItem = observer((props: Props) => {
    const {subtask, onCheckedItem, data, resend, onClickRowItem, isActive, model} = props
    const {dateFormat} = model
    const [open, setOpen] = React.useState(false)
    const [amINextSigner, setSmINextSigner] = React.useState(false)
    const [completeUrl, setCompleteUrl] = React.useState("")

    let taskOpen = false
    let amIInTheTask = false
    let didISigned = false
    for (let i = 0; i < subtask.users.length; i++) {
        const user = subtask.users[i]
        if (model.user.id === user.userId) {
            amIInTheTask = true
            didISigned = user.status !== "open"
        }
        if (model.user && !amINextSigner && !taskOpen && user.status === "open") {
            taskOpen = true
            if (model.user.id === user.userId) {
                setSmINextSigner(true)
                setCompleteUrl(user.url)
            }
        }
    }
    const handleOpenTask = (e) => {
        e.stopPropagation()
        window.top.location.href = completeUrl
    }

    const hasFile = !!(subtask.files && subtask.files[0])
    const hasCursorClassName = hasFile && subtask.users

    return (
        <>
            <tr
                key={subtask.id}
                className={`${styles.tableRow} ${isActive ? styles.tableRowActive : ""} ${amIInTheTask &&
                    !didISigned &&
                    styles.highlight_row}`}>
                <td className={`${styles.tableCell} ${styles.expandIconCell} ${hasCursorClassName ? "pointer" : ""}`}>
                    <ExpandRowItem data={subtask} open={open} setOpen={setOpen} />
                </td>
                <td
                    className={classNames(styles.tableCell, hasCursorClassName ? "pointer" : "")}
                    onClick={() => onClickRowItem(subtask.id)}>
                    <span>
                        {subtask.assigned.first_name} {subtask.assigned.last_name}
                    </span>
                    {amINextSigner && (
                        <a className={styles.openTask} href="#" onClick={handleOpenTask}>
                            Open task
                        </a>
                    )}
                </td>
                <td
                    className={classNames(styles.tableCell, hasCursorClassName ? "pointer" : "")}
                    onClick={() => onClickRowItem(subtask.id)}>
                    {subtask.assigned.roles}
                </td>
                <td
                    className={classNames(styles.tableCell, hasCursorClassName ? "pointer" : "")}
                    onClick={() => onClickRowItem(subtask.id)}>
                    {subtask.assigned.email}
                </td>
                <td
                    className={classNames(styles.tableCell, hasCursorClassName ? "pointer" : "")}
                    onClick={() => onClickRowItem(subtask.id)}>
                    {subtask.status === "submitted" ? moment(subtask.updatedAt).format(dateFormat) : "N/A"}
                </td>
                <td
                    className={classNames(styles.tableCell, hasCursorClassName ? "pointer" : "")}
                    onClick={() => onClickRowItem(subtask.id)}>
                    {STATUS[subtask.status]}
                    {subtask.status === "rejected" && (
                        <Tooltip title={subtask.reason}>
                            <span className={styles.rejectIconWrap}>
                                <Icon icon="INFO" className={styles.rejectIcon} color="#df1642" />
                            </span>
                        </Tooltip>
                    )}
                </td>
                <td className={styles.tableCell}>
                    {(subtask.status === "submitted" || subtask.status === "open") && (
                        <Checkbox
                            checked={data[subtask.id]}
                            onChange={(event) => onCheckedItem(subtask.id, event.target.checked)}
                        />
                    )}
                </td>
            </tr>
            <ChildRows childRows={subtask.users} open={open} resend={resend} model={model} />
        </>
    )
})
export default TableRowItem
