import React from "react"
import {useHistory} from "react-router-dom"
import * as QueryString from "query-string"
import {TableRowItem, EmptyRow, LoadingRow} from "./Table"
import styles from "./OpenTable.module.css"

function BodyTable(props) {
    const {tableData, isLoading, ...rest} = props
    if (isLoading) return <LoadingRow colSpan={8} />
    if (!tableData.length) return <EmptyRow colSpan={8} />
    return tableData.map((item) => <TableRowItem key={item.taskId} data={item} {...rest} />)
}

export default function OpenTable(props) {
    const history = useHistory()
    const {onClickShowDetail, isLoading, onDeleteTaskSuccess, data, fields, model} = props

    const onClickRowItem = (data) => {
        const searchParams: any = QueryString.parse(history.location.search)
        const token = (searchParams && searchParams.token) || ""
        history.push(`/go-klass/${data.taskId}?token=${token}`, {data})
    }

    return (
        <div className={styles.wrapper}>
            <table className={styles.table}>
                <thead className={styles.tableHead}>
                    <tr className={styles.tableRowHeader}>
                        {fields.includes("status") && (
                            <th
                                className={`${styles.tableCell} ${styles.tableCellHeader} ${styles.tableCellCenter}`}
                                style={{width: "150px"}}>
                                Status
                            </th>
                        )}
                        {fields.includes("name") && (
                            <th className={`${styles.tableCell} ${styles.tableCellHeader}`}>Task name</th>
                        )}
                        {fields.includes("description") && (
                            <th className={`${styles.tableCell} ${styles.tableCellHeader}`}>Description</th>
                        )}
                        {fields.includes("department") && (
                            <th className={`${styles.tableCell} ${styles.tableCellHeader}`}>Department</th>
                        )}
                        {fields.includes("created") && (
                            <th className={`${styles.tableCell} ${styles.tableCellHeader}`}>Created</th>
                        )}
                        {fields.includes("dueDate") && (
                            <th className={`${styles.tableCell} ${styles.tableCellHeader}`}>Due date</th>
                        )}
                        {fields.includes("progress") && (
                            <th className={`${styles.tableCell} ${styles.tableCellHeader}`}>Progress</th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    <BodyTable
                        tableData={data}
                        isLoading={isLoading}
                        onClickShowDetail={onClickShowDetail}
                        onClickRowItem={onClickRowItem}
                        onDeleteTaskSuccess={onDeleteTaskSuccess}
                        fields={fields}
                        model={model}
                    />
                </tbody>
            </table>
        </div>
    )
}
