import React, {useState, useCallback} from "react"
import {Tabs, Button, message} from "antd"
import {useHistory} from "react-router-dom"
import * as QueryString from "query-string"
import moment from "moment"
import {useDropzone} from "react-dropzone"
import {Icon} from "components/Icons"
import {BaseButton} from "components/Button"
import {BaseLoading} from "components/Loading"
import {taskService} from "services"
import {FileItem} from "./FileItem"
import styles from "../detail.module.css"

const {TabPane} = Tabs

export function ScanItem(props) {
    const history = useHistory()
    let [files, setFiles] = useState([])
    let [isLoading, setIsLoading] = useState(false)
    let [isUploading, setIsUploading] = useState(false)

    const onClickBack = () => {
        const searchParams: any = QueryString.parse(history.location.search)
        const token = (searchParams && searchParams.token) || ""
        history.push(`/go-klass?token=${token}`)
    }

    const uploadFile = async (file) => {
        try {
            setIsUploading(true)
            let data = new FormData()
            data.append("file", file)
            const fileUrl = await taskService.upload(data)
            files.push({image: fileUrl})
            setFiles([...files])
        } catch (error) {
            console.log("error", error)
        } finally {
            setIsUploading(false)
        }
    }

    const onDrop = useCallback(async (acceptedFiles) => {
        const file = acceptedFiles[0]
        uploadFile(file)
    }, [])

    const {getRootProps, getInputProps} = useDropzone({onDrop})

    const onClickRemoveFileItem = (index) => {
        files.splice(index, 1)
        files = [...files]
        setFiles(files)
    }

    const onSubmitForm = async () => {
        if (!files.length) {
            message.warning("Please choose at least one file")
            return
        }
        try {
            setIsLoading(true)
            const {taskUserId} = props
            await taskService.answerScan(taskUserId, {response: files})
            message.success("Success")
            onClickBack()
        } catch (error) {
            console.log(error)
            message.error("Error")
        } finally {
            setIsLoading(false)
        }
    }

    const {data, model} = props
    const {dateFormat} = model

    return (
        <div className={styles.itemWrap}>
            <BaseLoading isShow={isUploading} />
            <div className={styles.titleWrap}>
                <Button className={styles.backIconBtn} onClick={onClickBack}>
                    <Icon className={styles.backIcon} icon="BACK" />
                </Button>
                <p className={`${styles.title} ${styles.headerTitle}`}>Scan / File Upload Task</p>
            </div>
            <Tabs className={`fullwidth ${styles.tabsWrapper}`} defaultActiveKey="1">
                <TabPane
                    className={styles.tabItem}
                    tab={
                        <span>
                            <Icon className={styles.tabIcon} icon="FILE" />
                            <span className={styles.tabTitle}>Scan / File Upload Task</span>
                        </span>
                    }
                    key="1">
                    <div className={styles.bodyWrap}>
                        <p className={styles.title}>Photo ID Documents</p>
                        <p className={styles.description}>Upload files to complete this task</p>
                        <div className={styles.bodyInfoWrap}>
                            <div className={styles.bodyInfoItem} style={{minWidth: 110}}>
                                <p className={styles.bodyInfoItem__title}>Requested:</p>
                                <p className={styles.bodyInfoItem__value}>
                                    {data.createdAt ? moment(data.createdAt).format(dateFormat) : ""}
                                </p>
                            </div>
                            <div className={styles.bodyInfoItem} style={{minWidth: 110}}>
                                <p className={styles.bodyInfoItem__title}>Due date:</p>
                                <p className={styles.bodyInfoItem__value}>
                                    {data.dueDate ? moment(data.dueDate).format(dateFormat) : ""}
                                </p>
                            </div>
                            <div className={styles.bodyInfoItem} style={{minWidth: 50}}>
                                <p className={styles.bodyInfoItem__title}>Status:</p>
                                <p className={styles.bodyInfoItem__value}>{data.status}</p>
                            </div>
                            <div className={styles.bodyInfoItem} style={{minWidth: 140}}>
                                <p className={styles.bodyInfoItem__title}>Type:</p>
                                <p className={styles.bodyInfoItem__value}>{data.type}</p>
                            </div>
                            <div className={styles.bodyInfoItem}>
                                <p className={styles.bodyInfoItem__title}>Description:</p>
                                <p className={styles.bodyInfoItem__value}>{data.description}</p>
                            </div>
                        </div>
                        <div className={styles.actionWrap}>
                            {/* <div className={styles.leftActionWrap}>
                                <Button className={styles.leftActionBtn}>Cancel</Button>
                                <Button className={styles.leftActionBtn}>Save</Button>
                            </div> */}
                            <div className={styles.rightActionWrap}>
                                <BaseButton title="Submit" onClick={onSubmitForm} loading={isLoading} />
                            </div>
                        </div>
                    </div>
                    <div className={styles.formBodyWrap}>
                        <div className={styles.dropzoneWrap}>
                            <div {...getRootProps()} className={styles.dropzoneItem}>
                                <input {...getInputProps()} />
                                <p className={styles.dropzone__text}>
                                    Drag &amp; drop a PDF, JPEG or PNG file into this box or
                                </p>
                                <Button className={styles.dropzone__btn}>Browse to upload</Button>
                            </div>
                        </div>
                        <div className={styles.listFiles}>
                            {files.map((fileUrl, index) => (
                                <FileItem
                                    key={index}
                                    fileUrl={fileUrl.image}
                                    index={index}
                                    onClickRemoveFileItem={onClickRemoveFileItem}
                                />
                            ))}
                        </div>
                    </div>
                </TabPane>
            </Tabs>
        </div>
    )
}
