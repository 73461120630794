import React from "react"
import {Divider, message} from "antd"
import Model from "Model"
import {taskService} from "services"
import {BaseButton} from "components/Button"
import {Icon} from "components/Icons"
import {TableRowItem} from "./Table"
import {DocumentTask} from "./items"
import styles from "./LibraryTable.module.css"
import {SortHeader} from "components/SortHeader"

interface Props {
    onClose: Function
    model: Model
}

export default class LibraryTable extends React.Component<Props, {}> {
    state = {
        isShowDocumentTask: false,
        activeItem: null,
        filterData: {
            taskType: "",
            department: ""
        },
        data: [],
        total: 0,
        taskTypes: [
            {id: "type1", name: "Type 1"},
            {id: "type2", name: "Type 2"}
        ],
        departments: [
            {id: "department1", name: "Department 1"},
            {id: "department2", name: "Department 2"}
        ],
        isShowSearchInput: false,
        sortBy: "name",
        sortValue: 0
    }
    componentDidMount() {
        this.loadLibraryTasks().catch(console.log)
    }
    loadLibraryTasks = async () => {
        try {
            const {data, total} = await taskService.getListAssignedTasks({subtype: "library"})
            this.setState({data, total})
        } catch (e) {
            message.error("Can't get task list")
        }
    }

    onClickShowDetail = (item) => {
        this.onClickNextBtn(item.id)
    }

    onClickNextBtn = async (taskId) => {
        const task = await taskService.getTaskDetail(taskId)
        if (task.parentId === null) {
            task.parentId = task.id
            delete task.id
        }
        this.props.model.libraryTask = task
    }

    onCloseDocumentTask = () => {
        this.setState({isShowDocumentTask: false})
    }

    setSortValue = (sortValue) => {
        this.setState({sortValue})
    }

    setSortBy = (sortBy) => {
        this.setState({sortBy})
    }

    onSortClick = (sortBy) => {
        return (sortValue) => {
            this.setSortValue(sortValue)
            this.setSortBy(sortBy)
        }
    }
    sort = (a, b) => {
        if (this.state.sortBy === "name") {
            return a.name.localeCompare(b.name) * this.state.sortValue
        }
    }

    onClickSendBtn = () => {
        this.setState({isShowDocumentTask: false})
        this.props.onClose()
    }

    onChangeFilter = (key, value) => {
        const {filterData} = this.state
        filterData[key] = value
        this.setState({filterData})
    }

    onClearFilter = () => {
        const filterData = {
            taskType: "",
            department: ""
        }
        this.setState({filterData})
    }

    onClickSearchIcon = () => {
        this.setState({isShowSearchInput: true})
    }

    onCloseSearch = () => {
        this.setState({isShowSearchInput: false})
    }

    onSearch = (event) => {
        const value = event.target.value || ""
    }

    render() {
        const {isShowDocumentTask, activeItem, taskTypes, departments, filterData, isShowSearchInput} = this.state
        const {taskType, department} = filterData
        const {model} = this.props

        return (
            <div className={styles.wrapper}>
                {/*
                <div className={styles.filterWrap}>
                    <Row>
                        <Col span={6}>
                            <KlassDropdown
                                onChange={(selectedOption) => this.onChangeFilter("taskType", selectedOption)}
                                options={taskTypes}
                                value={taskType}
                                placeholder="Task Type"
                                className={styles.colFilter}
                            />
                        </Col>
                        <Col span={6}>
                            <KlassDropdown
                                onChange={(selectedOption) => this.onChangeFilter("department", selectedOption)}
                                options={departments}
                                value={department}
                                placeholder="Department"
                                className={styles.colFilter}
                            />
                        </Col>
                        <Col span={6} className={styles.clearFilterBtnWrap}>
                            <Button onClick={this.onClearFilter} className={styles.clearFilterBtn}>
                                Clear Filter
                            </Button>
                        </Col>
                    </Row>
                </div>
                */}
                <div className={styles.tableSearchWrap}>
                    {/*
                            <div className={styles.searchWrap}>
                                {isShowSearchInput ? (
                                    <Input
                                        className={styles.searchInputWrap}
                                        suffix={
                                            <Button className={styles.searchIconBtn} onClick={this.onCloseSearch}>
                                                <Icon className={styles.closeIcon} icon="CLOSE" color="#d0d0d0" />
                                            </Button>
                                        }
                                        placeholder="Search"
                                        onPressEnter={this.onSearch}
                                    />
                                ) : (
                                    <Button className={styles.searchIconBtn} onClick={this.onClickSearchIcon}>
                                        <Icon className={styles.searchIcon} icon="SEARCH" color="#d0d0d0" />
                                    </Button>
                                )}
                            </div>
                        */}
                    <div className={styles.tableWrap}>
                        <table className={styles.table}>
                            <thead className={styles.tableHead}>
                                <tr className={styles.tableRowHeader}>
                                    <SortHeader
                                        title={"Task name"}
                                        onClick={this.onSortClick("name")}
                                        className={`${styles.tableCell} ${styles.tableCellHeader}`}
                                    />
                                    <th className={`${styles.tableCell} ${styles.tableCellHeader}`}>Type</th>
                                    {/*
                                    <th className={`${styles.tableCell} ${styles.tableCellHeader}`}>Department</th>
                                     */}
                                    <th className={`${styles.tableCell} ${styles.tableCellHeader}`}>Assigned to</th>
                                    {/*
                                    <th className={`${styles.tableCell} ${styles.tableCellHeader}`}>Preview</th>
                                     */}
                                    <th className={`${styles.tableCell} ${styles.tableCellHeader}`}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.data.sort(this.sort).map((item) => (
                                    <TableRowItem
                                        key={item.id}
                                        data={item}
                                        activeItem={activeItem}
                                        onClickShowDetail={this.onClickShowDetail}
                                    />
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <Divider className={styles.divider} />
                {activeItem && (
                    <div>
                        <div className={styles.fileEmpty}>
                            <Icon icon="FILE_EMPTY" />
                            <p>There is no preview available for this type of task</p>
                        </div>
                        <div className={styles.nextBtnWrap}>
                            <BaseButton title="Next" onClick={this.onClickNextBtn} />
                        </div>
                    </div>
                )}
                <DocumentTask
                    isShow={isShowDocumentTask}
                    onClose={this.onCloseDocumentTask}
                    onClickSendBtn={this.onClickSendBtn}
                    model={model}
                />
            </div>
        )
    }
}
