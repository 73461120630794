import React from "react"
import {Row, Col, Button} from "antd"
import moment from "moment"
import Model from "Model"
import {Icon} from "components/Icons"
import {BasePopup} from "components/popup"
import {BaseInput} from "components/Input"
import {BaseDatePicker} from "components/DatePicker"
import {KlassDropdown} from "components/KlassDropdown"
import {BaseButton} from "components/Button"
import {ConfigureTaskFilter} from "./filter"
import styles from "./DocumentTask.module.css"

interface Props {
    isShow: boolean
    onClose: Function
    onClickSendBtn: Function
    model: Model
}

export default class DocumentTask extends React.Component<Props, {}> {
    state = {
        statuses: [
            {id: "type1", name: "Status 1"},
            {id: "type2", name: "Status 2"}
        ],
        categories: [
            {id: "category1", name: "Category 1"},
            {id: "category2", name: "Category 2"}
        ],
        members: [
            {id: "member1", name: "Member 1"},
            {id: "member2", name: "Member 2"}
        ],
        reminderOptions: [
            {id: "1", name: "On due date"},
            {id: "2", name: "2 days before"},
            {id: "7", name: "7 days before"},
            {id: "30", name: "Daily for 30 days after"}
        ],
        data: {
            title: "",
            description: "",
            member: null,
            dueDate: "",
            status: null,
            category: null,
            reminders: []
        }
    }

    onChangeTaskData = (key, value) => {
        const {data} = this.state
        data[key] = value
        this.setState({data})
    }

    onClickBackIcon = () => {
        this.props.onClose()
    }

    onClickSendBtn = () => {
        this.props.onClickSendBtn()
    }

    render() {
        const {statuses, categories, members, reminderOptions, data} = this.state
        const {title, member, dueDate, status, category, reminders} = data
        const {isShow, onClose, model} = this.props
        const {dateFormat} = model

        return (
            <BasePopup isShow={isShow} onClose={onClose} leftIcon="LIBRARY" leftIconColor="#fff">
                <div className={styles.titleWrap}>
                    <Button className={styles.backIconBtn} onClick={this.onClickBackIcon}>
                        <Icon className={styles.backIcon} icon="BACK" />
                    </Button>
                    <p className={styles.title}>Photo ID Documents Task</p>
                </div>
                <div className={styles.tabWrapper}>
                    <Icon className={styles.tabIcon} icon="CHECK_LIST" />
                    <span className={styles.tabTitle}>Individual Tasks</span>
                </div>
                <Row className={styles.rowItem}>
                    <Col span={8}>
                        <div className={styles.itemBodyWrap}>
                            <p className={styles.bodyTitle}>
                                Title <span className="required-star">*</span>
                            </p>
                            <BaseInput
                                defaultValue={title}
                                onChangeValue={(newValue) => this.onChangeTaskData("title", newValue)}
                                placeholder="Title"
                            />
                        </div>
                        <div className={styles.itemBodyWrap}>
                            <p className={styles.bodyTitle}>
                                Description <span className="required-star">*</span>
                            </p>
                            <BaseInput
                                onChangeValue={(newValue) => this.onChangeTaskData("description", newValue)}
                                placeholder="Basic usage"
                            />
                        </div>
                        <div className={styles.itemBodyWrap}>
                            <p className={styles.bodyTitle}>Additional Approval Access</p>
                            <KlassDropdown
                                onChange={(selectedOption) => this.onChangeTaskData("member", selectedOption)}
                                options={members}
                                value={member}
                                placeholder="Select..."
                            />
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className={styles.itemBodyWrap}>
                            <p className={styles.bodyTitle}>
                                Due Date <span className="required-star">*</span>
                            </p>
                            <BaseDatePicker
                                defaultValue={dueDate}
                                onChange={(newValue) => this.onChangeTaskData("dueDate", newValue)}
                                format={dateFormat}
                                disabledDate={(current) => moment(current).isBefore(moment().subtract(1, "d"))}
                            />
                        </div>
                        <div className={styles.itemBodyWrap}>
                            <p className={styles.bodyTitle}>On completion set Status to</p>
                            <KlassDropdown
                                onChange={(selectedOption) => this.onChangeTaskData("status", selectedOption)}
                                options={statuses}
                                value={status}
                                placeholder="Select..."
                            />
                        </div>
                        <div className={styles.itemBodyWrap}>
                            <p className={styles.bodyTitle}>Document category</p>
                            <KlassDropdown
                                onChange={(selectedOption) => this.onChangeTaskData("category", selectedOption)}
                                options={categories}
                                value={category}
                                placeholder="Select..."
                            />
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className={styles.itemBodyWrap}>
                            <p className={styles.bodyTitle}>Reminder options</p>
                            <KlassDropdown
                                onChange={(selectedOption) => this.onChangeTaskData("reminders", selectedOption)}
                                options={reminderOptions}
                                value={reminders}
                                placeholder="Select..."
                                isMulti
                            />
                        </div>
                    </Col>
                </Row>
                <ConfigureTaskFilter model={model} />
                <div className={styles.sendBtn}>
                    <BaseButton title="SEND" onClick={this.onClickSendBtn} />
                </div>
            </BasePopup>
        )
    }
}
