import React from "react"
import {message, Tabs} from "antd"
import * as QueryString from "query-string"
import {observer} from "mobx-react"
import {Icon} from "components/Icons"
import {BaseSearchInput} from "components/Input"
import {BaseLoading} from "components/Loading"
import {BasePagination} from "components/Pagination"
import {taskService} from "services"
import {AssignedTab, LibraryTab} from "./items/Tab"
import TaskCard from "./items/TaskCard"
import Model from "Model"
import styles from "./task.module.css"

const {TabPane} = Tabs

interface Props {
    model: Model
    history: any
}

@observer
export default class Tasks extends React.Component<Props, {}> {
    state = {
        isLoading: false,
        isLoadingTask: false,
        numberTaskPerPage: 20,
        page: 1,
        data: [],
        total: 0,
        searchData: "",
        filteredStatus: "",
        subType: "",
        tabKeyByIndex: {
            1: "assigned",
            2: "trigger",
            3: "ondemand",
            4: "library"
        }
    }
    componentDidUpdate(prevProps) {
        if (
            this.props.model.activeTabKey !== prevProps.model.activeTabKey ||
            this.state.subType !== this.state.tabKeyByIndex[this.props.model.activeTabKey]
        ) {
            this.onChangeTab(this.props.model.activeTabKey)
        }
    }

    async componentDidMount() {
        this.getDashboardData()
        const {numberTaskPerPage, page} = this.state
        const {location} = this.props.history
        const searchParams: any = QueryString.parse(location.search)
        let params = {limit: numberTaskPerPage, offset: (page - 1) * numberTaskPerPage}
        const dataState: any = {}
        if (searchParams) {
            const {token, ...rest} = searchParams
            params = {...params, ...rest}
            if (searchParams.page) {
                const searchParamsPage = parseInt(searchParams.page, 10)
                params.offset = (searchParamsPage - 1) * numberTaskPerPage
            }
            // if (searchParams.limit) {
            //     this.setState({numberTaskPerPage: searchParams.limit})
            // }
            if (searchParams.search) {
                dataState.searchData = searchParams.search || ""
            }
            if (searchParams.filter) {
                dataState.filteredStatus = searchParams.filter
            }
        }
        this.setState({...dataState}, () => {
            this.getData(params)
        })

        try {
            this.setState({isLoading: true})
            await Promise.all([
                this.getPrograms(),
                this.getForms(),
                this.getDocumentCategories(),
                this.getCampuses(),
                this.getGroups(),
                this.getStudentStatuses()
            ])
        } catch (e) {
            console.log("e")
        } finally {
            this.setState({isLoading: false})
        }
    }

    async getData(params) {
        try {
            this.setState({isLoadingTask: true})
            const {subType, searchData, filteredStatus} = this.state
            if (subType) {
                params.subtype = subType
            }
            params.search = searchData
            params.filter = filteredStatus
            const {data, total} = await taskService.getListAssignedTasks(params)
            this.setState({data, total})
        } catch (e) {
            message.error("Can't get task list")
        } finally {
            this.setState({isLoadingTask: false})
        }
    }

    async getDashboardData() {
        const {model} = this.props
        await model.loadDashboardData()
    }

    async getForms(params = {}) {
        const {model} = this.props
        await model.loadForms({limit: 20})
    }

    async getPrograms() {
        const {model} = this.props
        await model.loadPrograms({limit: 20})
    }

    async getDocumentCategories() {
        const {model} = this.props
        await model.loadDocumentCategories({limit: 20})
    }

    async getCampuses() {
        const {model} = this.props
        await model.loadCampuses({limit: 20})
    }

    async getGroups() {
        const {model} = this.props
        await model.loadGroups({limit: 1000})
    }

    async getStudentStatuses() {
        const {model} = this.props
        await model.loadStudentStatus({limit: 20})
    }

    onChangePage = async (page) => {
        const {numberTaskPerPage} = this.state
        const params = {limit: numberTaskPerPage, offset: (page - 1) * numberTaskPerPage}
        this.setState({page})
        await this.getData(params)
    }

    onReGetListTasks = async () => {
        const {numberTaskPerPage, page} = this.state
        const params = {limit: numberTaskPerPage, offset: (page - 1) * numberTaskPerPage}
        await this.getData(params)
    }

    onSearch = async (value) => {
        const {numberTaskPerPage, page} = this.state
        const {history} = this.props
        const {location} = history
        const searchParams = QueryString.parse(location.search)
        searchParams.search = value
        location.search = this.getQueryStringFromSearchParams(searchParams)
        history.replace({...location})
        const params = {limit: numberTaskPerPage, offset: (page - 1) * numberTaskPerPage}
        await this.getData(params)
    }

    onChangeSearch = (event) => {
        this.setState({searchData: event.target.value})
    }

    onChangeTab = (activeKey) => {
        const {tabKeyByIndex, numberTaskPerPage} = this.state
        this.props.model.activeTabKey = activeKey
        this.setState({page: 1, subType: tabKeyByIndex[activeKey], data: []}, () => {
            const params = {limit: numberTaskPerPage, offset: 0}
            this.getData(params)
        })
    }

    onFilterTaskByStatus = async (status) => {
        const {numberTaskPerPage, page} = this.state
        const dataState: any = {filteredStatus: status}
        if (this.props.model.activeTabKey !== "1") {
            this.props.model.activeTabKey = "1"
            dataState.subType = ""
        }
        const {history} = this.props
        const {location} = history
        const searchParams = QueryString.parse(location.search)
        searchParams.filter = status
        location.search = this.getQueryStringFromSearchParams(searchParams)
        history.replace({...location})
        const params = {limit: numberTaskPerPage, offset: (page - 1) * numberTaskPerPage}
        this.setState({...dataState}, () => {
            this.getData(params)
        })
    }

    getQueryStringFromSearchParams = (searchParams) => {
        let queryString = ""
        const searchParamOfKeys = Object.keys(searchParams)
        searchParamOfKeys.forEach((key, index) => {
            queryString += `${key}=${searchParams[key]}`
            if (index !== searchParamOfKeys.length - 1) {
                queryString += "&"
            }
        })
        return queryString
    }

    render() {
        const {isLoading, isLoadingTask, data, total, page, numberTaskPerPage, searchData} = this.state
        const {model} = this.props
        const {activeTabKey} = model
        const {dashboardData} = model

        return (
            <div className={styles.wrapper}>
                <BaseLoading isShow={isLoading} />
                <div className={styles.tasksHeaderWrap}>
                    <p className={styles.taskHeaderTitle}>Tasks</p>
                    <div>
                        <BaseSearchInput
                            onSearch={this.onSearch}
                            placeholder="Enter task name"
                            defaultValue={searchData}
                            value={searchData}
                            onChange={this.onChangeSearch}
                        />
                    </div>
                </div>
                <div className={styles.tasksCardContainer}>
                    <div className={styles.tasksCardItemWrap}>
                        <TaskCard
                            number={dashboardData.all}
                            status="All"
                            desc="Every task regardless of its status"
                            onClick={() => this.onFilterTaskByStatus("all")}
                        />
                    </div>
                    <div className={styles.tasksCardItemWrap}>
                        <TaskCard
                            number={dashboardData.overdue}
                            status="Overdue"
                            desc="Tasks which have passed their specified due date"
                            onClick={() => this.onFilterTaskByStatus("overdue")}
                        />
                    </div>
                    <div className={styles.tasksCardItemWrap}>
                        <TaskCard
                            number={dashboardData.submitted}
                            status="Awaiting Approval"
                            desc="All tasks that have been submitted and are awaiting approval"
                            onClick={() => this.onFilterTaskByStatus("submitted")}
                        />
                    </div>
                    <div className={styles.tasksCardItemWrap}>
                        <TaskCard
                            number={dashboardData.submittedWaitingForMe}
                            status="My Tasks"
                            desc="Tasks that have been submitted and are awaiting approval by me"
                            onClick={() => this.onFilterTaskByStatus("myTasks")}
                        />
                    </div>
                    <div className={styles.tasksCardItemWrap}>
                        <TaskCard
                            number={dashboardData.completed}
                            status="Completed"
                            desc="All tasks that have been submitted and approved"
                            onClick={() => this.onFilterTaskByStatus("completed")}
                        />
                    </div>
                </div>
                <Tabs className="fullwidth" activeKey={activeTabKey} onChange={this.onChangeTab}>
                    <TabPane
                        className={styles.tabItem}
                        tab={
                            <span>
                                <Icon className={styles.tabIcon} icon="PEOPLE" />
                                <span className={styles.tabTitle}>Assigned</span>
                            </span>
                        }
                        key="1">
                        <AssignedTab
                            model={model}
                            isLoading={isLoading}
                            isLoadingTask={isLoadingTask}
                            data={data}
                            onReGetListTasks={this.onReGetListTasks}
                        />
                        <div className={styles.paginationWrap}>
                            <BasePagination
                                page={page}
                                total={total}
                                onChangePage={this.onChangePage}
                                defaultPageSize={numberTaskPerPage}
                            />
                        </div>
                    </TabPane>
                    {/*              <TabPane
                        className={styles.tabItem}
                        tab={
                            <span>
                                <Icon className={styles.tabIcon} icon="BAR_CHART" />
                                <span className={styles.tabTitle}>Trigger</span>
                            </span>
                        }
                        key="2">
                        <TriggerTab model={model} />
                    </TabPane>
                    <TabPane
                        className={styles.tabItem}
                        tab={
                            <span>
                                <Icon className={styles.tabIcon} icon="PERSON" />
                                <span className={styles.tabTitle}>On-Demand</span>
                            </span>
                        }
                        key="3">
                        <OnDemandTab model={model} />
                    </TabPane>
                                        <TabPane
                        className={styles.tabItem}
                        tab={
                            <span>
                                <Icon className={styles.tabIcon} icon="SETTINGS" />
                                <span className={styles.tabTitle}>Settings</span>
                            </span>
                        }
                        key="5">
                        Setting Tab
                    </TabPane>
                */}

                    <TabPane
                        className={styles.tabItem}
                        tab={
                            <span>
                                <Icon className={styles.tabIcon} icon="LIBRARY" />
                                <span className={styles.tabTitle}>Library</span>
                            </span>
                        }
                        key="4">
                        <LibraryTab
                            model={model}
                            isLoading={isLoading}
                            isLoadingTask={isLoadingTask}
                            data={data}
                            onReGetListTasks={this.onReGetListTasks}
                        />
                        <div className={styles.paginationWrap}>
                            <BasePagination
                                page={page}
                                total={total}
                                onChangePage={this.onChangePage}
                                defaultPageSize={numberTaskPerPage}
                            />
                        </div>
                    </TabPane>
                </Tabs>
            </div>
        )
    }
}
