import React from "react"
import PropTypes from "prop-types"
import styles from "../AllTable.module.css"

export default function EmptyRow(props) {
    const {colSpan} = props

    return (
        <tr className={styles.tableRow}>
            <td colSpan={colSpan}>
                <p className={styles.emptyRowData}>Empty Data</p>
            </td>
        </tr>
    )
}

EmptyRow.propTypes = {
    colSpan: PropTypes.number
}
