import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import ActionRowItem from "./ActionRowItem"
import {TASK_TYPE} from "constant"
import styles from "../LibraryTable.module.css"

export default function TableRowItem(props) {
    const {data, onClickShowDetail, activeItem} = props
    const isActive = activeItem && data.id === activeItem.id

    return (
        <tr
            key={data.id}
            className={classNames(styles.tableRow, isActive ? styles.tableRowActive : "")}
            onClick={() => onClickShowDetail(data)}>
            <td className={`${styles.tableCell}`}>{data.name}</td>
            <td className={styles.tableCell}>{TASK_TYPE[data.type]}</td>
            {/*
                <td className={styles.tableCell}>{data.department}</td>
             */}
            <td className={styles.tableCell}>{data.children}</td>
            {/*
            <td className={styles.tableCell}>
                <ActionRowItem data={data} onClickShowDetail={onClickShowDetail} />
            </td>
             */}
            <td className={`${styles.tableCell} ${styles.tableCellHeaderSearch}`}></td>
        </tr>
    )
}

TableRowItem.propTypes = {
    data: PropTypes.object,
    activeItem: PropTypes.object,
    onClickShowDetail: PropTypes.func
}
