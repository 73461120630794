import React from "react"
import PropTypes from "prop-types"
import {Spin} from "antd"
import styles from "../AllTable.module.css"

export default function LoadingRow(props) {
    const {colSpan} = props

    return (
        <tr className={styles.tableRow}>
            <td colSpan={colSpan}>
                <div className={styles.rowLoading}>
                    <Spin />
                </div>
            </td>
        </tr>
    )
}

LoadingRow.propTypes = {
    colSpan: PropTypes.number
}
