import React from "react"
import {Button} from "antd"
import {keyBy} from "lodash"
import {BaseInput} from "components/Input"
import {KlassDropdown} from "components/KlassDropdown"
import {Icon} from "components/Icons"
import styles from "./ChooseConfigureTaskType.module.css"

interface Props {
    item?: any
    userTypes: Array<object>
    onCloseItem: Function
    onAddItem: Function
}

interface State {
    data: any
}

const customStyles = {
    menuList: () => ({
        height: "70px",
        overflow: "auto"
    })
}

export default class ChooseConfigureTaskTypeFormItem extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        const data = this.initialData()
        this.state = {
            data
        }
    }

    initialData() {
        const {item, userTypes} = this.props
        if (item) {
            const userTypesKeyByID = keyBy(userTypes, "id")
            return {
                userType: userTypesKeyByID[item.userType],
                name: item.name
            }
        }
        return {
            userType: null,
            name: ""
        }
    }

    onChange = (key, value) => {
        const {data} = this.state
        data[key] = value
        this.setState({data})
    }

    onClickAddItem = () => {
        const {data} = this.state
        const {item} = this.props
        const {userType, name} = data

        if (!userType || !name) return
        this.props.onAddItem({...item, userType: userType.id, name})
    }

    render() {
        const {data} = this.state
        const {userType, name} = data
        const {item, onCloseItem, userTypes} = this.props

        return (
            <div className={styles.item}>
                <div className={styles.newItemWrap}>
                    <div className={styles.newItemFormWrap}>
                        <KlassDropdown
                            onChange={(value) => this.onChange("userType", value)}
                            options={userTypes}
                            value={userType}
                            placeholder="Select a user type"
                            className={styles.dropdownItem}
                            stylesCustom={customStyles}
                        />
                        <div className={styles.inputItemWrap}>
                            <BaseInput
                                defaultValue={name}
                                onChangeValue={(value) => this.onChange("name", value)}
                                placeholder="Name this user type"
                            />
                            <Button onClick={this.onClickAddItem} className={styles.btnItem}>
                                OK
                            </Button>
                        </div>
                    </div>
                    <div className={styles.closeIconWrap}>
                        <Button className={styles.closeBtn} onClick={() => onCloseItem(item)}>
                            <Icon icon="CLOSE" className={styles.closeIcon} />
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}
