import React from "react"
import Model from "Model"
import {SUBTYPE} from "constant"
import {BaseButton} from "components/Button"
import {BasePagination} from "components/Pagination"
import {Icon} from "components/Icons"
import TriggerFilter from "./TriggerFilter"
import TriggerTable from "./TriggerTable"
import {ConfigureTask} from "../../Configure"
import styles from "./TriggerTab.module.css"

interface Props {
    model: Model
}

export default class TriggerTab extends React.Component<Props, {}> {
    state = {
        isShowConfigureTask: false,
        configureTaskData: null,
        page: 1,
        data: []
    }

    componentDidMount() {}

    getData() {}

    onClickShowConfigureTaskPopup = () => {
        this.setState({isShowConfigureTask: true, configureTaskData: null})
    }

    onCloseConfigureTaskPopup = () => {
        this.setState({isShowConfigureTask: false, configureTaskData: null})
    }

    onClickShowDetail = (data) => {
        this.setState({isShowConfigureTask: true, configureTaskData: data})
    }

    onChangePage = (page) => {
        this.setState({page})
    }

    getConfigureTaskPopup() {
        const {isShowConfigureTask, configureTaskData} = this.state
        if (!isShowConfigureTask) return null
        const {model} = this.props
        return (
            <div>
                <ConfigureTask
                    isShow={isShowConfigureTask}
                    onClose={this.onCloseConfigureTaskPopup}
                    data={configureTaskData}
                    model={model}
                    subtype={SUBTYPE.TRIGGER}
                />
            </div>
        )
    }

    onApplyFilter = (data) => {
        console.log("data", data)
    }

    render() {
        const {page} = this.state
        const {model} = this.props

        return (
            <>
                <TriggerFilter onApplyFilter={this.onApplyFilter} model={model} />
                <TriggerTable onClickShowDetail={this.onClickShowDetail} model={model} />
                <div className={styles.createTaskWrap}>
                    <BaseButton
                        title="CREATE TASK"
                        onClick={this.onClickShowConfigureTaskPopup}
                        icon={<Icon className={styles.iconPlus} icon="PLUS" color="#FFF" />}
                    />
                    {this.getConfigureTaskPopup()}
                </div>
                <div className={styles.paginationWrap}>
                    <BasePagination page={page} total={101} onChangePage={this.onChangePage} />
                </div>
            </>
        )
    }
}
