const tableData = [
    {
        id: "1",
        title: "Request leave of absence",
        role: "Student",
        department: "Admissions",
        campus: "All",
        program: "All",
        usersAssigned: 10,
        createdAt: "15th Dec 2019"
    },
    {
        id: "2",
        title: "Expenses",
        role: "Student",
        department: "Admissions",
        campus: "All",
        program: "All",
        usersAssigned: 10,
        createdAt: "15th Dec 2019"
    },
    {
        id: "3",
        title: "Exit Procedures",
        role: "Student",
        department: "Financial Aid",
        campus: "All",
        program: "All",
        usersAssigned: 10,
        createdAt: "25th Mar 2020"
    },
    {
        id: "4",
        title: "Admissions",
        role: "Staff",
        department: "Financial Aid",
        campus: "All",
        program: "All",
        usersAssigned: 10,
        createdAt: "3rd Feb 2020"
    }
]

export {tableData}
