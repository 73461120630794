import React from "react"
import {Row, Col, Checkbox} from "antd"
import moment from "moment"
import Model from "Model"
import {BaseInput, BaseFileInput} from "components/Input"
import {BaseDatePicker} from "components/DatePicker"
import {KlassDropdown} from "components/KlassDropdown"
import {BaseError} from "components/Common"
import styles from "../ConfigureTask.module.css"

interface Props {
    data: any
    errors: any
    model: Model
    onChangeTaskData: Function
    library: boolean
}
const errorStyles = {
    control: (provided) => ({
        ...provided,
        borderRadius: "6px",
        borderColor: "transparent",
        boxShadow: "1px 2px 3px 1px var(--black-100)",
        border: "1px solid #ff6347",
        minHeight: "44px",
        fontSize: 16
    })
}
class TaskHeaderSettingBody extends React.Component<Props, {}> {
    state = {
        reminderOptions: [
            {id: "1", name: "On due date"},
            {id: "2", name: "2 days before"},
            {id: "7", name: "7 days before"},
            {id: "30", name: "Daily for 30 days after"}
        ]
    }

    render() {
        const {reminderOptions} = this.state
        const {onChangeTaskData, data, model, errors, library} = this.props
        const {category, staffUser, dueDate, name, reminderOption, description, digitalDocument, autoApprove} = data
        const {documentCategories, staffUsers, dateFormat} = model
        const file = digitalDocument && digitalDocument.file ? digitalDocument.file : undefined
        return (
            <div>
                <Row className={styles.rowItem}>
                    <Col span={8}>
                        <div className={styles.itemBodyWrap}>
                            <p className={styles.bodyTitle}>
                                Title <span className="required-star">*</span>
                            </p>
                            <BaseInput
                                defaultValue={name}
                                onChangeValue={(newValue) => onChangeTaskData("name", newValue)}
                                placeholder="Title"
                                className={errors && errors.name ? "error" : ""}
                            />
                            {errors && errors.name && (
                                <BaseError className={styles.error} error="This field is required" />
                            )}
                        </div>
                    </Col>
                    <Col span={8}>
                        {!library && (
                            <div className={styles.itemBodyWrap}>
                                <p className={styles.bodyTitle}>
                                    Due Date <span className="required-star">*</span>
                                </p>
                                <BaseDatePicker
                                    defaultValue={dueDate}
                                    onChange={(newValue) => onChangeTaskData("dueDate", newValue)}
                                    format={dateFormat}
                                    disabledDate={(current) => moment(current).isBefore(moment().subtract(1, "d"))}
                                    className={errors && errors.dueDate ? "error" : ""}
                                />
                                {errors && errors.dueDate && (
                                    <BaseError className={styles.error} error="This field is required" />
                                )}
                            </div>
                        )}
                    </Col>
                    <Col span={8}>
                        <div className={styles.itemBodyWrap}>
                            <p className={styles.bodyTitle}>Reminder options</p>
                            <KlassDropdown
                                onChange={(selectedOption) => onChangeTaskData("reminderOption", selectedOption)}
                                options={reminderOptions}
                                value={reminderOption}
                                placeholder="Select..."
                                isMulti
                            />
                        </div>
                    </Col>
                </Row>
                <Row className={styles.rowItem}>
                    <Col span={8}>
                        <div className={styles.itemBodyWrap}>
                            <p className={styles.bodyTitle}>
                                Description <span className="required-star">*</span>
                            </p>
                            <BaseInput
                                defaultValue={description}
                                onChangeValue={(newValue) => onChangeTaskData("description", newValue)}
                                placeholder="Basic usage"
                                className={errors && errors.description ? "error" : ""}
                            />
                            {errors && errors.description && (
                                <BaseError className={styles.error} error="This field is required" />
                            )}
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className={styles.itemBodyWrap}>
                            <p className={styles.bodyTitle}>
                                Document category <span className="required-star">*</span>
                            </p>
                            <KlassDropdown
                                onChange={(selectedOption) => onChangeTaskData("category", selectedOption)}
                                options={documentCategories}
                                value={category}
                                valueKey="item_value"
                                labelKey="item_label"
                                placeholder="Select..."
                                stylesCustom={errors && errors.category ? errorStyles : null}
                            />
                            {errors && errors.category && (
                                <BaseError className={styles.error} error="This field is required" />
                            )}
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className={styles.itemBodyWrap}>
                            <p className={styles.bodyTitle}>
                                Upload a PDF file <span className="required-star">*</span>
                            </p>
                            {file && (
                                <a target="_blank" href={file}>
                                    Open file
                                </a>
                            )}
                            {!file && (
                                <BaseFileInput
                                    label="BROWSE FOR A FILE"
                                    onChangeValue={(newValue) => onChangeTaskData("file", newValue)}
                                />
                            )}
                            {errors && errors.file && (
                                <BaseError className={styles.error} error="This field is required" />
                            )}
                        </div>
                    </Col>
                </Row>

                <Row className={styles.rowItem}>
                    <Col span={8}>
                        <div className={styles.itemBodyWrap}>
                            <p className={styles.bodyTitle}>Additional Approval Access</p>
                            <KlassDropdown
                                onChange={(selectedOption) => onChangeTaskData("staffUser", selectedOption)}
                                options={staffUsers}
                                value={staffUser}
                                getOptionLabel={(option: any) => `${option.first_name} ${option.last_name}`}
                                placeholder="Select..."
                                isMulti
                            />
                        </div>
                    </Col>
                    <Col span={16}>
                        <div className={styles.checkBoxAutoApprove}>
                            <Checkbox
                                onChange={({target: {checked}}) => onChangeTaskData("autoApprove", checked ? 1 : 0)}
                                checked={autoApprove}>
                                Auto Approve (Task will be automatically mark as "Approved" once all signers are done)
                            </Checkbox>
                        </div>
                    </Col>
                    {/* <Col span={8}>
                        <div className={styles.itemBodyWrap}>
                            <p className={styles.bodyTitle}>On completion set Status to</p>
                            <KlassDropdown
                                onChange={(selectedOption) => onChangeTaskData("taskType", selectedOption)}
                                options={studentStatuses}
                                value={taskType}
                                placeholder="Select..."
                            />
                        </div>
                    </Col> */}
                </Row>
            </div>
        )
    }
}

export default TaskHeaderSettingBody
