import React from "react"
import moment from "moment"
import {Row, Col, Checkbox} from "antd"
import Model from "Model"
import {BaseInput} from "components/Input"
import styles from "./TaskDetail.module.css"

interface Props {
    data: any
    model: Model
    onChangeTaskData: Function
}
const reminderOptions = {
    1: "On due date",
    2: "2 days before",
    7: "7 days before",
    30: "Daily for 30 days after"
}

class TaskHeaderSettingBody extends React.Component<Props, {}> {
    render() {
        const {data, model} = this.props
        const {categoryId, dueDate, createdAt, reminders, description, status, filename, cc, autoApprove} = data
        const {dateFormat} = model

        const createdAtLocal = moment(createdAt)
            .local()
            .format(dateFormat)
        const dueDateLocal = dueDate ? moment(dueDate.replace("T00:00:00.000Z", "")).format(dateFormat) : ""

        const {documentCategories, staffUsers} = model
        const category: any = documentCategories.find((cat) => cat.id === categoryId) || {}
        const reminderValue = reminders.map((reminder) => reminderOptions[+reminder]).join(", ")
        const ccValue = cc.map((staff) => `${staff.first_name} ${staff.last_name}`).join(", ")
        return (
            <div>
                <Row className={styles.rowItem}>
                    <Col span={8}>
                        <div className={styles.itemBodyWrap}>
                            <p className={styles.bodyTitle}>Completion status</p>
                            <BaseInput className={styles.inputReadOnly} value={status} />
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className={styles.itemBodyWrap}>
                            <p className={styles.bodyTitle}>Date sent</p>
                            <BaseInput className={styles.inputReadOnly} value={createdAtLocal} />
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className={styles.itemBodyWrap}>
                            <p className={styles.bodyTitle}>Due date</p>
                            <BaseInput className={styles.inputReadOnly} value={dueDateLocal} />
                        </div>
                    </Col>
                </Row>
                <Row className={styles.rowItem}>
                    <Col span={8}>
                        <div className={styles.itemBodyWrap}>
                            <p className={styles.bodyTitle}>Description</p>
                            <BaseInput className={styles.inputReadOnly} value={description} />
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className={styles.itemBodyWrap}>
                            <p className={styles.bodyTitle}>Reminder Options</p>
                            <BaseInput className={styles.inputReadOnly} value={reminderValue} />
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className={styles.itemBodyWrap}>
                            <p className={styles.bodyTitle}>Additional Approval Access</p>
                            <BaseInput className={styles.inputReadOnly} value={ccValue} />
                        </div>
                    </Col>
                    <Col span={16}>
                        <div className={styles.itemBodyWrap}>
                            <Checkbox checked={autoApprove}>
                                Auto Approve (Task will be automatically mark as "Approved" once all signers are done)
                            </Checkbox>
                        </div>
                    </Col>
                </Row>
                <Row className={styles.rowItem}>
                    <Col span={8}>
                        <div className={styles.itemBodyWrap}>
                            <p className={styles.bodyTitle}>Document category</p>
                            <BaseInput className={styles.inputReadOnly} value={category.description} />
                        </div>
                    </Col>
                    {filename && (
                        <Col span={8}>
                            <div className={styles.itemBodyWrap}>
                                <p className={styles.bodyTitle}>File name</p>
                                <BaseInput className={styles.inputReadOnly} value={reminderValue} />
                            </div>
                        </Col>
                    )}
                </Row>
            </div>
        )
    }
}

export default TaskHeaderSettingBody
