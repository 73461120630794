import React, {useState} from "react"
import PropTypes from "prop-types"
import {BaseInput} from "components/Input"
import {BaseAsyncSelect} from "components/Select"
import styles from "./ConfigureTaskOtherTable.module.css"

export default function TableRowItem({data, onChange, loadStudentFn}) {
    const [invalidEmail, setInvalidEmail] = useState(false)
    const handleOnChangeValue = (key, value) => {
        const newData = {
            ...data,
            [key]: value
        }
        onChange(newData)
    }
    const validateEmail = () => {
        const val = data.email.trim().length > 0 && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data.email)
        setInvalidEmail(val)
    }
    return (
        <tr key={data.id} className={styles.tableRow}>
            <td className={styles.tableCell} style={{width: "130px"}}></td>
            <td className={styles.tableCell}>
                {data.student.first_name} {data.student.last_name}
            </td>
            <td className={styles.tableCell}>
                <BaseInput
                    placeholder="Name"
                    value={data.name}
                    onChangeValue={(newValue) => handleOnChangeValue("name", newValue)}
                />
            </td>
            <td className={styles.tableCell}>
                <BaseInput
                    placeholder="Email"
                    className={invalidEmail && styles.inputError}
                    onBlur={validateEmail}
                    value={data.email}
                    onChangeValue={(newValue) => handleOnChangeValue("email", newValue)}
                />
            </td>

            {/*
                    defaultValue={description}
                    onChangeValue={(newValue) => onChangeTaskData("description", newValue)}

                    <td className={styles.tableCell}>
                        <Button className={styles.cloudIconBtn}>
                            <Icon icon="CLOUD_UPLOAD" className={styles.cloudIcon} />
                        </Button>1
                    </td>
                 */}
        </tr>
    )
}

TableRowItem.propTypes = {
    data: PropTypes.object
}
