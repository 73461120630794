export const SUBTYPE = {
    ASSIGNED: "assigned",
    LIBRARY: "library",
    ON_DEMAND: "on-demand",
    TRIGGER: "trigger"
}
export const TASK_TYPE = {
    dd: "D Docs",
    form: "eForms",
    scan: "Scan",
    custom: "custom"
}

export const STATUS = {
    open: "Open",
    overdue: "Overdue",
    completed: "Completed",
    rejected: "Rejected",
    submitted: "Awaiting Approval"
}

export const REMINDERS = [
    {id: "1", name: "On due date"},
    {id: "2", name: "2 days before"},
    {id: "7", name: "7 days before"},
    {id: "30", name: "Daily for 30 days after"}
]
