import React from "react"
import {Row, Col, Button} from "antd"
import Model from "Model"
import {BaseInput} from "components/Input"
import {BaseMultiSelect} from "components/Select"
import {Icon} from "components/Icons"
import ConfigureTaskFilterBody from "./ConfigureTaskFilterBody"
import styles from "./ConfigureTaskFilter.module.css"

interface Props {
    model: Model
}

interface State {
    filterData: any
    users: any
    isShowBodyFilterOptions: boolean
}

export default class ConfigureTaskFilter extends React.Component<Props, State> {
    state = {
        filterData: {
            fileName: "",
            assigners: []
        },
        users: [
            {id: "user1", name: "User 1"},
            {id: "user2", name: "User 2"},
            {id: "user3", name: "User 3"},
            {id: "user4", name: "User 4"},
            {id: "user5", name: "User 5"}
        ],
        isShowBodyFilterOptions: false
    }

    onChangeFilter = (key, value) => {
        const {filterData} = this.state
        filterData[key] = value
        this.setState({filterData})
    }

    onClickTogglewBodyFilterOptions = () => {
        const {isShowBodyFilterOptions} = this.state
        this.setState({isShowBodyFilterOptions: !isShowBodyFilterOptions})
    }

    render() {
        const {filterData, users, isShowBodyFilterOptions} = this.state
        const {model} = this.props

        return (
            <>
                <Row>
                    <Col span={8}>
                        <div className={styles.itemFilterWrap}>
                            <p className={styles.itemLabel}>
                                File name <span className="required-star">*</span>
                            </p>
                            <BaseInput
                                defaultValue={filterData.fileName}
                                onChangeValue={(newValue) => this.onChangeFilter("fileName", newValue)}
                                placeholder="Art project June"
                            />
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className={styles.itemFilterWrap}>
                            <p className={styles.itemLabel}>
                                Assign this task to <span className="required-star">*</span>
                            </p>
                            <BaseMultiSelect
                                options={users}
                                onChange={(selectedValue) => this.onChangeFilter("assigners", selectedValue)}
                                placeholder="Students by name or from the filter"
                            />
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className={styles.filterBtnWrap}>
                            <Button className={styles.filterBtn} onClick={this.onClickTogglewBodyFilterOptions}>
                                <span className={styles.filterBtnText}>Filter Options</span>
                                {isShowBodyFilterOptions ? (
                                    <Icon icon="ARROW_DOWN" className={styles.filterBtnIcon} />
                                ) : (
                                    <Icon icon="ARROW_RIGHT" className={styles.filterBtnIcon} />
                                )}
                            </Button>
                        </div>
                    </Col>
                </Row>
                {isShowBodyFilterOptions && <ConfigureTaskFilterBody model={model} />}
            </>
        )
    }
}
