import React from "react"
import classNames from "classnames"
import moment from "moment"
import ActionRowItem from "./ActionRowItem"
import {STATUS, TASK_TYPE} from "constant"

import styles from "../LibraryTable.module.css"

export default function TableRowItem(props) {
    const {data, onClickShowDetail, onClickRowItem, model, onDeleteTaskSuccess} = props
    const {dateFormat} = model

    return (
        <tr key={data.id} className={styles.tableRow}>
            <td className={classNames(styles.tableCell, styles.cursor)} onClick={() => onClickRowItem(data)}>
                {data.name}
            </td>
            <td className={styles.tableCell}>{TASK_TYPE[data.type]}</td>
            <td className={styles.tableCell}>{data.children}</td>
            <td className={styles.tableCell}>{data.createdAt ? moment(data.createdAt).format(dateFormat) : ""}</td>
            <td className={styles.tableCell}>
                <ActionRowItem
                    data={data}
                    onClickShowDetail={onClickShowDetail}
                    onDeleteTaskSuccess={onDeleteTaskSuccess}
                />
            </td>
        </tr>
    )
}
