import React, {Component} from "react"
import Select, {Props} from "react-select"

interface KlassDropdownProps extends Props {
    onChange: any
    options: any
    value: any
    valueKey?: string
    labelKey?: string
}

const customStyles = {
    control: (provided) => ({
        ...provided,
        borderRadius: "6px",
        borderColor: "transparent",
        boxShadow: "1px 2px 3px 1px var(--black-100)",
        border: "none",
        minHeight: "44px",
        fontSize: 16
    }),

    container: (provided) => ({
        ...provided
    }),

    indicatorSeparator: () => ({
        display: "none"
    }),

    dropdownIndicator: (provided, state) => ({
        ...provided,
        display: "flex",
        alignItems: "center",
        width: "40px",
        height: "100%",
        borderTopRightRadius: "6px",
        borderBottomRightRadius: "6px",
        backgroundColor: "var(--primary-400-base)",
        borderColor: "var(--primary-400-base)",
        justifyContent: "center",
        color: "var(--whitebase)",
        "&:hover": {color: "var(--whitebase)"},
        cursor: "pointer"
    }),

    placeholder: (provided) => ({
        ...provided,
        fontSize: "16px",
        color: "var(--black-200)"
    }),

    multiValue: (styles) => {
        return {
            ...styles,
            backgroundColor: "var(--primary-400-base)"
        }
    },

    multiValueLabel: (styles) => ({
        ...styles,
        color: "var(--whitebase)"
    }),

    multiValueRemove: (styles) => ({
        ...styles,
        color: "var(--whitebase)"
    }),

    menuList: () => ({
        maxHeight: "200px",
        overflow: "auto"
    })
}

export class KlassDropdown extends Component<KlassDropdownProps, {}> {
    public render() {
        const {stylesCustom, valueKey, labelKey, ...rest} = this.props
        let valueKeySelect = valueKey || "id"
        let labelKeySelect = labelKey || "name"
        let styles = customStyles
        if (stylesCustom) styles = {...styles, ...stylesCustom}
        const props = {...rest, styles}

        return (
            <Select
                getOptionValue={(option: any) => option[valueKeySelect]}
                getOptionLabel={(option: any) => option[labelKeySelect]}
                {...props}
            />
        )
    }
}
