import React from "react"
import {TableRowItem} from "./items"
import styles from "./user.module.css"
import {tableData} from "./rawData"

export default function Users(props) {
    const {model} = props

    return (
        <div className={styles.wrapper}>
            <table className={styles.table}>
                <thead className={styles.tableHead}>
                    <tr className={styles.tableRowHeader}>
                        <th className={`${styles.tableCell} ${styles.tableCellHeader}`}></th>
                        <th className={`${styles.tableCell} ${styles.tableCellHeader}`}>Task name</th>
                        <th className={`${styles.tableCell} ${styles.tableCellHeader}`}>Reference</th>
                        <th className={`${styles.tableCell} ${styles.tableCellHeader}`}>Task type</th>
                        <th className={`${styles.tableCell} ${styles.tableCellHeader}`}>Document</th>
                        <th className={`${styles.tableCell} ${styles.tableCellHeader}`}>Due</th>
                        <th className={`${styles.tableCell} ${styles.tableCellHeader}`}>Status</th>
                        <th className={`${styles.tableCell} ${styles.tableCellHeader}`}>Icon</th>
                    </tr>
                </thead>
                <tbody>
                    {tableData.map((item) => (
                        <TableRowItem key={item.id} data={item} model={model} />
                    ))}
                </tbody>
            </table>
        </div>
    )
}
