import React from "react"
import PropTypes from "prop-types"
import {Button, Menu, Dropdown} from "antd"
import {Icon} from "components/Icons"
import styles from "../TriggerTable.module.css"

export default function ActionRowItem(props) {
    // const onClickEditTask = () => {
    //     const {onClickShowDetail, data} = props
    //     onClickShowDetail(data)
    // }

    const onClickAddToLibrary = () => {}

    const onClickAddToPackage = () => {}

    const onClickDelete = () => {}

    const menu = (
        <Menu className={styles.menuAcitonWrap}>
            {/* <Menu.Item className={styles.menuAcitonItem} key="0" onClick={onClickEditTask}>
                <Icon className={styles.menuActionIcon} icon="PAPER_PLANE" />
                <span className={styles.menuActionTitle}>Edit Task</span>
            </Menu.Item> */}
            <Menu.Item className={styles.menuAcitonItem} key="1" onClick={onClickAddToLibrary}>
                <Icon className={styles.menuActionIcon} icon="LIBRARY" />
                <span className={styles.menuActionTitle}>Add To Libray</span>
            </Menu.Item>
            <Menu.Item className={styles.menuAcitonItem} key="2" onClick={onClickAddToPackage}>
                <Icon className={styles.menuActionPackageIcon} icon="PACKAGE" />
                <span className={styles.menuActionTitle}>Add To Package</span>
            </Menu.Item>
            <Menu.Item className={styles.menuAcitonItem} key="3" onClick={onClickDelete}>
                <Icon className={styles.menuActionIcon} icon="DELETE" />
                <span className={styles.menuActionTitle}>Delete</span>
            </Menu.Item>
        </Menu>
    )

    return (
        <Dropdown overlay={menu} trigger={["click"]}>
            <Button className={styles.iconBtn} icon={<Icon icon="MORE_HORZ" />} />
        </Dropdown>
    )
}

ActionRowItem.propTypes = {
    data: PropTypes.object,
    onClickShowDetail: PropTypes.func
}
