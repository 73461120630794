export class Iframe {
    public static updateSize() {
        const body = document.body
        const html = document.documentElement
        const height = Math.max(
            body.scrollHeight,
            body.offsetHeight,
            html.clientHeight,
            html.scrollHeight,
            html.offsetHeight
        )
        //window.top && window.top.postMessage('resize:'+height, '*')
    }
}
