import React from "react"
import {Tabs} from "antd"
import Model from "Model"
import {observer} from "mobx-react"
import {Icon} from "components/Icons"
import {BasePopup} from "components/popup"
import {ConfigureTaskScan} from "./Scan"
import {ConfigureTaskDigitalDocument} from "./DigitalDocument"
import {ConfigureTaskForm} from "./Form"
import {LibraryTable} from "./Library"
import {REMINDERS} from "constant"
import styles from "./ConfigureTask.module.css"

const {TabPane} = Tabs

interface Props {
    isShow: boolean
    model: Model
    data: any
    subtype: string
    onClose: Function
    onSuccess?: Function
    library?: boolean
}

const parseTaskInfo = (task, {documentCategories, forms}) => {
    task.category = documentCategories.find((cat) => +task.categoryId === +cat.id)
    task.form = forms.find((forms) => +task.formId === +forms.id)
    task.reminderOption = task.reminders.map((reminderId) => {
        return REMINDERS.find((reminder) => +reminderId === +reminder.id)
    })
    return task
}
@observer
export default class ConfigureTask extends React.Component<Props, {}> {
    constructor(props) {
        super(props)
        this.loadStaffUsers()
        this.loadDocumentCategories()
    }
    loadStaffUsers = async () => {
        const {model} = this.props
        return await model.loadStaffUsers({limit: 10000})
    }

    loadDocumentCategories = async () => {
        const {model} = this.props
        return await model.loadDocumentCategories({limit: 100})
    }
    handleClose = async () => {
        const {onClose, model} = this.props
        model.libraryTask = undefined
        onClose()
    }
    handleOnSuccess = () => {
        this.props.model.libraryTask = undefined
        this.props.onSuccess()
    }
    renderCreateForm = () => {
        const {data, model, subtype, library} = this.props

        return (
            <Tabs className={`fullwidth ${styles.tabsWrapper}`} defaultActiveKey="1">
                {!library && (
                    <TabPane
                        className={styles.tabItem}
                        tab={
                            <span>
                                <Icon className={styles.tabIcon} icon="DIGITAL_DOCUMENT_TASK" />
                                <span className={styles.tabTitle}>eForm Task</span>
                            </span>
                        }
                        key="1">
                        <ConfigureTaskForm
                            data={data}
                            model={model}
                            library={library}
                            subtype={subtype}
                            onSuccess={this.handleOnSuccess}
                            onClose={this.handleClose}
                        />
                    </TabPane>
                )}
                <TabPane
                    className={styles.tabItem}
                    tab={
                        <span>
                            <Icon className={styles.tabIcon} icon="DIGITAL_DOCUMENT_TASK" />
                            <span className={styles.tabTitle}>Digital Document Task</span>
                        </span>
                    }
                    key="2">
                    <ConfigureTaskDigitalDocument
                        data={data}
                        model={model}
                        library={library}
                        subtype={subtype}
                        onSuccess={this.handleOnSuccess}
                        onClose={this.handleClose}
                    />
                </TabPane>

                <TabPane
                    className={styles.tabItem}
                    tab={
                        <span>
                            <Icon className={styles.tabIcon} icon="SCAN_TASK" />
                            <span className={styles.tabTitle}>Scan/Upload Task</span>
                        </span>
                    }
                    key="3">
                    <ConfigureTaskScan
                        data={data}
                        model={model}
                        library={library}
                        onSuccess={this.handleOnSuccess}
                        onClose={this.handleClose}
                        subtype={subtype}
                    />
                </TabPane>

                {!library && (
                    <TabPane
                        className={styles.tabItem}
                        tab={
                            <span>
                                <Icon className={styles.tabIcon} icon="LIBRARY" />
                                <span className={styles.tabTitle}>Task from the Library</span>
                            </span>
                        }
                        key="4">
                        <LibraryTable model={model} onClose={this.handleClose} />
                    </TabPane>
                )}
            </Tabs>
        )
    }

    renderEditForm = (data, fromLibrary) => {
        const {model, subtype, library} = this.props
        const type = data.type.toLowerCase()

        switch (type) {
            case "scan":
                return (
                    <Tabs className={`fullwidth ${styles.tabsWrapper}`} defaultActiveKey="1">
                        <TabPane
                            className={styles.tabItem}
                            tab={
                                <span>
                                    <Icon className={styles.tabIcon} icon="SCAN_TASK" />
                                    <span className={styles.tabTitle}>Scan/Upload Task</span>
                                </span>
                            }
                            key="1">
                            <ConfigureTaskScan
                                data={data}
                                model={model}
                                library={library}
                                onSuccess={this.handleOnSuccess}
                                onClose={this.handleClose}
                                subtype={subtype}
                            />
                        </TabPane>
                    </Tabs>
                )
            case "dd":
                return (
                    <Tabs className={`fullwidth ${styles.tabsWrapper}`} defaultActiveKey="1">
                        <TabPane
                            className={styles.tabItem}
                            tab={
                                <span>
                                    <Icon className={styles.tabIcon} icon="DIGITAL_DOCUMENT_TASK" />
                                    <span className={styles.tabTitle}>Digital Document Task</span>
                                </span>
                            }
                            key="1">
                            <ConfigureTaskDigitalDocument
                                data={data}
                                model={model}
                                library={library}
                                subtype={subtype}
                                onSuccess={this.handleOnSuccess}
                                onClose={this.handleClose}
                                fromLibrary={fromLibrary}
                            />
                        </TabPane>
                    </Tabs>
                )
            case "form":
                return (
                    <Tabs className={`fullwidth ${styles.tabsWrapper}`} defaultActiveKey="1">
                        <TabPane
                            className={styles.tabItem}
                            tab={
                                <span>
                                    <Icon className={styles.tabIcon} icon="DIGITAL_DOCUMENT_TASK" />
                                    <span className={styles.tabTitle}>eForm Task</span>
                                </span>
                            }
                            key="1">
                            <ConfigureTaskForm
                                data={data}
                                model={model}
                                library={library}
                                subtype={subtype}
                                onSuccess={this.handleOnSuccess}
                                onClose={this.handleClose}
                            />
                        </TabPane>
                    </Tabs>
                )

            default:
                return null
        }
    }

    render() {
        const {isShow, onClose, data, model} = this.props
        const editData = model.libraryTask || data

        return (
            <BasePopup isShow={isShow} onClose={this.handleClose}>
                <p className={styles.title}>Configure Task</p>
                {editData && (editData.id || !!model.libraryTask)
                    ? this.renderEditForm(parseTaskInfo(editData, model), !!model.libraryTask)
                    : this.renderCreateForm()}
            </BasePopup>
        )
    }
}
