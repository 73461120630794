import React from "react"
import {Row, Col, Button} from "antd"
import {debounce} from "lodash"
import Model from "Model"
import {userService} from "services"
import {BaseInput} from "components/Input"
import {BaseAsyncSelect} from "components/Select"
import {Icon} from "components/Icons"
import {BaseError} from "components/Common"
import ConfigureTaskFilterBody from "./ConfigureTaskFilterBody"
import ConfigureTaskTable from "./ConfigureTaskTable"
import styles from "./ConfigureTaskFilter.module.css"

interface Props {
    model: Model
    data: any
    errors: any
    onChangeTaskData: Function
    onChangeFilteredUsers: Function
}

interface State {
    isShowBodyFilterOptions: boolean
    isLoadingStaffUsers: boolean
    isShowTable: boolean
    value: string
    filterBodyData: any
    tableData: any
}

const errorStyles = {
    control: (provided) => ({
        ...provided,
        borderRadius: "6px",
        borderColor: "transparent",
        boxShadow: "1px 2px 3px 1px var(--black-100)",
        border: "1px solid #ff6347 !important",
        minHeight: "44px",
        fontSize: 16
    })
}

export default class ConfigureTaskFilter extends React.Component<Props, State> {
    debouncedLoadStudentUsers: any

    constructor(props) {
        super(props)
        this.state = {
            isShowBodyFilterOptions: false,
            isShowTable: false,
            isLoadingStaffUsers: false,
            value: "",
            filterBodyData: {
                campuses: [],
                groups: [],
                programs: [],
                statuses: [],
                fromDate: "",
                toDate: ""
            },
            tableData: []
        }
        this.debouncedLoadStudentUsers = debounce(this.loadStudentUsers, 0, {leading: true})
    }

    onClickTogglewBodyFilterOptions = () => {
        const {isShowBodyFilterOptions} = this.state
        this.setState({isShowBodyFilterOptions: !isShowBodyFilterOptions})
    }

    loadStudentUsers = async (value) => {
        const {model} = this.props
        this.setState({value})
        return await model.loadStudentUsers({query: value, role: "student", limit: 20, offset: 0})
    }

    onChangeFilterData = (key, value) => {
        const {filterBodyData} = this.state
        filterBodyData[key] = value
        this.setState({filterBodyData})
    }

    onClickFilterBtn = async () => {
        const {value, filterBodyData} = this.state
        const {model} = this.props
        let data = []
        if (value) {
            data = await model.loadStudentUsers({query: value, role: "student", limit: 20, offset: 0})
        } else {
            const {response} = await userService.searchUsers({
                ...filterBodyData,
                role: "student",
                limit: 20,
                offset: 0
            })
            data = response
        }
        this.setState({isShowTable: true, isShowBodyFilterOptions: false, tableData: data})
        this.props.onChangeFilteredUsers(data)
    }

    onClickEditFilter = () => {
        this.setState({isShowTable: false, isShowBodyFilterOptions: true})
        this.props.onChangeFilteredUsers([])
    }

    render() {
        const {isShowBodyFilterOptions, filterBodyData, isShowTable, tableData} = this.state
        const {model, onChangeTaskData, data, errors} = this.props
        const hasAssignerSelected = !!(data.assigners && data.assigners.length)

        return (
            <>
                <Row>
                    <Col span={8}>
                        <div className={styles.itemFilterWrap}>
                            <p className={styles.itemLabel}>
                                File name <span className="required-star">*</span>
                            </p>
                            <BaseInput
                                defaultValue={data.fileName}
                                onChangeValue={(newValue) => onChangeTaskData("fileName", newValue)}
                                placeholder="Art project June"
                                className={errors && errors.fileName ? "error" : ""}
                            />
                            {errors && errors.fileName && (
                                <BaseError className={styles.error} error="This field is required" />
                            )}
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className={styles.itemFilterWrap}>
                            <p className={styles.itemLabel}>
                                Assign this task to <span className="required-star">*</span>
                            </p>
                            <BaseAsyncSelect
                                getOptionValue={(option) => option.id}
                                getOptionLabel={(option) => option.full_name}
                                loadOptions={(inputValue) => this.debouncedLoadStudentUsers(inputValue)}
                                onChange={(userSelected) => onChangeTaskData("assigners", userSelected)}
                                isMulti
                                stylesCustom={errors && errors.assigners ? errorStyles : null}
                            />
                            {errors && errors.assigners && (
                                <BaseError className={styles.error} error="This field is required" />
                            )}
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className={styles.filterBtnWrap}>
                            <Button className={styles.filterBtn} onClick={this.onClickTogglewBodyFilterOptions}>
                                <span className={styles.filterBtnText}>Filter Options</span>
                                {isShowBodyFilterOptions ? (
                                    <Icon icon="ARROW_DOWN" className={styles.filterBtnIcon} />
                                ) : (
                                    <Icon icon="ARROW_RIGHT" className={styles.filterBtnIcon} />
                                )}
                            </Button>
                        </div>
                    </Col>
                </Row>
                {isShowBodyFilterOptions && (
                    <ConfigureTaskFilterBody
                        model={model}
                        disabled={hasAssignerSelected}
                        filterBodyData={filterBodyData}
                        onChangeFilter={this.onChangeFilterData}
                        onClick={this.onClickFilterBtn}
                    />
                )}
                {isShowTable && <ConfigureTaskTable data={tableData} onEdit={this.onClickEditFilter} />}
            </>
        )
    }
}
