import React from "react"
import PropTypes from "prop-types"
import {Button} from "antd"
import classNames from "classnames"
import moment from "moment"
import ExpandRowItem from "./ExpandRowItem"
import ActionRowItem from "./ActionRowItem"
import StatusIconItem from "./StatusIconItem"
import {Icon} from "components/Icons"
import ChildRows from "./ChildRows"
import styles from "../user.module.css"

export default function TableRowItem(props) {
    const {data, model} = props
    const {dateFormat} = model

    const [open, setOpen] = React.useState(false)

    return (
        <>
            <tr key={data.id} className={styles.tableRow}>
                <td className={`${styles.tableCell} ${styles.expandIconCell}`}>
                    <ExpandRowItem data={data} open={open} setOpen={setOpen} />
                </td>
                <td className={styles.tableCell}>{data.taskName}</td>
                <td className={styles.tableCell}>{data.reference}</td>
                <td className={styles.tableCell}>{data.taskType}</td>
                <td className={classNames(styles.tableCell, styles.tableCellCenter)}>
                    {data.childs ? (
                        <Button className={styles.iconBtn} icon={<Icon icon="PACKAGE" />} />
                    ) : (
                        <Button className={styles.iconBtn} icon={<Icon icon="FILE" />} />
                    )}
                </td>
                <td className={styles.tableCell}>{data.due ? moment(data.due).format(dateFormat) : ""}</td>
                <td className={styles.tableCell}>
                    <div>
                        <StatusIconItem data={data} />
                        <span className={styles.rowItemStatus}>{data.status}</span>
                    </div>
                </td>
                <td className={styles.tableCell}>
                    <ActionRowItem data={data} />
                </td>
            </tr>
            <ChildRows childRows={data.childs} open={open} />
        </>
    )
}
