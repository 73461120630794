import React from "react"
import PropTypes from "prop-types"
import {Button, Menu, Dropdown} from "antd"
import {Icon} from "components/Icons"
import styles from "../user.module.css"

export default function ActionRowItem(props) {
    const onClickSendTask = () => {}

    const onClickSendNotify = () => {}

    const onClickDuplicate = () => {}

    const onClickDelete = () => {}

    const menu = (
        <Menu className={styles.menuAcitonWrap}>
            <Menu.Item className={styles.menuAcitonItem} key="0" onClick={onClickSendTask}>
                <Icon className={styles.menuActionIcon} icon="PAPER_PLANE" />
                <span className={styles.menuActionTitle}>Send Task</span>
            </Menu.Item>
            <Menu.Item className={styles.menuAcitonItem} key="1" onClick={onClickSendNotify}>
                <Icon className={styles.menuActionIcon} icon="NOTIFY" />
                <span className={styles.menuActionTitle}>Send Reminder</span>
            </Menu.Item>
            <Menu.Item className={styles.menuAcitonItem} key="2" onClick={onClickDuplicate}>
                <Icon className={styles.menuActionIcon} icon="DUPLICATE" />
                <span className={styles.menuActionTitle}>Duplicate</span>
            </Menu.Item>
            <Menu.Item className={styles.menuAcitonItem} key="3" onClick={onClickDelete}>
                <Icon className={styles.menuActionIcon} icon="DELETE" />
                <span className={styles.menuActionTitle}>Delete</span>
            </Menu.Item>
        </Menu>
    )

    return (
        <Dropdown overlay={menu} trigger={["click"]}>
            <Button className={styles.iconBtn} icon={<Icon icon="MORE_HORZ" />} />
        </Dropdown>
    )
}

ActionRowItem.propTypes = {
    data: PropTypes.object
}
