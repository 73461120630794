import React from "react"
import {Row, Col, Button} from "antd"
import Model from "Model"
import {BaseButton} from "components/Button"
import {KlassDropdown} from "components/KlassDropdown"
import styles from "./OnDemandTab.module.css"

interface Props {
    model: Model
    onApplyFilter: Function
}

export default class OnDemandFilter extends React.Component<Props, {}> {
    state = {
        departments: [
            {id: "department1", name: "Department 1"},
            {id: "department2", name: "Department 2"}
        ],
        statuses: [
            {id: "status1", name: "Status 1"},
            {id: "status2", name: "Status 2"}
        ],
        data: {
            department: null,
            status: null
        }
    }

    onChange = (key, selectedOption) => {
        const {data} = this.state
        data[key] = selectedOption
        this.setState({data})
    }

    onClickResetFilter = () => {
        const data = {program: null, campus: null, department: null, status: null}
        this.setState({data})
        this.props.onApplyFilter({})
    }

    onClickApplyFilter = () => {
        const {data} = this.state
        const dataFilter = {}
        Object.keys(data).forEach((key) => {
            if (data[key]) dataFilter[key] = data[key].id
        })
        this.props.onApplyFilter(dataFilter)
    }

    render() {
        const {departments, statuses, data} = this.state
        const {department, status} = data

        return (
            <div>
                <Row>
                    <Col span={6}>
                        <KlassDropdown
                            onChange={(selectedOption) => this.onChange("department", selectedOption)}
                            options={departments}
                            value={department}
                            placeholder="Department"
                            className={styles.colFilter}
                        />
                    </Col>
                    <Col span={6}>
                        <KlassDropdown
                            onChange={(selectedOption) => this.onChange("status", selectedOption)}
                            options={statuses}
                            value={status}
                            placeholder="Status"
                            className={styles.colFilter}
                        />
                    </Col>
                    <Col span={6}>
                        <div className={styles.filterBtnWrap}>
                            <Button className={styles.filterBtn} onClick={this.onClickResetFilter}>
                                Reset Filters
                            </Button>
                        </div>
                    </Col>
                    <Col span={6}>
                        <div className={styles.applyBtnWrap}>
                            <BaseButton title="APPLY" onClick={this.onClickApplyFilter} />
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
