import axiosModule, {AxiosInstance} from "axios"

class BaseService {
    protected axios: AxiosInstance
    protected baseURL: string
    protected baseURLV1: string

    constructor() {
        this.axios = axiosModule.create({timeout: 60 * 1000})
        this.baseURL = process.env.REACT_APP_API_DOMAIN
        this.baseURLV1 = process.env.REACT_APP_API_DOMAIN_V1
    }

    protected setHeaderFromParams(params) {
        const headers = {
            "Content-Type": "application/json",
            clientId: "test"
        }
        const token = localStorage.getItem("token")
        if (token) {
            headers["auth_token"] = `Bearer ${token}`
            headers["auth_id"] = "v3"
        }
        const res: any = {}
        res.headers = {...headers, ...(params.headers || {})}
        res.params = params
        return res
    }

    async get(url, params = {}, isV1 = false) {
        const baseURL = isV1 ? this.baseURLV1 : this.baseURL
        const paramsData = this.setHeaderFromParams(params)
        const response = await this.axios.get(`${baseURL}${url}`, paramsData)
        return response
    }

    async post(url, data, params = {}, isV1 = false) {
        const baseURL = isV1 ? this.baseURLV1 : this.baseURL
        const paramsData = this.setHeaderFromParams(params)
        const response = await this.axios.post(`${baseURL}${url}`, data, paramsData)
        return response
    }

    async put(url, data, params = {}, isV1 = false) {
        const baseURL = isV1 ? this.baseURLV1 : this.baseURL
        const paramsData = this.setHeaderFromParams(params)
        const response = await this.axios.put(`${baseURL}${url}`, data, paramsData)
        return response
    }

    async delete(url, params = {}, isV1 = false) {
        const baseURL = isV1 ? this.baseURLV1 : this.baseURL
        const paramsData = this.setHeaderFromParams(params)
        const response = await this.axios.delete(`${baseURL}${url}`, paramsData)
        return response
    }
}

export default BaseService
