import React from "react"
import classNames from "classnames"
import moment from "moment"
import {Button, Menu, Dropdown, Tooltip} from "antd"
import Model from "Model"
import {Icon} from "components/Icons"
import styles from "../ResponsesTable.module.css"
import {STATUS} from "constant"

interface Props {
    childRows: any
    open: boolean
    resend: Function
    model: Model
}

export default function ChildRows(props: Props) {
    const {childRows, open, resend, model} = props
    if (!childRows) return null
    const {dateFormat} = model

    const menu = (id) => (
        <Menu className={styles.menuAcitonWrap}>
            <Menu.Item className={styles.menuAcitonItem} key="0">
                <span className={styles.menuActionTitle} onClick={() => resend(id)}>
                    Re-send
                </span>
            </Menu.Item>
        </Menu>
    )

    return childRows.map((childItem) => (
        <tr
            key={childItem.id}
            className={classNames(styles.tableRow, styles.initial_padding, !open ? styles.hiddenRow : "")}>
            <td className={`${styles.tableCell} ${styles.expandIconCell}`}></td>
            <td className={styles.tableCell}>
                <span>{childItem.full_name}</span>
            </td>
            <td className={styles.tableCell}>{childItem.type === "other" ? "Other" : childItem.roles}</td>
            <td className={styles.tableCell}>{childItem.email}</td>
            <td className={styles.tableCell}>
                {childItem.status === "submitted" ? moment(childItem.submittedAt).format(dateFormat) : "N/A"}
                {childItem.status === "rejected" && (
                    <Tooltip title={childItem.reason}>
                        <span className={styles.rejectIconWrap}>
                            <Icon icon="INFO" className={styles.rejectIcon} color="#df1642" />
                        </span>
                    </Tooltip>
                )}
            </td>
            <td className={styles.tableCell}>{STATUS[childItem.status]}</td>
            <td className={styles.tableCell}>
                {(childItem.status === "submitted" || childItem.status === "rejected") && (
                    <Dropdown overlay={menu(childItem.id)} trigger={["click"]}>
                        <Button className={styles.iconBtn} icon={<Icon icon="MORE_HORZ" />} />
                    </Dropdown>
                )}
            </td>
        </tr>
    ))
}
